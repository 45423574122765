import React from 'react'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button = ({ className, children, ...props }: ButtonProps) => {
  return (
    <>
      <button className={`btn ${className}`} {...props}>
        {children}
      </button>
    </>
  )
}
