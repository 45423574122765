import React from 'react'

const Share = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
  >
    <path
      d='M12.5 3.5L15.5 6.5M12.5 14.5V3.5V14.5ZM12.5 3.5L9.5 6.5L12.5 3.5Z'
      stroke='black'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M16.5 19H8.5C7.51367 19 7.19515 18.9953 6.95248 18.9569C5.45485 18.7197 4.28029 17.5451 4.04309 16.0475C4.00466 15.8048 4 15.4863 4 14.5C4 13.5137 4.00466 13.1952 4.04309 12.9525C4.28029 11.4549 5.45485 10.2803 6.95248 10.0431C7.19515 10.0047 7.51367 10 8.5 10H8.75C9.16421 10 9.5 9.66421 9.5 9.25C9.5 8.83579 9.16421 8.5 8.75 8.5H8.5C7.57099 8.5 7.10649 8.5 6.71783 8.56156C4.57837 8.90042 2.90042 10.5784 2.56156 12.7178C2.5 13.1065 2.5 13.571 2.5 14.5C2.5 15.429 2.5 15.8935 2.56156 16.2822C2.90042 18.4216 4.57836 20.0996 6.71783 20.4384C7.10649 20.5 7.57099 20.5 8.5 20.5H16.5C17.429 20.5 17.8935 20.5 18.2822 20.4384C20.4216 20.0996 22.0996 18.4216 22.4384 16.2822C22.5 15.8935 22.5 15.429 22.5 14.5C22.5 13.571 22.5 13.1065 22.4384 12.7178C22.0996 10.5784 20.4216 8.90042 18.2822 8.56156C17.8935 8.5 17.429 8.5 16.5 8.5H16.25C15.8358 8.5 15.5 8.83579 15.5 9.25C15.5 9.66421 15.8358 10 16.25 10H16.5C17.4863 10 17.8048 10.0047 18.0475 10.0431C19.5451 10.2803 20.7197 11.4549 20.9569 12.9525C20.9953 13.1952 21 13.5137 21 14.5C21 15.4863 20.9953 15.8048 20.9569 16.0475C20.7197 17.5451 19.5451 18.7197 18.0475 18.9569C17.8048 18.9953 17.4863 19 16.5 19Z'
      fill='black'
    />
  </svg>
)

export const ShareIcon = () => (
  <button className='flex items-center gap-2 text-sm'>
    <Share />
  </button>
)
