import { Box, Checkbox, FormControlLabel } from '@mui/material'
import React, { useState } from 'react'
import { BiSolidArrowFromTop } from 'react-icons/bi'

type Props = {
  data: any
  setLibraryList: Function
  libraryList: any
}

export const TableBody = ({ data, setLibraryList, libraryList }: Props) => {
  const [state, setState] = useState(data)
  const handleSelectAll = () => {
    data.map(() => {})
  }
  return (
    <div className='content'>
      <Checkbox onChange={handleSelectAll} />
      <div className='list'>
        <div className='item '>
          <p>{data.name}</p>
          <p>{data.size}</p>
        </div>
        <div className='item wide'>
          <p>{data.description}</p>
        </div>
        <div className='item'>
          <p>
            <span>Document Purchased: </span>
            {data.date_purchased}
          </p>
        </div>
        <div className='item'>
          <p>
            <span>Document type:</span> {data.type}
          </p>
        </div>
        <div className='item'>
          <span>Document owner:</span>
          <div className='flex items-center'>
            <img src={data.owner[0].avatar_url} alt='' />
            <div>
              <p>{data.owner[0].name}</p>
              <p>{data.owner[0].email}</p>
            </div>
          </div>
        </div>
        <div className='item flex  justify-center'>
          <p>
            <a href='' className='flex'>
              <span>Invoice: </span>
              <BiSolidArrowFromTop size={20} />
            </a>
          </p>
        </div>
        <div className='item'>
          <p>
            <span>Status: </span>
            {data.status}
          </p>
        </div>
        <div className='item'>
          <button className='btn-default'>Create</button>
          <button className='btn-primary'>Download</button>
        </div>
      </div>
    </div>
  )
}

export const TableHeader = () => {
  return (
    <div className='content header'>
      <FormControlLabel
        name='select'
        className='checkbox'
        control={<Checkbox />}
        label='Select all'
      />

      <div className='list'>
        <div className='item'>
          <p>Document name</p>
        </div>
        <div className='item wide'>
          <p>Description</p>
        </div>
        <div className='item'>
          <p>Data purchased</p>
        </div>
        <div className='item'>
          <p>Document Type</p>
        </div>
        <div className='item'>
          <p>Document owner</p>
        </div>
        <div className='item flex  justify-center'>
          <p>Invoice</p>
        </div>
        <div className='item'>
          <p>Status</p>
        </div>
        <div className='item'></div>
      </div>
    </div>
  )
}
