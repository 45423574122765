export const icons = {
  group: require('./group.png'),
  userInfo: require('./user-info.png'),
  CheckFill: require('./check.png'),
  Heart: require('./heart.png'),
  Upload: require('./upload.png'),
  Download: require('./download.png'),
  Play: require('./play.png'),
  Info: require('./info.png'),
}
