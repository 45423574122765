import React, { FC, MouseEventHandler, useState } from 'react'
import {
  FaHeart,
  FaRegHeart,
  FaCheckCircle,
  FaTimesCircle,
} from 'react-icons/fa'
import { AiOutlineUp, AiOutlineDown, AiOutlineEllipsis } from 'react-icons/ai'

import {
  MenuItem,
  InfoPopup,
  SharePopup,
  AvatarImage,
  ContactPopup,
  BookNowSection,
  ProfileCardBase,
  ContactBtnsSection,
} from '../components'
import '../../../styles/style.css'
import Img from '../../base/Img'
import Project from 'common/project'
import Stepper from 'components/base/Stepper'
import map_icon from 'assets/FAL/map_icon.png'
import { getCardTheme } from 'utils/StylesUtil'
import { LawfirmMember } from './LawfirmMember'
import { LawfirmMembers } from './LawfirmMembers'
import { LawfirmDetail } from './LawfirmDetailSection'
import { MembershipType, OfficeSummary } from 'common/state-type'
import useAreasOfPractice from 'common/providers/useAreasOfPractice'
import { Button } from 'components/base/Button'

type LawfirmCardMblType = {
  latitude: any
  longitude: any
  areas?: string[]
  cardStyle?: string
  data: OfficeSummary
  onBookNow?: () => void
  onFavPress?: () => void
  onMouseEnter?: MouseEventHandler
  onClose?: () => void
}

const LawfirmCardMbl: FC<LawfirmCardMblType> = ({
  data,
  onMouseEnter,
  onFavPress,
  onBookNow,
  onClose,
  cardStyle,
}) => {
  const { areasOfPractice } = useAreasOfPractice()
  const [expandedView, setExpandedView] = useState(false)
  const [lawyersDetail, setLawyersDetail] = useState(null)
  const [activeLawyerId, setActiveLawyerId] = useState(null)
  const membershipType: MembershipType = data.organisation.membership_type
  const themeColor = getCardTheme(membershipType)
  const isMember = membershipType !== MembershipType.NONE

  const aop =
    data.organisation.areas_of_practice &&
    areasOfPractice &&
    areasOfPractice.filter((e) =>
      data?.organisation.areas_of_practice.find((area) => area.id === e.id),
    )
  const operatingAreas = aop?.map((item) => item.name)

  const handleShowMore = () => {
    setExpandedView(!expandedView)
  }

  const renderStepperView = () => {
    const hasLawyers = !!data.organisation.lawyers.length
    const allLawers = data.organisation.lawyers.map((lawyer) => {
      return (
        <LawfirmMember
          member={lawyer}
          key={lawyer.id}
          fetchLawyerData={(data) => {
            setLawyersDetail({ ...lawyersDetail, [data.id]: data })
          }}
        />
      )
    })

    const steps = [<LawfirmDetail data={data} />]
    if (hasLawyers) {
      steps.push(<LawfirmMembers members={data.organisation.lawyers} />)
      steps.push(...allLawers)
    }
    const handleStepChange = (page) => {
      if (page > 1) {
        const lawyerCount = page - 2
        setActiveLawyerId(data.organisation.lawyers[lawyerCount].id)
      } else {
        setActiveLawyerId(null)
      }
    }
    return (
      <Stepper
        steps={steps}
        themeColor={themeColor}
        getCurrentStep={handleStepChange}
      />
    )
  }

  return (
    <div
      id={`profile-${data.id}`}
      onMouseEnter={onMouseEnter}
      className='w-full md:hidden'
    >
      <ProfileCardBase membershipType={membershipType} className={cardStyle}>
        <div className='flex flex-col flex-1 w-full'>
          <div className='flex flex-col'>
            {onClose && (
              <Button
                className='self-end mb-8 mt-[-18px] md:hidden'
                onClick={onClose}
              >
                <FaTimesCircle
                  size={20}
                  color={themeColor.hex}
                  onClick={() => {}}
                />
              </Button>
            )}
            <div className='flex flex-row mb-4'>
              <AvatarImage
                imgSource={data.organisation.logo}
                nameInitials={data.organisation.practice_name_initials}
              />
              <div className='flex flex-col w-full mx-3'>
                <div className='flex flex-row place-content-between mb-1'>
                  <div className='flex flex-col text-black'>
                    <span className='text-lg font-bold'>{data.name}</span>
                  </div>
                  {isMember && (
                    <div className='flex flex-row gap-x-2'>
                      <button
                        onClick={onFavPress}
                        className={`flex items-center justify-center h-8 w-8 rounded-full ${themeColor.class}`}
                      >
                        {data.favourite_id ? (
                          <FaRegHeart color='#fff' size={20} />
                        ) : (
                          <FaHeart color='#fff' size={20} />
                        )}
                      </button>
                      <div
                        className={`flex items-center justify-center h-8 w-8 rounded-full ${themeColor.class}`}
                      >
                        <InfoPopup
                          themeColor={themeColor}
                          triggerrer={
                            <AiOutlineEllipsis color='#fff' size={20} />
                          }
                          position='bottom right'
                          on='hover'
                          closeOnDocumentClick
                          mouseLeaveDelay={300}
                          mouseEnterDelay={0}
                          arrow
                          nested
                          MenuItems={[
                            <MenuItem
                              key='1stItem'
                              Icon={data.favourite_id ? FaHeart : FaRegHeart}
                              label={
                                data.favourite_id
                                  ? 'Remove from Favourite'
                                  : 'Add Favorites'
                              }
                              onClick={onFavPress}
                            />,
                            <SharePopup
                              key='2ndItem'
                              shareLink={`${Project.cwp}lawfirms/${data.id}`}
                              position='bottom right'
                            />,
                            <ContactPopup
                              position='bottom right'
                              key='3rdItem'
                              themeColor={themeColor}
                              contactNumber={data.phone_number}
                              email={data.email_address}
                              website={data.website_url}
                            />,
                          ]}
                        >
                          <></>
                        </InfoPopup>
                      </div>
                    </div>
                  )}
                </div>

                <div className='flex flex-row gap-x-4'>
                  {isMember && (
                    <div
                      className={`text-sm px-2.5 items-center flex h-7 text-black rounded-lg font-bold ${themeColor.class}`}
                    >
                      <span>{membershipType}</span>
                    </div>
                  )}
                  {isMember && (
                    <div className='flex flex-row items-center text-black'>
                      <Img imgSrc={map_icon} className={'h-4 w-4'} />
                      <span className='pl-2 text-sm capitalize'>
                        {Utils.getDistance(data.latitude, data.longitude)} Miles
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {data.legal_aid_practitioner && (
              <div
                className={`text-sm px-2.5 items-center flex h-7 text-black rounded-lg font-bold bg-[#F4F4F4] max-w-fit mb-3 gap-2`}
              >
                <span>Legal Aid</span>
                <FaCheckCircle size={16} color={themeColor.hex} />
              </div>
            )}
            <div className='w-full flex flex-col'>
              {Boolean(operatingAreas) && (
                <>
                  <span className='text-black text-sm font-bold'>
                    Operating areas
                  </span>
                  <div className=' gap-2 py-3'>
                    {operatingAreas.map((area: string, index: number) => {
                      let textColor: string
                      const isActive = area?.includes(area)
                      textColor = isActive ? 'text-white' : 'text-black'
                      return (
                        <span
                          key={area}
                          style={
                            isActive
                              ? { backgroundColor: themeColor.hex }
                              : { backgroundColor: '#F4F4F4' }
                          }
                          className={`px-2.5 py-1.5 items-center text-sm inline-block text-center whitespace-nowrap align-baseline ${textColor} rounded-lg mr-2 mb-1`}
                        >
                          {area}
                        </span>
                      )
                    })}
                  </div>
                </>
              )}
              {isMember && (
                <div className='flex flex-1 flex-col'>
                  <button
                    className='flex flex-row self-center'
                    onClick={handleShowMore}
                  >
                    {expandedView ? (
                      <>
                        <p className='text-center text-gray-500 text-sm pr-2'>
                          show less ...
                        </p>
                        <AiOutlineUp size={20} color='#000000' />
                      </>
                    ) : (
                      <>
                        <p className='text-center text-gray-500 text-sm pr-2'>
                          show more ...
                        </p>
                        <AiOutlineDown size={20} color='#000000' />
                      </>
                    )}
                  </button>
                  {expandedView ? renderStepperView() : null}
                </div>
              )}
              <div className='flex flex-row place-content-between'>
                <ContactBtnsSection
                  phone={data.phone_number}
                  email={data.email_address}
                  website={data.website_url}
                  themeColor={themeColor}
                />
                {isMember && (
                  <BookNowSection
                    btnLabel='Book Now'
                    onClick={onBookNow}
                    title={
                      data.organisation?.fee_transparency_policy_url ? (
                        <a
                          target={'_blank'}
                          href={data.organisation.fee_transparency_policy_url}
                        >
                          Transparency Pricing
                        </a>
                      ) : null
                    }
                    themeColor={themeColor}
                    popupTitle='*Price information*'
                    popupDescription={`This is limited mandatory pricing information regulated lawyers have to disclose to you`}
                    titleClass={'text-sm underline font-bold'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </ProfileCardBase>
    </div>
  )
}

export default LawfirmCardMbl
