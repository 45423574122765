import React, { useState } from 'react'
import { LatestCard, MostViewedCard, PostCard } from './components/ArticlesCard'
import { articles, blogs, categories, sectors, tags } from 'data/ArticlesData'
import './index.scss'
import { Avatar } from 'components/Utils'
import { InputAdornment, TextField } from '@mui/material'
import { BsSearch } from 'react-icons/bs'
import Filter from './components/Filter'
import { Newsletter } from 'components/Newsletter'

const Articles = () => {
  const [filteredData, setFilteredData] = useState(articles)
  const [articleToShow, setEpisodeToShow] = useState(6)

  const handleSeeMore = () => {
    let newRecord = 0
    if (articleToShow < articles.length) {
      newRecord = articleToShow + 3
    } else {
      newRecord = articleToShow - 3
    }
    console.log(newRecord)
    setEpisodeToShow(newRecord)
  }
  return (
    <div id='articles'>
      <div className='section banner'>
        <div className='row'>
          <div className='col-md-6'>
            <h1 className='mb-10 display-5 fw-bold'>Most Viewed</h1>
            <MostViewedCard data={blogs} />
          </div>
          <div className='col-md-4 pull-right'>
            <h1 className='mb-10 display-5 fw-bold'>Latest</h1>
            <LatestCard data={blogs} />
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='row position-relative'>
          <div className='col-md-8'>
            <h3 className='display-5 mb-10 fw-bold'>All articles and blogs</h3>

            <Filter setFilterData={setFilteredData} data={articles} />

            <div className='row'>
              {filteredData &&
                filteredData.slice(0, articleToShow).map((article, idx) => (
                  <div className='col-md-4 mb-5 pr-1'>
                    <PostCard data={article} />
                  </div>
                ))}
            </div>
            <div className='flex justify-end'>
              <button onClick={handleSeeMore} className='text-underline'>
                {articleToShow < filteredData.length
                  ? 'Show more'
                  : 'Show less'}
              </button>
            </div>
          </div>
          <div className='col-md-3 pull-right mr-lg-n5 position-sticky'>
            <div className=' '>
              <h2 className='h2 mb-10 fw-bold'>Top authors</h2>

              {articles &&
                articles
                  .slice(0, 3)
                  .map((article, idx) => (
                    <Avatar
                      picture={article.author[0].avatar}
                      url='/'
                      urlName='View profile'
                      name={'Jenny Kia'}
                      sx={100}
                      className={'mb-4 '}
                    />
                  ))}

              <h3 className='h3 mb-5 fw-bold mt-20'>Search by law</h3>
              <div className='my-3'>
                <TextField
                  sx={{ width: { md: '320px', xs: '100%' } }}
                  variant='outlined'
                  label='Search'
                  name='search'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <BsSearch fill='#000' fontWeight={900} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className='flex flex-wrap'>
                {tags &&
                  tags.map((tag, idx) => (
                    <div className='badge badge-primary mr-1 mb-3 fs-sm'>
                      {tag}
                    </div>
                  ))}
              </div>
              <h3 className='h3 mb-5 fw-bold mt-20'>Sectors</h3>

              {sectors &&
                sectors.map((sector, idx) => (
                  <div className='flex justify-between bb-dotted py-4'>
                    <span>{sector.name}</span>
                    <span>{sector.count}</span>
                  </div>
                ))}

              <h3 className='h3 mb-5 fw-bold mt-20 mr-n5'>
                Most published categories
              </h3>
              <div className='row'>
                {categories &&
                  categories.map((category, idx) => (
                    <div className='col-xs-6 col-lg-6  pr-1'>
                      <div className='card mb-4 bg-theme-light border-0'>
                        <div className='card-body text-center '>
                          <h3 className='h2 fw-bold'>{category.count}</h3>
                          <p>{category.name}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
    </div>
  )
}

export default Articles
