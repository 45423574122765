import React from 'react'
import { data } from 'data/PodcastData'
import PodcastsCard from 'components/cards/PodcastCard'
import { background } from 'assets/backgrounds'

const PodcastSection = () => {
  return (
    <div className='md:my-[100px]'>
      <img
        src={background.discoverLaw._04}
        className='absolute left-0 md:mt-[-300px] z-[-2]'
        alt=''
      />
      <h1 className='text-[18px] mb-3 font-bold'>Podcasts</h1>
      <div className='grid xl:grid-cols-4 md:grid-cols-2 gap-5'>
        {data.slice(0, 4).map((episode) => (
          <div className=' '>
            <PodcastsCard episode={episode} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PodcastSection
