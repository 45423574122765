import { Avatars } from 'assets/avatars'
import { icons } from 'assets/icons'
import { Avatar } from 'components/Utils'
import React from 'react'

const InterviewList = () => {
  return (
    <div className='card bg-[#fff] p-[16px]'>
      <div className='flex flex-wrap items-center justify-between'>
        <div className='flex items-center flex-wrap justify-between '>
          <Avatar
            picture={Avatars._01}
            name='Martin Manson'
            caption='Published on 25/05/2024'
            className={'md:pr-3'}
          />
          <div className='md:hidden'>
            <img src={icons.Heart} className='h-5 inline' alt='' />
            <span className='text-[14px] font-extralight ml-2'>20 plays</span>
          </div>
          <div className='flex w-full justify-between items-center md:hidden mt-3 mb-3'>
            <div className='flex items-center gap-3 md:border-r-2 px-3'>
              <p className='text-[16px] font-extralight'>1hr, 24 min</p>
              <div className='w-10 md:w-16 bg-gray-200 rounded-full h-1'>
                <div className='bg-blue-600 h-1 rounded-full w-1/4'></div>
              </div>
            </div>
            <div className='flex items-center gap-3'>
              <img src={icons.Upload} className='h-6' alt='' />
              <div className='flex h-10 w-10 border-1  rounded-full bg-gray-50 relative cursor-pointer'>
                <img
                  src={icons.Play}
                  className='m-auto absolute top-[30%] left-[30%]'
                  alt=''
                />
              </div>
            </div>
          </div>

          <div className='flex md:border-l-2 md:border-y-0 border-y-2 mx-0 md:py-0 py-3'>
            <Avatar
              picture={Avatars._02}
              name='Martin Manson'
              caption='Published on 25/05/2024'
              tx={10}
              sx={24}
              className={'md:pl-3'}
            />
            <Avatar
              className={'ml-2'}
              picture={Avatars._02}
              name='Martin Manson'
              caption='Published on 25/05/2024'
              tx={10}
              sx={24}
            />
          </div>
        </div>

        <div className='flex items-center'>
          <div className='flex gap-1 md:border-r-2 md:px-2 max-sm:mt-3 text-[12px]'>
            <div className='badge bg-[#EBF3FF]'>Conveyancing</div>
            <div className='badge bg-[#D2F6FF] '>Conveyancing</div>
            <div className='badge bg-[#EBF3FF]'>Conveyancing</div>
          </div>
          <div className='flex items-center gap-3 border-r-2 px-3 max-sm:hidden'>
            <p className='text-[16px] font-extralight'>1hr, 24 min</p>
            <div className='w-10 md:w-16 bg-gray-200 rounded-full h-1'>
              <div className='bg-blue-600 h-1 rounded-full w-1/4'></div>
            </div>
          </div>
          <div className='flex items-center gap-3 px-3 max-sm:hidden'>
            <div>
              <img src={icons.Heart} className='h-5 inline' alt='' />
              <span className='text-[14px] font-extralight ml-2'>20 plays</span>
            </div>
            <img src={icons.Upload} className='h-6' alt='' />
            <div className='flex h-10 w-10  rounded-full bg-gray-50 relative cursor-pointer'>
              <img
                src={icons.Play}
                className='m-auto absolute top-[30%] left-[30%]'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col mt-10'>
        <h1 className='font-normal text-[14px]'>Title</h1>
        <p className='md:text-[16px] text-[14px] text-text-muted font-normal mt-2 mb-3'>
          Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem
          ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
          lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem
          ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
          lorem ipsum
        </p>
        <div className='flex items-center justify-end font-normal gap-3 text-[16px]'>
          <img src={icons.Download} alt='' /> Download transcript
        </div>
      </div>
    </div>
  )
}

export default InterviewList
