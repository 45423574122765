import { ButtonHTMLAttributes, useState } from 'react'
import cn from 'classnames'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
}

export const Button = ({
  children,
  className,
  disabled =false,
  variant = 'primary',
  ...restProps
}: ButtonProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isClicked, setIsClicked] = useState(false)

  return (
    <button
      type='button'
      {...restProps}
      disabled={disabled}
      className={cn(
        'rounded-lg text-lg py-1.5 px-8 w-full flex items-center justify-center whitespace-nowrap transition-all duration-200',
        {
              // Primary button styles
          'bg-[#020251] text-white': variant === 'primary' && !disabled && (!isHovered || isClicked),
          'bg-[#020251] text-[#65C2D7]': variant === 'primary' && !disabled && isHovered && !isClicked,


  // Secondary button styles
          'bg-white text-[#1C7D93]': variant === 'secondary' && !disabled && (!isHovered || isClicked),
          'bg-[#EBFBFF] text-[#205497]': variant === 'secondary' && !disabled && isHovered && !isClicked,

          // Tertiary button styles
          'bg-transparent text-[#1C7D93]': variant === 'tertiary' && !disabled && !isHovered && !isClicked,
          //@ts-ignore
          'bg-[#EBFBFF] text-[#1C7D93]': variant === 'tertiary' && !disabled && isHovered && !isClicked,
            //@ts-ignore
          'bg-[#83C8D7] text-[#020251]': variant === 'tertiary' && !disabled && isClicked,

          // Quaternary button styles
          'bg-transparent text-[#001A3D]': variant === 'quaternary' && !disabled && !isHovered && !isClicked,
          'bg-transparent text-[#6596D7]': variant === 'quaternary' && !disabled && isHovered && !isClicked,
          'bg-transparent text-[#205497]': variant === 'quaternary' && !disabled && isClicked,

          // Disabled styles (same for all variants)
          'bg-[#F4F4F4] text-[#A0ABBE] cursor-not-allowed': disabled,

          // Default cursor
          'cursor-pointer': !disabled,

          // Shadow for primary and secondary, but not for tertiary and quaternary
          'shadow-[0_1px_4px_rgba(0,0,0,0.25)]': (variant === 'primary' || variant === 'secondary') && !disabled,
        },
        className,
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false)
        setIsClicked(false)
      }}
      onMouseDown={() => setIsClicked(true)}
      onMouseUp={() => setIsClicked(false)}
    >
      {children}
    </button>
  )
}