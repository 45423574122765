import React, { FC, MouseEventHandler, ReactElement, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

import ShareIcon from '../../../assets/share.svg'
import { MembershipType, OfficeSummary } from 'common/state-type'
import { getCardTheme } from 'utils/StylesUtil'
import useAreasOfPractice from 'common/providers/useAreasOfPractice'
import Utils from 'common/utils/utils'
import { InfoItem, InfoPopup } from '../components'
import { AccountType } from '../../../../../common/state-type'
import HeartIcon from '../../../assets/heart_empty.svg'
import PhoneIcon from '../../../assets/phone_light.svg'
import InfoIcon from '../../../assets/info_light.svg'
import QuestionIcon from '../../../assets/info_dark.svg'
import EmailIcon from '../../../assets/email_light.svg'

import useFavourite from 'common/providers/useFavourite'
import { Button } from '../../../../../common/components/Authentication/Button'
import { CardMode } from '../barrister/BarristerProfileCard'

type LawfirmProfileCardType = {
  onMouseEnter?: MouseEventHandler
  data: OfficeSummary
  longitude?: any
  latitude?: any
  areas?: string[]
  onFavPress?: () => void
  onBookNow?: () => void
  onReviewBooking?: () => void
  mode: CardMode
  cardStyle?: string
  className?: string
}

const LawfirmProfileCard: FC<LawfirmProfileCardType> = ({
  data,
  onMouseEnter,
  onFavPress,
  onBookNow,
  onReviewBooking,
  mode,
  className,
}) => {
  const { areasOfPractice } = useAreasOfPractice()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isFavorited, setIsFavorited] = useState(!!data.favourite_id)
  const [showPhoneNumber, setShowPhoneNumber] = useState(false)

  const { createFavourite, deleteFavourite } = useFavourite()

  const aop =
    data.organisation?.areas_of_practice &&
    areasOfPractice &&
    areasOfPractice.filter((e) =>
      data.organisation?.areas_of_practice?.find((area) => area.id === e.id),
    )

  const operatingAreas = aop?.map((item) => item.name)
  const membershipType: MembershipType = data.organisation?.membership_type
  const themeColor = getCardTheme(membershipType)

  const toggleExpand = () => setIsExpanded(!isExpanded)

  const handleFavPress = () => {
    if (data?.id) {
      onFavPress()
      const type = Utils.getUserType(AccountType.BUSINESS)
      createFavourite({ [type]: data.id })
    }
  }

  const handlePhoneClick = () => {
    setShowPhoneNumber(!showPhoneNumber)
  }

  const handleEmailClick = () => {
    window.location.href = `mailto:customerservice@legalutopia.co.uk`
  }

  const handleWebsiteClick = () => {
    if (data.website_url) {
      window.open(data.website_url, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <div
      id={`profile-${data.id}`}
      onMouseEnter={onMouseEnter}
      className={`relative w-80 ${
        mode === CardMode.FAVOURITE ? 'h-[30rem]' : 'h-[18rem]'
      } bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 ${
        className ?? ''
      }`}
    >
      <div
        className={` ${mode === CardMode.FAVOURITE ? ' h-[30%]' : ' h-[50%]'}
           bg-gradient-to-b from-blue-300 to-blue-100 transform -skew-y-6 origin-top-left`}
      ></div>

      <div
        className={`relative ${
          mode === CardMode.FAVOURITE ? '-mt-16' : '-mt-12'
        } px-6`}
      >
        <div className='flex justify-between items-start mb-4'>
          <div className='space-y-2 -mt-14'>
            <IconButton iconSrc={EmailIcon} onClick={handleEmailClick} />

            <div className='relative'>
              <IconButton iconSrc={PhoneIcon} onClick={handlePhoneClick} />
              {showPhoneNumber && (
                <button
                  onClick={handlePhoneClick}
                  className='absolute left-0 top-0 bg-white rounded-full py-2 px-2 text-xs shadow-md whitespace-nowrap'
                >
                  {data.phone_number || 'N/A'}
                </button>
              )}
            </div>
            <IconButton iconSrc={InfoIcon} onClick={handleWebsiteClick} />
          </div>

          <div className='flex flex-col items-center -mt-14'>
            <h2 className='text-lg font-semibold text-center mb-2 '>
              {data.name}
            </h2>
            <div className='w-20 h-20 bg-blue-100 rounded-full flex items-center justify-center shadow-md'>
              <span className='text-2xl font-bold'>
                {data.organisation?.practice_name_initials || 'N/A'}
              </span>
            </div>
          </div>

          <div className='space-y-2 -mt-14'>
            <IconButtons2
              isFavorited={isFavorited}
              onFavPress={onFavPress}
              className='space-y-4'
            />
          </div>
        </div>

        <div className='mt-2 h-20 overflow-y-auto'>
          <p className='text-sm text-gray-600 text-center'>
            {data.organisation?.description || 'No bio available'}
          </p>
        </div>

        <div
          className={`${
            isExpanded ? 'h-32 overflow-y-auto pb-[20%]' : 'h-0 overflow-hidden'
          } transition-all duration-300`}
        >
          <div className='space-y-1 text-xs text-gray-600'>
            <InfoItem
              title='SRA Organisation ID'
              content={data.organisation?.sra_organisation_id ?? 'N/A'}
            />
            <InfoItem
              title='SRA Number'
              content={data.organisation?.sra_number ?? 'N/A'}
            />
            <InfoItem
              title='Company Number'
              content={data.organisation?.company_registration_number ?? 'N/A'}
            />
            <InfoItem
              title='Company Structure'
              content={data.organisation?.company_structure ?? 'N/A'}
            />
            <InfoItem
              title='Regulatory Body Type'
              content={data.organisation?.regulatory_body_type ?? 'N/A'}
            />
            <InfoItem
              title='Auth Date'
              content={data.organisation?.authorisation_date ?? 'N/A'}
            />
            <InfoItem
              title='Regional Cost'
              content={Utils.formatMoneyFromPence(
                data.average_regional_cost_pence ?? 0,
              )}
              rightElement={
                <InfoPopup
                  themeColor={themeColor}
                  className='max-w-md'
                  triggerrer={<FaChevronDown />}
                  title='Regional Cost'
                  description="The median legal bill by region is based on office address and Legal Services Board's (2019-2020) data"
                  position='right bottom'
                >
                  <></>
                </InfoPopup>
              }
            />
          </div>
        </div>

        <div className='overflow-y-auto'>
          {operatingAreas && operatingAreas.length > 0 && (
            <div className='mt-4'>
              <h3 className='text-sm font-semibold mb-2'>Areas of law</h3>
              <div className='flex flex-wrap gap-2'>
                {operatingAreas.slice(0, 3).map((area, index) => (
                  <span
                    key={index}
                    className='px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-xs'
                  >
                    {area}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='absolute bottom-0 left-0 right-0 bg-[#6596D7] p-4'>
        <div className='flex justify-between items-center'>
          {mode === CardMode.APPOINTMENT ? (
            <>
              <div className='text-white'>
                <div className='text-lg font-medium leading-normal'>
                  From £
                  {((data.average_regional_cost_pence ?? 0) / 100).toFixed(2)}
                </div>
              </div>
              <Button
                type='submit'
                variant='secondary'
                className='!px-0 !mx-0 !w-[20%]'
                onClick={onReviewBooking}
              >
                <span className='!px-0 !mx-0 '>View</span>
              </Button>
            </>
          ) : (
            <>
              <div className='text-white'>
                <div className='flex items-baseline'>
                  <span className='text-lg font-bold'>
                    From £
                    {((data.average_regional_cost_pence ?? 0) / 100).toFixed(2)}
                  </span>
                  <span className='text-xs ml-1'>p/hr</span>
                </div>
                <p className='text-xs'>Estimated cost</p>
              </div>

              <Button
                type='submit'
                variant='secondary'
                className='!px-0 !mx-0 !w-auto'
                onClick={onBookNow}
              >
                <span className='px-1'>{'Book Now'}</span>
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const IconButton: FC<{
  iconSrc: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  alt?: string
}> = ({ iconSrc, onClick, alt = 'Icon' }) => (
  <button onClick={onClick}>
    <img src={iconSrc} alt={alt} className='w-5 h-5 ' />
  </button>
)

const IconButtons2: FC<{
  isFavorited: boolean
  onFavPress: () => void
  onInfoPress?: () => void
  onSharePress?: () => void
  className?: string
}> = ({ isFavorited, onFavPress, onInfoPress, onSharePress, className }) => {
  return (
    <div
      className={`flex flex-col items-center justify-between h-full ${className}`}
    >
      <button onClick={onFavPress} className='flex items-center justify-center'>
        <img
          src={HeartIcon}
          alt='Heart'
          className={`${isFavorited ? 'text-red-500' : 'text-gray-400'}`}
        />
      </button>

      <button
        onClick={onInfoPress}
        className='flex items-center justify-center'
      >
        <img src={QuestionIcon} alt='Info' />
      </button>

      <button
        onClick={onSharePress}
        className='flex items-center justify-center !mt-12'
      >
        <img src={ShareIcon} alt='Share' />
      </button>
    </div>
  )
}

const ProfileSection: FC<{
  data: any
  isExpanded: boolean
  toggleExpand: () => void
}> = ({ data, isExpanded, toggleExpand }) => {
  return (
    <>
      <div className='border-t border-[#F4F4F4] py-2 mb-4 mt-4'>
        <button
          className='w-full text-left flex justify-between items-center py-2'
          onClick={toggleExpand}
        >
          <span className='font-semibold text-blue-500 text-sm'>
            Additional Information
          </span>
          {isExpanded ? (
            <FaChevronUp className='text-blue-500' />
          ) : (
            <FaChevronDown className='text-blue-500' />
          )}
        </button>
      </div>
    </>
  )
}

export { ProfileSection, LawfirmProfileCard, IconButtons2, IconButton }
