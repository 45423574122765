interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

export const BasicInputField = ({ ...props }: Props) => {
  return (
    <>
      <input type='text' {...props} />
    </>
  )
}

interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {}

export const MultiLineField = ({ ...props }: TextAreaProps) => {
  return (
    <>
      <textarea {...props}></textarea>
    </>
  )
}

interface InputGroupProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon: any
  className?: any
  reverse?: boolean
}

export const InputGroupField = ({
  icon,
  reverse,
  ...props
}: InputGroupProps) => {
  return (
    <>
      <label className='input-group border-2'>
        {icon && !reverse && <div className='input-group-icon'>@</div>}
        <div className='input-group-area'>
          <input type='text' {...props} />
        </div>
        {icon && reverse && (
          <div className='input-group-icon bg-white'>{icon}</div>
        )}
      </label>
    </>
  )
}

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  className: string
}

export const InputField = ({ label, className, ...props }: InputFieldProps) => {
  return (
    <div className='relative z-10'>
      <input
        type='text'
        {...props}
        placeholder=''
        className={`block rounded-lg px-2.5 pb-2.5 pt-5 w-full shadow-sm  border appearance-none peer ${className}`}
      />
      <label
        htmlFor='floating_filled'
        className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto'
      >
        {label}
      </label>
    </div>
  )
}
