export const data = [
  {
    title: 'How you search for lawyers and firms',
    description:
      'You can use the text-based search box on the home page to enter keywords or topics for your search, e.g.  divorce or conveyancing. Alternatively, you can describe your problem, for example,’I want to ask my landlord to make an urgent repair’, or ‘Have I been unfairly dismissed from my job?’. This information will be used to search our comprehensive database of lawyers and firms. We may use artificial intelligence to understand your information and match it with the lawyers and firms in our database, to find you the best possible legal advice for your problem.',
  },
  {
    title: 'How we generate search results',
    description:
      'Results are generated automatically, based on your search as well as any preferences you have applied to your account. They can be further refined by using our Find a Lawyer Intelligence feature. When you input a keyword, topic, or description of your legal problem into the text-based search bar, our system will search for lawyers whose expertise matches the information in your search. The results are further filtered or refined based on any additional information you input via the Find a Lawyer Intelligence, and the lawyers’ information on our database.',
  },
  {
    title: 'How we rank and display search results',
    description:
      'Profiles displayed on the Find a Lawyer results page are ranked by their relevance to the search term inputs and user preferences. Where profiles are identified by keyword, topic, or other information provided by the user, they are included in the results. If the user then adds more preferences to their search, this will filter or reduce the number of relevant profiles selected from the database. These profiles are displayed on a hierarchical basis with premium (purple) profiles displaying first or highest, then standard (green) second or below premium, and then basic (blue) below standard profiles. Should relevant profiles not include a hierarchical tier, they will not be included in the results.',
  },
  {
    title: 'How Advance Search works',
    description:
      'The Advance Search option enables users to filter their results and make them even more relevant. This operates in the same way as general preferences. When you select one or more filters, a new  results list will be generated, which will only include  profiles which provide the service or expertise that you are looking for. This applies equally to the options to select an area or sub-area of law. The results will be refined to only those profiles that are relevant to the user’s preferences.',
  },
  {
    title: 'How to make a booking',
    description:
      'You can make a booking via the Find a Lawyer service once you have selected a lawyer profile from your search results. You start by selecting the type of booking you would like to make (Document Review or Legal Advice), You will then be asked to input additional information for the lawyer to access so that they can engage with you as a potential client. This might include your name, email, phone, and a description of your legal problem. For document review, you have the option to upload your document(s) so that the lawyer can provide you with a quotation and deadline. You will then need to select a date and time for either a remote call or a proposed deadline for the document review.',
  },
]
