import React from 'react'

const PageBanner = ({ children }) => {
  return (
    <section className='page-banner'>
      <h4 className='text-center'>{children}</h4>
    </section>
  )
}

export { PageBanner }
