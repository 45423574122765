import React from 'react'
import './index.scss'
import { background } from 'assets/backgrounds'
import LawTech from './components/lawtech'
import { Button } from 'components/forms/Buttons'
import FundedBy from './components/funded-by'
import PioneerSection from './components/pioneer'

const WhoWeAre = () => {
  return (
    <>
      <section id='who-we-are' className='md:p-10 py-10 px-5'>
        <div className='relative'>
          <div className='flex md:items-center  justify-around'>
            <div className='col-md-5 !pr-0 '>
              <h1 className='md:text-[64px] text-[24px] md:t-0 mt-5 md:ml-5 font-bold text-[#020251]'>
                We help you with your{' '}
                <span className='text-[#65C2D7]'>legal needs</span>
              </h1>
              <p className='md:ml-20 ml-10 mt-5 md:mt-10 md:mr-0 mr-[-200px] md:text-[18px] text-[12px] '>
                Legal Utopia is a pioneering legal technology company dedicated
                to making legal services more accessible and affordable for
                everyone. We leverage advanced AI and intuitive design to
                provide comprehensive legal solutions tailored to individual
                needs.{' '}
                <span className='md:inline block md:ml-0 ml-10 md:mt-0 mt-5'>
                  Our mission is to demystify the legal process and empower
                  users with the knowledge and tools necessary to navigate their
                  legal challenges confidently.
                </span>
              </p>
            </div>
            <div className='col-md-5 !pl-0'>
              <img
                className='md:t-0 mt-5'
                src={background.WhoWeAre.Hero}
                alt=''
              />
            </div>
          </div>
          <img
            className='absolute left-[-10px] top-[40px] md:h-[1300px]'
            src={background.WhoWeAre.Tree}
            alt=''
          />
          <div className='flex justify-end'>
            <Button className='btn-primary mt-[300px] md:mt-[50px] md:mr-[150px]'>
              Get a help today
            </Button>
          </div>
        </div>

        <div className='flex justify-end items-center mt-[100px]'>
          <div className='col-md-4 md:mr-[200px] relative'>
            <div className='upper md:p-[30px] p-5 absolute md:right-[-100px] right-[-10px] top-0 md:w-[90%] w-[70%]'>
              <p className='md:text-[32px] font-bold text-center '>
                We envision a world where law is for everyone.
              </p>
            </div>
            <div className='card md:p-[40px] p-4 mt-[80px] md:mt-[120px]'>
              <p className='text-primary md:text-[32px] font-bold'>
                We're here to make a difference: by making law affordable and
                accessible to everyone.
              </p>
              <div className='md:hidden '>
                <hr className='mt-5' />
                <p className='text-text-muted mt-3 text-[12px]'>Who said...</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <LawTech />

      <FundedBy />

      <PioneerSection />
    </>
  )
}

export default WhoWeAre
