import React, { useState } from 'react'
import { Button } from './../../../common/components/Authentication/Button'
import Project from '../../../common/project'

export const NewsletterSignup: React.FC = () => {
  const [email, setEmail] = useState('')
  const [agreed, setAgreed] = useState(false)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    // Handle newsletter signup logic here
    console.log('Newsletter signup:', { email, agreed })
  }

  return (
    <div className='bg-blue-50 rounded-lg p-4 sm:p-8 md:p-12 mt-8 sm:mt-12 md:mt-16 w-full sm:w-[90%] md:w-[80%] mx-auto'>
      <h2 className='text-xl sm:text-3xl md:text-4xl font-bold text-center mb-2 sm:mb-4'>
        Join our newsletter
      </h2>
      <p className='text-center text-gray-600 mb-4 sm:mb-6 text-sm sm:text-base'>
        Some text here lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem
        ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem
        ipsum lorem ipsum lorem ipsum lorem ipsum.
      </p>
      <form onSubmit={handleSubmit} className='max-w-md mx-auto'>
        <input
          type='email'
          placeholder='Email address'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className='w-full px-3 sm:px-4 py-2 mb-3 sm:mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm sm:text-base'
          required
        />
        <div className='flex items-start sm:items-center mb-3 sm:mb-4'>
          <input
            type='checkbox'
            id='agree'
            checked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
            className='mr-2 mt-1 sm:mt-0'
            required
          />
          <span className='text-sm sm:text-base text-black'>
            I agree with the
            <a
              href={new URL('/privacy-policy-web', Project.cwp).href}
              className='!text-blue inline-block px-1'
            >
              Privacy policy
            </a>
            and
            <a
              href={new URL('/marketing-policy', Project.cwp).href}
              className='!text-blue inline-block px-1'
            >
              Marketing policy
            </a>
          </span>
        </div>
        <div className='flex justify-center sm:justify-end'>
          <Button
            type='submit'
            variant='secondary'
            className='w-full sm:w-auto mt-4 sm:mt-6'
          >
            Join
          </Button>
        </div>
      </form>
    </div>
  )
}

export default NewsletterSignup
