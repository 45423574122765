import React, { useState, useRef, useEffect } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { useFirebaseCloudStorage } from '../../common/firebase/useFirebaseCloudStorage'
import ForwardPlayIcon from '../../assets/forward_play_icon.svg'
import BackwardPlayIcon from '../../assets/backward_play_icon.svg'
import PauseIcon from '../../assets/pause.svg'
import PlayIcon from '../../assets/play_icon.svg'
import ShareIcon from '../../assets/share.svg'
import thumbNail from '../../assets/player_thumbnail.png'

const SvgIcon = ({ src, alt, ...props }) => (
  <img src={src} alt={alt} {...props} />
)

const AudioPlayer = ({ title, author, thumbnailSrc = thumbNail }) => {
  const { getImages, getAudios, loading, error } =
    useFirebaseCloudStorage('podcasts')
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const audioRef = useRef(null)

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.audioEl.current.pause()
      } else {
        audioRef.current.audioEl.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const handleTimeUpdate = (e) => {
    setCurrentTime(e.target.currentTime)
  }

  const handleLoadedMetadata = (e) => {
    setDuration(e.target.duration)
  }

  const handleForward = () => {
    if (audioRef.current) {
      audioRef.current.audioEl.current.currentTime += 10
    }
  }

  const handleBackward = () => {
    if (audioRef.current) {
      audioRef.current.audioEl.current.currentTime -= 10
    }
  }

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.audioEl.current.addEventListener(
        'timeupdate',
        handleTimeUpdate,
      )
      audioRef.current.audioEl.current.addEventListener(
        'loadedmetadata',
        handleLoadedMetadata,
      )
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.audioEl.current.removeEventListener(
          'timeupdate',
          handleTimeUpdate,
        )
        audioRef.current.audioEl.current.removeEventListener(
          'loadedmetadata',
          handleLoadedMetadata,
        )
      }
    }
  }, [])

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  const audioFiles = getAudios()

  return (
    <div className='bg-white rounded-xl shadow-lg p-4 max-w-sm mx-auto'>
      <div className='flex items-center space-x-4'>
        <img
          src={thumbnailSrc}
          alt='Thumbnail'
          className='w-16 h-16 rounded-lg object-cover'
        />
        <div className='flex-1'>
          <h3 className='font-semibold text-gray-800 line-clamp-2'>{title}</h3>
          <p className='text-sm text-gray-600'>{author}</p>
        </div>
      </div>

      <div className='mt-4'>
        <div className='bg-gray-200 rounded-full h-1.5 w-full'>
          <div
            className='bg-[#5858DF] h-1.5 rounded-full'
            style={{ width: `${(currentTime / duration) * 100}%` }}
          ></div>
        </div>
        <div className='flex justify-between text-xs text-gray-500 mt-1'>
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>

      <div className='flex mt-4'>
        <div className='w-[40%] flex justify-between items-center'>
          <button
            className='text-gray-600 hover:text-gray-800 p-2'
            onClick={handleBackward}
          >
            <SvgIcon src={ForwardPlayIcon} alt='Forward' className='w-6 h-6' />
          </button>
          <button
            className='bg-white text-[#5858DF] rounded-full p-3 hover:bg-gray-100'
            style={{
              boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
            }}
            onClick={togglePlayPause}
          >
            {isPlaying ? (
              <SvgIcon
                src={PauseIcon}
                alt='Pause'
                className='h-[16px] w-[16px]'
              />
            ) : (
              <SvgIcon
                src={PlayIcon}
                alt='Play'
                className='w-4 h-4 transform scale-[1.75] translate-y-[20%]'
              />
            )}
          </button>
          <button
            className='text-gray-600 hover:text-gray-800 p-2'
            onClick={handleForward}
          >
            <SvgIcon
              src={BackwardPlayIcon}
              alt='Backward'
              className='w-6 h-6'
            />
          </button>
        </div>
        <div className='w-[60%] flex justify-end items-center'>
          <button className='text-gray-600 hover:text-gray-800 p-2'>
            <SvgIcon src={ShareIcon} alt='Share' className='w-6 h-6' />
          </button>
        </div>
      </div>

      <ReactAudioPlayer src={audioFiles[0]} ref={audioRef} />
    </div>
  )
}

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60)
  const secs = Math.floor(seconds % 60)
  return `${mins}:${secs.toString().padStart(2, '0')}`
}

export default AudioPlayer
