import React, { forwardRef, useState, useEffect, useRef } from 'react'
import ForwardPlayIcon from '../../assets/forward_play_icon_light.svg'
import BackwardPlayIcon from '../../assets/backward_play_icon_light.svg'
import PauseIcon from '../../assets/pause.svg'
import PlayIcon from '../../assets/play_icon.svg'
import songTunePost from '../../assets/song_tune_post.svg'
import songTunePre from '../../assets/song_tune_pre.svg'

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void
    YT: typeof YT
  }
}

interface SvgIconProps {
  src?: string
  alt: string
  className?: string
}

const SvgIcon: React.FC<SvgIconProps> = ({ src, alt, ...props }) => (
  <img src={src} alt={alt} {...props} />
)

interface VideoPlayerProps {
  src: string
  title: string
  interviewee: string
  isYouTube?: boolean
}

const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>(
  ({ src, title, interviewee, isYouTube = false, ...props }, ref) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [youtubeError, setYoutubeError] = useState(false)
    const videoRef = useRef<HTMLVideoElement>(null)
    const youtubePlayerRef = useRef<YT.Player | null>(null)

    useEffect(() => {
      if (isYouTube) {
        const tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag)

        // Initialize YouTube player when API is ready
        window.onYouTubeIframeAPIReady = () => {
          youtubePlayerRef.current = new window.YT.Player('youtube-player', {
            videoId: extractYouTubeID(src),
            events: {
              onReady: onPlayerReady,
              onStateChange: onPlayerStateChange,
              onError: onPlayerError,
            },
          })
        }
      } else if (videoRef.current) {
        videoRef.current.addEventListener('timeupdate', handleTimeUpdate)
        videoRef.current.addEventListener(
          'loadedmetadata',
          handleLoadedMetadata,
        )
      }

      return () => {
        if (!isYouTube && videoRef.current) {
          videoRef.current.removeEventListener('timeupdate', handleTimeUpdate)
          videoRef.current.removeEventListener(
            'loadedmetadata',
            handleLoadedMetadata,
          )
        }
      }
    }, [isYouTube, src])

    const extractYouTubeID = (url: string) => {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      const match = url.match(regExp)
      return match && match[2].length === 11 ? match[2] : null
    }

    const onPlayerReady = (event: YT.PlayerEvent) => {
      setDuration(event.target.getDuration())
      setYoutubeError(false)
    }

    const onPlayerStateChange = (event: YT.OnStateChangeEvent) => {
      setIsPlaying(event.data === window.YT.PlayerState.PLAYING)
      setCurrentTime(event.target.getCurrentTime())
    }

    const onPlayerError = (event: YT.OnErrorEvent) => {
      console.error('YouTube Player Error:', event.data)
      setYoutubeError(true)
    }

    const handlePlayPause = () => {
      if (isYouTube && youtubePlayerRef.current) {
        if (isPlaying) {
          youtubePlayerRef.current.pauseVideo()
        } else {
          youtubePlayerRef.current.playVideo()
        }
      } else if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.pause()
        } else {
          videoRef.current.play()
        }
        setIsPlaying(!isPlaying)
      }
    }

    const handleTimeUpdate = () => {
      if (videoRef.current) {
        setCurrentTime(videoRef.current.currentTime)
      }
    }

    const handleLoadedMetadata = () => {
      if (videoRef.current) {
        setDuration(videoRef.current.duration)
      }
    }

    const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
      const time = Number(e.target.value)
      setCurrentTime(time)
      if (isYouTube && youtubePlayerRef.current) {
        youtubePlayerRef.current.seekTo(time, true)
      } else if (videoRef.current) {
        videoRef.current.currentTime = time
      }
    }

    const formatTime = (time: number) => {
      const minutes = Math.floor(time / 60)
      const seconds = Math.floor(time % 60)
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    }

    return (
      <div className='w-full max-w-2xl mx-auto'>
        <div className='rounded-lg border-8 border-[#020251] bg-[#020251] shadow-[0px_4px_30px_0px_rgba(101,194,215,0.30)] p-1 sm:p-1.5 md:p-2 mb-3'>
          {isYouTube ? (
            youtubeError ? (
              <div className='w-full h-48 sm:h-64 md:h-72 lg:h-80 xl:h-96 rounded-lg bg-gray-200 flex items-center justify-center'>
                <p className='text-gray-500'>Video unavailable</p>
              </div>
            ) : (
              <div
                id='youtube-player'
                className='w-full h-48 sm:h-64 md:h-72 lg:h-80 xl:h-96 rounded-lg'
              ></div>
            )
          ) : (
            <video
              ref={videoRef}
              src={src}
              className='w-full h-48 sm:h-64 md:h-72 lg:h-80 xl:h-96 rounded-lg object-cover'
              {...props}
            />
          )}
        </div>
        <div className='bg-[#020251] text-white p-4 rounded-[40px] backdrop-blur-[77.5px]'>
          <h2 className='text-center text-sm font-semibold mb-1'>
            <div className='flex flex-row items-center justify-center'>
              <SvgIcon
                src={songTunePre}
                alt='Backward'
                className='w-5 h-5 pr-1'
              />

              {title}
              <SvgIcon
                src={songTunePost}
                alt='Backward'
                className='w-5 h-5  pl-1'
              />
            </div>
          </h2>
          <p className='text-center text-xs text-[#65C2D7] mb-2'>
            {interviewee}
          </p>
          {!youtubeError && (
            <>
              <div className='flex items-center justify-between mb-2'>
                <span className='text-xs'>{formatTime(currentTime)}</span>
                <div className='flex-grow mx-2'>
                  <input
                    type='range'
                    min={0}
                    max={duration}
                    value={currentTime}
                    onChange={handleSeek}
                    className='w-full h-1 accent-[#65C2D7]'
                  />
                </div>
                <span className='text-xs'>{formatTime(duration)}</span>
              </div>
              <div className='flex justify-center items-center space-x-4'>
                <button
                  className='p-1'
                  onClick={() => {
                    if (isYouTube && youtubePlayerRef.current) {
                      youtubePlayerRef.current.seekTo(
                        Math.max(0, currentTime - 10),
                        true,
                      )
                    } else if (videoRef.current) {
                      videoRef.current.currentTime = Math.max(
                        0,
                        videoRef.current.currentTime - 10,
                      )
                    }
                  }}
                >
                  <SvgIcon
                    src={BackwardPlayIcon}
                    alt='Backward'
                    className='w-5 h-5'
                  />
                </button>
                <button
                  className='bg-white text-[#020251] rounded-full p-2'
                  onClick={handlePlayPause}
                >
                  {isPlaying ? (
                    <SvgIcon src={PauseIcon} alt='Pause' className='w-4 h-4' />
                  ) : (
                    <SvgIcon
                      src={PlayIcon}
                      alt='Play'
                      className='w-4 h-4 transform scale-[1.75] translate-y-[20%]'
                    />
                  )}
                </button>
                <button
                  className='p-1'
                  onClick={() => {
                    if (isYouTube && youtubePlayerRef.current) {
                      youtubePlayerRef.current.seekTo(
                        Math.min(duration, currentTime + 10),
                        true,
                      )
                    } else if (videoRef.current) {
                      videoRef.current.currentTime = Math.min(
                        videoRef.current.duration,
                        videoRef.current.currentTime + 10,
                      )
                    }
                  }}
                >
                  <SvgIcon
                    src={ForwardPlayIcon}
                    alt='Forward'
                    className='w-5 h-5'
                  />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    )
  },
)

export default VideoPlayer
