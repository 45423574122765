import React from 'react'

type Props = {
  size?: number
  icon?: string
  title?: string
  caption?: string
  bgColor?: string
}

const BoxIcon = ({ icon, size, title, caption, bgColor }: Props) => {
  return (
    <div className='box'>
      <div className='card border-0 py-5' style={{ backgroundColor: bgColor }}>
        <div className='card-body'>
          {icon && (
            <img className='mx-auto mb-3' width={size} src={icon} alt='' />
          )}
          {title && <h1>{title}</h1>}
          {caption && <p>{caption}</p>}
        </div>
      </div>
    </div>
  )
}

export default BoxIcon
