import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import './index.scss'
import PodcastsCard from 'components/cards/PodcastCard'
import { data, speakers } from '../../data/PodcastData'
import PodcastFilter from './components/PodcastFilter'
import SpeakerList from './components/SpeakerList'
import { BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs'
import PodcastNewsletter from './components/PodcastNewsletter'
import { background } from 'assets/backgrounds'

const Podcasts = () => {
  const sliderRef = useRef<any>(null)
  const next = () => {
    sliderRef.current.slickNext()
  }
  const previous = () => {
    sliderRef.current.slickPrev()
  }
  const [filterData, setFilterData] = useState<any>(data)
  const [episodeToShow, setEpisodeToShow] = useState(4)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const handleShowMore = () => {
    let newRecord = 0
    if (episodeToShow < filterData.length) {
      newRecord = episodeToShow + 4
    } else {
      newRecord = episodeToShow - 4
    }
    console.log(newRecord)
    setEpisodeToShow(newRecord)
  }

  return (
    <section id='podcasts'>
      <div className='banner'>
        <div className='layer'>
          <h1>Our conversations on legal life events</h1>
          <p className='mt-10'>
            Unlock a wealth of legal knowldge by diving into our exclusive
            podcast insights.
          </p>
          <div className='stats mt-10'>
            <div className='item'>
              <h4>50+</h4>
              <p>podcasts recorded</p>
            </div>
            <div className='item'>
              <h4>10+</h4>
              <p>podcasts hosts</p>
            </div>
            <div className='item'>
              <h4>20+</h4>
              <p>legal needs discussed</p>
            </div>
          </div>
        </div>
        <div className='layer'>
          <img src={background.Podcast.Hero} alt='' />
        </div>
      </div>

      <div className='latest-podcasts'>
        <h1 className='heading'>Latest podcasts episodes</h1>
        <PodcastFilter data={data} setFilterData={setFilterData} />
        <div className='episodes'>
          {filterData.slice(0, episodeToShow).map((episode, idx) => (
            <div className='col-md-3'>
              <PodcastsCard key={idx} episode={episode} />
            </div>
          ))}
        </div>

        <div className='see_more '>
          <button onClick={handleShowMore} className='mt-10 btn'>
            {episodeToShow < filterData.length ? 'Show more' : 'Show less'}
          </button>
        </div>
      </div>

      {speakers && (
        <div className='speakers'>
          <div className='layer list'>
            <Slider
              ref={(slider) => {
                sliderRef.current = slider
              }}
              className='item'
              {...settings}
            >
              {speakers.map((speaker, idx) => (
                <SpeakerList data={speaker} key={idx} />
              ))}
            </Slider>
            <div className='controls'>
              <BsFillCaretLeftFill
                size={25}
                className='prev'
                onClick={previous}
              />
              <BsFillCaretRightFill size={25} className='next' onClick={next} />
            </div>
          </div>
          <div className='layer'>
            <h1>Top Speakers</h1>
            <hr />
            <p>
              Some text here lorem ipsum lorem ipsum lorem ipsum lorem ipsum
              lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem
              ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.
            </p>
          </div>
          <div className='layer'>
            <h4>Become a speaker</h4>
            <p className='mt-5'>
              Some text here lorem ipsum lorem ipsum lorem ipsum lorem ipsum
              lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem
              ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.
            </p>
            <button className='btn mt-5'>Contact Us</button>
          </div>
        </div>
      )}
      <PodcastNewsletter />
    </section>
  )
}

export default Podcasts
