import React from 'react'

const Sectors: React.FC = () => {
  const sectors = [
    { name: 'Aviation', count: 20 },
    { name: 'Energy', count: 20 },
    { name: 'Gas and Oil', count: 20 },
    { name: 'Advertising', count: 20 },
    { name: 'Transport', count: 20 },
  ]

  return (
    <div className='my-8 sm:my-12 md:my-[20%]'>
      <h2 className="text-[#001A3D] font-['DM_Sans'] text-xl sm:text-3xl md:text-[32px] font-bold leading-normal mb-4 sm:mb-6 text-center">
        Sectors
      </h2>
      <div className='space-y-3 sm:space-y-4'>
        {sectors.map((sector, index) => (
          <React.Fragment key={sector.name}>
            <div className="flex justify-between items-center font-['DM_Sans'] text-sm sm:text-base md:text-[16px] font-medium leading-tight sm:leading-[24px] tracking-[0.4px] text-[#001A3D]">
              <span className='mr-2'>{sector.name}</span>
              <span>{sector.count}</span>
            </div>
            {index < sectors.length - 1 && (
              <div className='border-b border-dotted border-[#9FCDD7]'></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default Sectors
