import React from 'react'
import PlayIcon from '../../../assets/play_icon.svg'

interface InterviewCardLargeProps {
  image: string
  title: string
  description: string
  duration: string
}

const SvgIcon = ({ src, alt, ...props }) => (
  <img src={src} alt={alt} {...props} />
)

const InterviewCardLarge: React.FC<InterviewCardLargeProps> = ({
  image,
  title,
  description,
  duration,
}) => {
  return (
    <div className='bg-transparent rounded-lg shadow-md overflow-hidden flex flex-col h-auto sm:h-[400px] w-full sm:w-[90vw] md:w-[80vw] lg:w-[30vw] mx-auto'>
      <div className='h-48 sm:h-1/2 overflow-hidden'>
        <img src={image} alt={title} className='w-full h-full object-cover' />
      </div>
      <div className='p-4 flex flex-col justify-between flex-grow'>
        <div>
          <h3 className='text-lg font-semibold mb-2'>{title}</h3>
          <p className='text-gray-600 mb-4 text-sm sm:text-base'>{description}</p>
        </div>
        <div className='flex items-center justify-between'>
          <div className='flex items-center flex-row'>
            <button className='flex items-center bg-[#F4F4F4] text-[#A0ABBE] rounded-full p-2 hover:bg-[#E4E4E4] transition-colors duration-200 shadow-[0px_0.4px_4px_rgba(0,0,0,0.25)]'>
              <SvgIcon
                src={PlayIcon}
                alt='Play'
                className='w-3 h-3 sm:w-4 sm:h-4 transform scale-[1.25] translate-y-[10%]'
              />
            </button>
            <span className='ml-2 text-xs sm:text-sm'>Play this interview</span>
          </div>
          <span className='text-xs sm:text-sm text-gray-600'>{duration}</span>
        </div>
      </div>
    </div>
  )
}

export default InterviewCardLarge
