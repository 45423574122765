import React from 'react'
import { steps } from '../../../data/home.dummy'
import { Step } from 'components/utils/Step'

const HowItWorks = () => {
  return (
    <section id='how-it-works' className='flex justify-center'>
      <div className='col-md-9'>
        <div className='card'>
          <h5>How it works</h5>
          <div id='step' className='row text-center mt-5'>
            {steps.map((step, idx) => (
              <div className='col-md-3'>
                <Step data={step} length={steps.length} key={idx} count={idx} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
