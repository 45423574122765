import React, { FC, MouseEventHandler, useState } from 'react'

import PhoneIcon from '../../assets/phone_light.svg'
import InfoIcon from '../../assets/info_light.svg'
import EmailIcon from '../../assets/email_light.svg'

import { MembershipType, BarristerProfile } from 'common/state-type'
import { getCardTheme } from 'utils/StylesUtil'
import useAreasOfPractice from 'common/providers/useAreasOfPractice'
import Utils from 'common/utils/utils'
import { InfoItem, InfoPopup } from 'components/CWP Sections/components'

import { IconButton, IconButtons2 } from './LawFirmProfileCard'
import useFavourite from 'common/providers/useFavourite'
import { AccountType } from '../../../../common/state-type'
import { Button } from 'components/forms/Buttons'

enum CardMode {
  FAVOURITE,
  APPOINTMENT,
}

type BarristerProfileCardType = {
  onMouseEnter?: MouseEventHandler<HTMLDivElement>
  data: BarristerProfile
  onFavPress?: () => void
  onBookNow?: () => void
  onReviewBooking?: () => void
  bookingStatus?: React.ReactNode
  className?: string
  mode: CardMode
}

const BarristerProfileCard: FC<BarristerProfileCardType> = ({
  data,
  onMouseEnter,
  onFavPress,
  onBookNow,
  onReviewBooking,
  bookingStatus,
  className,
  mode,
}) => {
  const { areasOfPractice } = useAreasOfPractice()

  const [showPhoneNumber, setShowPhoneNumber] = useState(false)
  const [isFavorited, setIsFavorited] = useState(!!data.favourite_id)
  const { createFavourite, deleteFavourite } = useFavourite()

  const aop =
    data.areas_of_practice &&
    areasOfPractice &&
    areasOfPractice.filter((e) => data.areas_of_practice.includes(e.id))

  const operatingAreas = aop?.map((item) => item.name)

  const handleFavPress = () => {
    if (data?.id) {
      onFavPress()
      if (isFavorited) {
        const del = {
          id: data.id,
          type: AccountType.BARRISTER,
        }
        deleteFavourite(del)
        data.favourite_id = null
      } else {
        const type = Utils.getUserType(AccountType.BARRISTER)

        createFavourite({ [type]: data.id })
        // .then((response) => {
        //   data.favourite_id = response.id
        // })
        // .catch((error) => {
        //   console.error('Error creating favourite:', error)
        // })
      }
      onFavPress?.()
    }
    setIsFavorited(!isFavorited)
  }

  const handlePhoneClick = () => {
    setShowPhoneNumber(!showPhoneNumber)
  }

  const handleEmailClick = () => {
    window.location.href = `mailto:customerservice@legalutopia.co.uk`
  }

  const handleWebsiteClick = () => {
    if (data.website_url) {
      window.open(data.website_url, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <div
      id={`profile-${data.id}`}
      onMouseEnter={onMouseEnter}
      className={` w-full ${
        mode === CardMode.FAVOURITE ? 'h-[30rem]' : 'h-[18rem]'
      } bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 relative ${
        className ?? ''
      }`}
    >
      <div
        className={` ${mode === CardMode.FAVOURITE ? ' h-[30%]' : ' h-[50%]'}
           bg-gradient-to-b from-blue-300 to-blue-100 transform -skew-y-6 origin-top-left`}
      ></div>

      <div
        className={`relative ${
          mode === CardMode.FAVOURITE ? '-mt-16' : '-mt-12'
        } px-6`}
      >
        <div className='flex justify-between items-start '>
          <div className=''>
            <IconButton iconSrc={EmailIcon} onClick={handleEmailClick} />
            <div className=''>
              <IconButton iconSrc={PhoneIcon} onClick={handlePhoneClick} />
              {showPhoneNumber && (
                <button
                  onClick={handlePhoneClick}
                  className='absolute left-0 top-0 bg-white rounded-full py-2 px-2 text-xs shadow-md whitespace-nowrap'
                >
                  {data.phone_number || 'N/A'}
                </button>
              )}
            </div>
            {mode === CardMode.FAVOURITE ? (
              <IconButton iconSrc={InfoIcon} onClick={handleWebsiteClick} />
            ) : (
              <></>
            )}
          </div>
          <div className='flex flex-col items-center -mt-5'>
            <h2 className='text-lg font-semibold text-center mb-2'>
              {data.full_name}
            </h2>
            <p>{data.number_of_practice_years}y of experience</p>
            <div className='w-[40px] h-[40px] bg-blue-100 rounded-full flex items-center justify-center shadow- border-2 border-[white]'>
              <span className='text-[14px] font-'>
                {data.full_name[0]}
                {data.full_name.split(' ')[1]?.[0] ?? ''}
              </span>
            </div>
          </div>
          <div className='space-y-2 -mt-14'>
            <IconButtons2
              isFavorited={isFavorited}
              onFavPress={onFavPress}
              className='space-y-4'
            />
          </div>
        </div>

        {mode === CardMode.FAVOURITE ? (
          <div className='mt-2 overflow-y-auto'>
            <p className='text-sm text-gray-600 text-center mt-2 max-w-[90%] mx-auto'>
              {data.bio || 'No bio available'}
            </p>
          </div>
        ) : (
          <></>
        )}

        <div className='overflow-y-auto'>
          {operatingAreas && operatingAreas.length > 0 && (
            <div className='mt-4'>
              <h3 className='text-sm font-semibold mb-2'>Areas of law</h3>
              <div className='flex flex-wrap gap-2'>
                {operatingAreas.slice(0, 3).map((area, index) => (
                  <span
                    key={index}
                    className='px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-xs'
                  >
                    {area}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className={`${'h-0 overflow-hidden'} transition-all duration-300`}>
          <div className='space-y-1 text-xs text-gray-600'>
            <InfoItem
              title='Date of Call'
              content={data.date_of_call ?? 'N/A'}
            />
            <InfoItem
              title='Chambers Name'
              content={data.chamber_name ?? 'N/A'}
            />
            <InfoItem
              title='Primary Status'
              content={data.primary_status?.toLowerCase() ?? 'N/A'}
            />
            <InfoItem
              title='Member Status'
              content={data.member_status?.toLowerCase() ?? 'N/A'}
            />
            {data.memberships && data.memberships.length > 0 && (
              <InfoItem
                title='Membership'
                content={data.memberships
                  .map((m) => Utils.capitalizeFirstLetter(m.name))
                  .join(', ')}
              />
            )}
            {data.honours && data.honours.length > 0 && (
              <InfoItem
                title='Honours'
                content={data.honours
                  .map((h) => Utils.capitalizeFirstLetter(h.name))
                  .join(', ')}
              />
            )}
            <InfoItem
              title='Years of Experience'
              content={
                data.date_of_call
                  ? `${Utils.getAge(data.date_of_call)} years`
                  : 'N/A'
              }
            />
          </div>
        </div>
      </div>
      <div className='absolute bottom-0 left-0 right-0 bg-[#6596D7] p-4'>
        <div className='flex justify-between items-center'>
          {mode === CardMode.APPOINTMENT ? (
            <>
              <div className='text-white'>
                <div className='text-[14px] font-light leading-normal'>
                  {data.date_of_call}
                </div>
              </div>
              <Button
                type='submit'
                className='btn-default !px-0 !mx-0 !w-[20%]'
                onClick={onReviewBooking}
              >
                <span className='!px-0 !mx-0 text-[12px] text-[#1C7D93]'>
                  View
                </span>
              </Button>
            </>
          ) : (
            <>
              <div className='text-white'>
                <div className='flex items-baseline'>
                  <span className='text-lg font-bold'>
                    From £
                    {data.rate_per_hour_excluding_vat?.toFixed(2) ?? 'Nil'}
                  </span>
                  <span className='text-xs ml-1'>p/hr</span>
                </div>
                <p className='text-xs'>Estimated cost</p>
              </div>

              <Button
                type='submit'
                className='btn-default !px-0 !mx-0 !w-auto'
                onClick={onBookNow}
              >
                <span className='px-1'>{'Book Now'}</span>
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export { BarristerProfileCard, CardMode }
