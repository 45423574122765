import React from 'react'
import { Button } from '../../../../../common/components/Authentication/Button'
import PlayIcon from '../../../assets/play_icon.svg'
import downloadIcon from '../../../assets/download_icon.svg'
import thumbnail from '../../../assets/image_placeholder.svg'

import ShareIcon from '../../../assets/share.svg'
import HeartIcon from '../../../assets/heart_icon.svg'

const SvgIcon = ({ src, alt, ...props }) => (
  <img src={src} alt={alt} {...props} />
)

export const InterviewsSection = () => (
  <section className='my-4 mx-6'>
    <h3 className='text-xl font-semibold mb-6'>Our interviews</h3>
    <div className='space-y-4'>
      {[1, 2].map((interview) => (
        <InterviewCard
          key={interview}
          author={{
            name: 'Martin Manson',
            publishDate: '25/05/2024',
          }}
          title='Title'
          description='Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum'
          duration='1 hr, 24 min'
          tags={['Conveyancing', 'Legal long title', 'Conveyancing']}
          plays={20}
        />
      ))}
    </div>
  </section>
)

export const InterviewCard = ({
  author,
  title,
  description,
  duration,
  tags,
  plays,
}) => {
  return (
    <div className='bg-white rounded-lg shadow-md p-4 space-y-4 '>
      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-4 sm:space-y-0'>
        <div className='flex items-center space-x-3'>
          <img src={thumbnail} className='w-8 h-8 rounded-full' />
          <div>
            <h4 className='font-semibold text-sm text-gray-900'>
              {author.name}
            </h4>
            <p className='text-xs text-gray-500'>
              Published on {author.publishDate}
            </p>
          </div>
        </div>
        <div className='flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4'>
          <div className='flex flex-wrap gap-2'>
            {tags.map((tag, index) => (
              <span
                key={index}
                className='px-3 py-1 bg-blue-100 text-blue-800 text-xs rounded-full'
              >
                {tag}
              </span>
            ))}
          </div>
          <div className='flex items-center space-x-4'>
            <div className='flex items-center space-x-1'>
              <SvgIcon src={HeartIcon} alt='Like' className='w-5 h-5' />
              <span className='text-sm text-gray-600'>{plays} plays</span>
            </div>
            <SvgIcon src={ShareIcon} alt='Share' className='w-5 h-5' />
            <div className='flex items-center space-x-4'>
              <span className='text-sm text-gray-500'>{duration}</span>
              <div className='w-24 sm:w-48 bg-gray-200 rounded-full h-1'>
                <div className='bg-blue-600 h-1 rounded-full w-1/4'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 className='font-bold text-xl text-gray-900'>{title}</h3>
        <p className='text-sm text-gray-600 mt-2'>{description}</p>
      </div>
      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-4 sm:space-y-0'>
        <div></div>
        <div className='flex items-center justify-end space-x-4'>
          <Button variant='quaternary' className='text-sm '>
            <SvgIcon
              src={downloadIcon}
              alt='Download'
              className='w-4 h-4 mr-2'
            />
            Download transcript
          </Button>
        </div>
      </div>
    </div>
  )
}
