import React, { useState } from 'react'
import {
  Modal,
  Box,
  Typography,
  TextField,
  Link,
  CircularProgress,
  IconButton,
} from '@mui/material'
import { useFormik } from 'formik'
import { Button } from '../../../../common/components/Authentication/Button'
import * as yup from 'yup'

import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import { ReactComponent as VeryDissatisfiedIcon } from '../../assets/very_dissatisfied.svg'
import { ReactComponent as DissatisfiedIcon } from '../../assets/dissatisfied.svg'
import { ReactComponent as NeutralIcon } from '../../assets/neutral.svg'
import { ReactComponent as SatisfiedNormalIcon } from '../../assets/satisfied_alt.svg'
import { ReactComponent as SatisfiedIcon } from '../../assets/satisfied.svg'
import { ReactComponent as VerySatisfiedIcon } from '../../assets/very_satisfied.svg'
import { ReactComponent as UploadIcon } from '../../assets/upload_file.svg'
import { ReactComponent as VeryDissatisfiedFilledIcon } from '../../assets/very_dissatisfied_filled.svg'
import { ReactComponent as DissatisfiedFilledIcon } from '../../assets/very_dissatisfied_filled1.svg'
import { ReactComponent as SatisfiedNormalFilledIcon } from '../../assets/very_satisfied_alt.svg'
import { ReactComponent as NeutralFilledIcon } from '../../assets/neutral_filled.svg'
import { ReactComponent as SatisfiedFilledIcon } from '../../assets/satisfied_filled.svg'
import { ReactComponent as VerySatisfiedFilledIcon } from '../../assets/very_satisfied_filled.svg'
import { saveFeedback, uploadFile } from 'common/firebase/useFirestore'
import { useAuth } from '../../common/providers/useAuth'
import { BsXLg } from 'react-icons/bs'

const validationSchema = yup.object({
  feedback: yup.string().required('Feedback is required'),
})

interface FeedbackModalProps {
  description?: string
  title?: string
  open: boolean
  closeModal?: () => void
}

const icons = [
  {
    Icon: VeryDissatisfiedIcon,
    FilledIcon: DissatisfiedFilledIcon,
    value: 1,
    label: 'Bad',
  },
  {
    Icon: DissatisfiedIcon,
    FilledIcon: VeryDissatisfiedFilledIcon,
    value: 2,
    label: 'Not good',
  },
  {
    Icon: NeutralIcon,
    FilledIcon: NeutralFilledIcon,
    value: 3,
    label: 'Standard',
  },
  {
    Icon: SatisfiedIcon,
    FilledIcon: SatisfiedFilledIcon,
    value: 4,
    label: 'Good',
  },
  {
    Icon: SatisfiedNormalIcon,
    FilledIcon: SatisfiedNormalFilledIcon,
    value: 5,
    label: 'Great',
  },
  {
    Icon: VerySatisfiedIcon,
    FilledIcon: VerySatisfiedFilledIcon,
    value: 6,
    label: 'Exceptional',
  },
]

const Feedback: React.FC<FeedbackModalProps> = ({ open, closeModal }) => {
  const { profile } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [selectedRating, setSelectedRating] = useState<number | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [feedbackError, setFeedbackError] = useState<string | null>(null)
  const [uploadedFile, setUploadedFile] = useState<{
    name: string
    time: string
  } | null>(null)

  const handleClosePressed = () => {
    setShowSuccessMessage(false)
    setUploadedFile(null)
    closeModal()
  }

  const formik = useFormik({
    initialValues: {
      rating: 0,
      feedback: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (profile) {
        setIsSubmitting(true)
        setFeedbackError(null)
        try {
          await saveFeedback(
            values.rating,
            values.feedback,
            profile,
            selectedFile || undefined,
          )
          setShowSuccessMessage(true)
          setSelectedRating(null)
          setSelectedFile(null)
        } catch (err) {
          console.error('Error saving feedback:', err)
          setFeedbackError('Failed to save feedback. Please try again.')
        } finally {
          setIsSubmitting(false)
        }
      }
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      setSelectedFile(file)
      setUploadedFile({
        name: file.name,
        time: new Date().toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }),
      })
    }
  }

  const truncateFileName = (fileName: string, maxLength = 20) => {
    if (fileName.length <= maxLength) return fileName
    const extension = fileName.split('.').pop()
    const nameWithoutExtension = fileName.slice(
      0,
      -(extension?.length || 0) - 1,
    )
    const truncatedName =
      nameWithoutExtension.slice(0, maxLength - 3 - (extension?.length || 0)) +
      '...'
    return `${truncatedName}.${extension}`
  }

  return (
    <Modal
      className='backdrop-blur-[12.5px]'
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeModal()
        }
      }}
    >
      <>
        <button
          onClick={closeModal}
          className='absolute right-10 text-white top-10 '
        >
          <BsXLg size={18} fontWeight={700} stroke='bold' />
        </button>
        <div className='md:w-[800px] w-[95%] left-2 top-10 bg-white absolute md:top-[25%] md:left-[25%] rounded-lg	'>
          {!showSuccessMessage && (
            <div className='bg-gradient-to-r from-[#CCE0DD] to-[#9FB7D7] p-[16px]'>
              <h5 className='text-[24px] text-center font-bold'>Feedback</h5>
            </div>
          )}
          <div className='lg:p-[40px] p-[20px]'>
            {showSuccessMessage ? (
              <div className='relative'>
                <IconButton
                  onClick={handleClosePressed}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <p className='text-[24px]'>Thanks for your feedback</p>
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: { xs: '14px', sm: '14px', md: '18px' },
                  }}
                >
                  Providing this feedback will enable us to continuously improve
                  what we offer.
                </Typography>
              </div>
            ) : (
              <>
                <p className='md:text-[18px] sm:text-[12px] font-bold'>
                  Your feedback is very important to us. How has your experience
                  been so far?
                </p>
                <div className='flex md:gap-10 gap-5 py-10'>
                  {icons.map(({ Icon, FilledIcon, label, value }) => (
                    <div
                      key={value}
                      onClick={() => {
                        setSelectedRating(value)
                        formik.setFieldValue('rating', value)
                      }}
                      className='hover:text-legalColor-blue cursor-pointer'
                    >
                      {selectedRating === value ? (
                        <FilledIcon className='mx-auto' />
                      ) : (
                        <Icon className='mx-auto' />
                      )}
                      <p
                        className={` text-[10px] md:text-[14px] ${
                          selectedRating === value
                            ? 'text-[#00323D]'
                            : 'inherit'
                        }`}
                      >
                        {label}
                      </p>
                    </div>
                  ))}
                </div>
                <p className='font-bold pb-3'>
                  Is there any feedback you can give us to help us improve for
                  next time?
                </p>
                <TextField
                  fullWidth
                  id='feedback'
                  name='feedback'
                  placeholder='We made you feel...'
                  multiline
                  rows={4}
                  value={formik.values.feedback}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.feedback && Boolean(formik.errors.feedback)
                  }
                  helperText={formik.touched.feedback && formik.errors.feedback}
                  sx={{ mb: 2 }}
                />
                <div className='mt-3'>
                  {!uploadedFile ? (
                    <>
                      <label
                        htmlFor='file-upload'
                        className='flex items-center text-sm cursor-pointer'
                      >
                        <UploadIcon className='mr-2 text-[#5858DF]' />
                        <span className='text-[#5858DF] font-medium text-[18px] sm:text-[10px] md:text-[18px]'>
                          <span className='fw-bold text-primary'>Click to</span>{' '}
                          upload a file
                        </span>
                        <input
                          id='file-upload'
                          type='file'
                          className='hidden'
                          onChange={handleFileChange}
                          accept='.svg,.png,.jpg,.jpeg,.gif'
                        />
                      </label>
                      <p className='text-xs text-[#6E7785] mt-1 ml-8 font-medium text-[8px] sm:text-[10px] md:text-[12px]'>
                        SVG, PNG, JPG or GIF (max. 800x400px)
                      </p>
                    </>
                  ) : (
                    <div className='flex items-center text-sm text-[#6E7785]'>
                      <span className='mr-2'>
                        {truncateFileName(uploadedFile.name)}
                      </span>
                      <span>[{uploadedFile.time}]</span>
                    </div>
                  )}
                </div>
                <p className='lg:text-[16px] text-[12px] mt-10'>
                  We process any feedback and data you share with us in
                  accordance with our{' '}
                  <Link href='#' underline='always'>
                    Privacy Policy
                  </Link>
                  . We ask that any feedback you provide does not contain
                  personal data and complies with our Acceptable Use Policy and
                  the Terms of Use which applies to your use of our site.
                </p>
                <div className='flex justify-end mt-10'>
                  <Button
                    variant='primary'
                    className={'sm:!w-[50%]  md:!w-[30%]  '}
                    onClick={() => formik.handleSubmit()}
                    disabled={
                      isSubmitting || !formik.isValid || !selectedRating
                    }
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Submit feedback'
                    )}
                  </Button>
                </div>
                {feedbackError && (
                  <p className='text-danger mt-2'>{feedbackError}</p>
                )}
              </>
            )}
          </div>
        </div>
      </>
    </Modal>
  )
}

export default Feedback
