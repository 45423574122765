import React from 'react'
import discoverMoreCardThumbnail from '../../../assets/discover-more-card-thumbnail.svg'
import { useFirebaseCloudStorage } from '../../../common/firebase/useFirebaseCloudStorage'

const discoverMoreCards = [
  { name: 'Family', image: discoverMoreCardThumbnail },
  { name: 'Family', image: discoverMoreCardThumbnail },
  { name: 'Family', image: discoverMoreCardThumbnail },
  { name: 'Label', image: discoverMoreCardThumbnail },
  { name: 'Business', image: discoverMoreCardThumbnail },
  { name: 'Label', image: discoverMoreCardThumbnail },
  { name: 'Label', image: discoverMoreCardThumbnail },
  { name: 'Label', image: discoverMoreCardThumbnail },
  { name: 'Label', image: discoverMoreCardThumbnail },
  { name: 'Label', image: discoverMoreCardThumbnail },
  { name: 'Label', image: discoverMoreCardThumbnail },
  { name: 'Label', image: discoverMoreCardThumbnail },
  { name: 'Label', image: discoverMoreCardThumbnail },
  { name: 'Label', image: discoverMoreCardThumbnail },
  { name: 'Label', image: discoverMoreCardThumbnail },
]

const discoverMoreCardColors = [
  'B6CFF1',
  '65C2D7',
  'B6CFF1',
  '65C2D7',
  '65C2D7',
  '65C2D7',
  'B6CFF1',
  '65C2D7',
  'B6CFF1',
  '65C2D7',
  '65C2D7',
  '65C2D7',
  '65C2D7',
  '65C2D7',
  '65C2D7',
  'B6CFF1',
]

export const DiscoverMore = () => {
  const { getImages, getAudios, loading, error } =
    useFirebaseCloudStorage('images')
  const images = getImages()

  return (
    <>
      <section className=' text-center md:mt-[250px]'>
        <h2 className='text-[#101828]  text-3xl font-bold leading-normal tracking-[0.4px] mb-4'>
          Discover more on the law
        </h2>
        <p className='text-[#37455C] text-center text-[18px] font-bold   mb-6'>
          Practical guidance and resources on everyday law
        </p>
      </section>

      <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 sm:px-[2%] md: px-[4%] lg:px-[8%]'>
        {discoverMoreCards.map((category, index) => (
          <div
            key={index}
            className={`bg-[#${discoverMoreCardColors[index]}] rounded-3xl  p-4 flex flex-col items-center justify-center `}
          >
            <div className='w-full overflow-hidden pb-2'>
              <img
                src={images[1]}
                alt={category.name}
                className='object-cover rounded-3xl'
              />
            </div>
            <span className='text-sm font-medium mt-1 text-[#101828]'>
              {category.name}
            </span>
          </div>
        ))}
      </div>
    </>
  )
}
