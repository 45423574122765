import React from 'react'
import './navbar.scss'
import Header from './components/Header'
import Footer from './components/Footer'

const AppLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default AppLayout
