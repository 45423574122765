import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BlockContent from '@sanity/block-content-to-react'
import sanityClient from '../../../sanityClient.js'
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}

const Image = ({ node }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <div className='flex justify-center my-8'>
      {!isLoaded && (
        <div className='w-full h-48 bg-gray-200 animate-pulse rounded-lg'></div>
      )}
      <img
        src={urlFor(node.asset).width(800).url()}
        alt={node.alt || ' '}
        className={`max-w-full h-auto rounded-lg shadow-md ${
          isLoaded ? 'block' : 'hidden'
        }`}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  )
}

const serializers = {
  types: {
    image: Image,
  },
}

const BlogPostView = () => {
  const { id } = useParams()
  const [document, setDocument] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    sanityClient
      .fetch(
        `*[_type == "legalDocument" && _id == $id][0]{
          _id,
          title,
          source,
          content,
          sections[]{
            sectionTitle,
            sectionContent
          }
        }`,
        { id },
      )
      .then((data) => {
        console.log('Fetched document:', data)
        setDocument(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }, [id])

  if (loading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500'></div>
      </div>
    )
  }

  if (!document) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <p className='text-xl text-gray-600'>No document found</p>
      </div>
    )
  }

  return (
    <div className='bg-gray-100 min-h-screen py-12'>
      <article className='container mx-auto px-4 sm:px-6 lg:px-8 max-w-3xl bg-white shadow-lg rounded-lg overflow-hidden'>
        <header className='py-8 border-b border-gray-200'>
          <h1 className='text-4xl font-bold text-center text-gray-900 mb-4'>
            {document.title}
          </h1>
          <p className='text-center text-gray-600'>Source: {document.source}</p>
        </header>

        <div className='py-8 px-6'>
          <div className='prose max-w-none'>
            <BlockContent
              blocks={document.content}
              serializers={serializers}
              projectId={sanityClient.config().projectId}
              dataset={sanityClient.config().dataset}
            />
          </div>
        </div>

        {document.sections && document.sections.length > 0 && (
          <div className='border-t border-gray-200 py-8 px-6'>
            <h2 className='text-3xl font-bold text-gray-900 mb-6'>Sections</h2>
            {document.sections.map((section, index) => (
              <section key={index} className='mb-8'>
                <h3 className='text-2xl font-semibold text-gray-900 mb-4'>
                  {section.sectionTitle}
                </h3>
                <div className='prose max-w-none'>
                  <BlockContent
                    blocks={section.sectionContent}
                    serializers={serializers}
                    projectId={sanityClient.config().projectId}
                    dataset={sanityClient.config().dataset}
                  />
                </div>
              </section>
            ))}
          </div>
        )}
      </article>
    </div>
  )
}

export default BlogPostView
