import React from 'react'

const Image = ({ children, ...props }: any) => {
  return (
    <div className='card' style={{ ...props }}>
      <div className='m-auto'>{children}</div>
    </div>
  )
}

export default Image
