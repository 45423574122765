import React, { useContext } from 'react'
import { LinkContext } from 'common/providers/LinkProvider'

const LegalDocList = ({ heading, body, onFunction }: any) => {
  const { Link } = useContext(LinkContext)

  return (
    <div className='card'>
      <div className='card-body'>
        <h3>{heading}</h3>
        <hr className='my-1' />
        <p className='mb-5'>{body}</p>
        <div className='flex  justify-end'>
          <Link className='mt-5' href='./1'>
            Buy template
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LegalDocList
