import React from 'react'
import ContentLayout from 'components/layouts/ContentLayout'
import { useLocation } from 'react-router-dom'

const LegalPagesDocs = () => {
  const { state } = useLocation()

  return (
    <ContentLayout title={state?.title}>
      <h1 className='md:text-[40px] text-[16px] max-sm:text-center text-[#001A3D] font-bold nb'>
        {state?.title}
      </h1>
      <div
        className='md:text-[16px] text-[12px] font-light text-[#25395C] text-justify tracking-[0.4px] '
        dangerouslySetInnerHTML={{
          __html: state?.content.replace(/&lt;meta charset="UTF-8"&gt;/g, ''),
        }}
      />
    </ContentLayout>
  )
}

export default LegalPagesDocs
