import { background } from 'assets/backgrounds'
import { Button } from 'components/forms/Buttons'
import React from 'react'

const LawTech = () => {
  return (
    <section
      id='lawtech'
      className='text-center relative my-[300px]'
      // style={{ margin: '250px 50px' }}
    >
      <img
        src={background.WhoWeAre.CurveRight}
        className='absolute right-0 md:top-[-300px] top-[-20px] z-[-1] md:w-[200px] w-[100px]'
        alt=''
      />
      <h1 className='my-4 md:text-[40px] font-bold text-primary'>
        Lawtech Around the World
      </h1>
      <div className='flex flex- justify-center'>
        <div className='col-md-9'>
          <div className='row'>
            {Array(4)
              .fill(0)
              .map(() => (
                <div className='col-md-3'>
                  <div className='card '>
                    <div
                      className='card-cover'
                      style={{
                        background: `url(${background.WhoWeAre.LawTech}) lightgray 50% / cover no-repeat`,
                      }}
                    ></div>
                    <div className='card-body text-start !z-[1]'>
                      <h4 className='fw-bold'>Label</h4>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Enim lobortis
                        facilisis ac nunc tincidunt ut massa nec etiam. At sed
                        lorem faucibus eleifend mauris ut.
                      </p>
                      <div className='flex justify-end'>
                        <Button className='btn-default mt-4'>View</Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <img
        src={background.WhoWeAre.CurveLeft}
        className='absolute left-0 md:bottom-[-500px] bottom-[-120px] !z-[-99999999] md:w-[200px] w-[100px]'
        alt=''
      />
    </section>
  )
}

export default LawTech
