import { Checkbox, TextField } from '@mui/material'
import React from 'react'

export const Newsletter = () => {
  return (
    <div className='section'>
      <div className='flex justify-center'>
        <div className='col-md-9 bg-theme-light-2 text-center rounded-pill'>
          <div className='p-5 my-3'>
            <h1 className='display-5 fw-bold mb-3'>Join our newsletter</h1>
            <p className='fs-lg text-muted'>
              Some text here lorem ipsum lorem ipsum lorem ipsum lorem ipsum
              lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem
              ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.
            </p>
            <div className='my-4 text-center'>
              <TextField
                sx={{ width: { md: '400px', xs: '100%' } }}
                placeholder='Type what you are searching for...'
                name='search'
                variant='outlined'
              />
              <div className='flex items-center justify-center'>
                <Checkbox className='pl-0 ms-0' />
                <span>
                  I agree to the <a href=''>Privacy policy</a> and{' '}
                  <a href=''>Marketting policy</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
