import React from 'react'
import InterviewList from 'components/cards/InterviewList'
import { background } from 'assets/backgrounds'

const InterviewSection = () => {
  return (
    <div>
      <div className='py-[40px] md:px-[32px] my-[100px] bg-[#EBEBFF] rounded-xl w-full p-2'>
        <h1 className='mb-7 md:text-[18px]'>Our Interview</h1>

        <div className='h-[530px] overflow-auto'>
          {Array(3)
            .fill(0)
            .map(() => (
              <div className='md:mb-10 mb-1'>
                <InterviewList />
              </div>
            ))}
        </div>
      </div>
      <img
        src={background.discoverLaw._03}
        className='absolute mt-[-200px] right-0 z-[-2]'
        alt=''
      />

      {/* <img
        src={background.discoverLaw._03}
        className='absolute m-[-100px] left-0 z-[-2]'
        alt=''
      /> */}
    </div>
  )
}

export default InterviewSection
