import React from 'react'
import { socials } from 'assets/socials'
import {
  Box,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material'
import { BsSearch } from 'react-icons/bs'
import { InputGroupField } from 'components/forms/InputFields'

const DiscoverLawFilterSection = () => {
  return (
    <section className='container md:hidden  my-5'>
      <div className='flex justify-center gap-5'>
        <img src={socials.dark.linkedin} className='h-[16px] w-[16px]' alt='' />
        <img src={socials.dark.mail} className='h-[16px] w-[16px]' alt='' />
        <img src={socials.dark.twitter} className='h-[16px] w-[16px]' alt='' />
      </div>
      <div className='filter  my-10'>
        <div className='flex flex-column-sm'>
          <div className='components me-0 md:me-4'>
            <InputGroupField icon={<BsSearch />} reverse className='search' />
          </div>
          <div className='flex items-center justify-between mt-5'>
            <select className='w-[70%] px-3 py-2 border-2'>
              {/* {FilterByList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))} */}
            </select>
            <button className='text-text-muted'>Clear Filters</button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DiscoverLawFilterSection
