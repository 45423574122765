import React from 'react'

type Props = {
  color: string
  title: string
}

export const Badge = ({ color, title }: Props) => {
  return <div className={`badge badge-${color}`}>{title}</div>
}

type AvatarProps = {
  name?: string
  caption?: string
  picture: string
  className?: any
  sx?: any
  url?: string
  urlName?: string
  tx?: number
}
export const Avatar = ({
  name,
  caption,
  picture,
  className,
  sx,
  url,
  urlName,
  tx,
}: AvatarProps) => {
  return (
    <div className={`avatar flex ${className}`}>
      <img
        className='br-50'
        style={{
          background: `url(${picture}) lightgray 50% / cover no-repeat`,
          height: `${sx ? sx + 'px' : '36px'}`,
          width: `${sx ? sx + 'px' : '36px'}`,
        }}
      />
      <div className='ml-2'>
        {name && <h4 className={`text-[${tx ? tx : '12'}px]`}>{name}</h4>}
        {caption && (
          <p className={`text-[${tx ? tx - 2 : '12'}px]`}>{caption}</p>
        )}
        {url && (
          <a className='fs-xs' href={url}>
            {urlName}
          </a>
        )}
      </div>
    </div>
  )
}
