import React from 'react'

const PublishedCategories: React.FC = () => {
  const categories = [
    { name: 'Category 1', count: 12 },
    { name: 'Category 2', count: 20 },
    { name: 'Category 3', count: 28 },
    { name: 'Category 4', count: 39 },
  ]

  return (
    <div className='text-center my-8 sm:my-12'>
      <h2 className="font-['DM_Sans'] text-xl sm:text-3xl md:text-[32px] font-bold leading-normal text-[#001A3D] mb-4 sm:mb-6">
        Most published categories
      </h2>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
        {categories.map((category) => (
          <div
            key={category.name}
            className='bg-[#CFFBF5] p-3 sm:p-4 rounded-md flex flex-col items-center'
          >
            <span className="font-['DM_Sans'] text-2xl sm:text-3xl md:text-[32px] font-bold leading-normal text-[#001A3D] block">
              {category.count}
            </span>
            <span className="font-['DM_Sans'] text-sm sm:text-base md:text-[16px] font-medium leading-[24px] tracking-[0.4px] text-[#001A3D]">
              {category.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PublishedCategories
