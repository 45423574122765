import { FC } from 'react'

export const Error: FC<{ width: string; height: string }> = ({
  width,
  height,
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='12px / close_circle'>
      <path
        id='Subtract'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10ZM7.95358 4.04684C8.14884 4.2421 8.14884 4.55868 7.95358 4.75394L6.70713 6.00039L7.95358 7.24684C8.14884 7.4421 8.14884 7.75868 7.95358 7.95394C7.75832 8.14921 7.44173 8.14921 7.24647 7.95394L6.00002 6.7075L4.75358 7.95394C4.55832 8.14921 4.24173 8.14921 4.04647 7.95394C3.85121 7.75868 3.85121 7.4421 4.04647 7.24684L5.29292 6.00039L4.04647 4.75394C3.85121 4.55868 3.85121 4.2421 4.04647 4.04684C4.24173 3.85158 4.55832 3.85158 4.75358 4.04684L6.00002 5.29328L7.24647 4.04684C7.44173 3.85158 7.75832 3.85158 7.95358 4.04684Z'
        fill='#CE2815'
      />
    </g>
  </svg>
)
