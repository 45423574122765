import React from 'react'
import { Badge, Avatar } from 'components/Utils'
import { BsCalendar, BsClock } from 'react-icons/bs'

type MostViewedProps = {
  data: any
}

export const MostViewedCard = ({ data }: MostViewedProps) => {
  return (
    <>
      {data &&
        data.slice(0, 2).map((blog, idx) => (
          <div key={idx} className='my-2'>
            <div className='flex gap-2'>
              {blog.tags.map((tag, idx) => (
                <Badge key={idx} title={tag} color='primary' />
              ))}
            </div>
            <h3 className='my-2 h3'>{blog.title}</h3>
            <div className='flex items-center flex-wrap'>
              <Avatar
                picture={blog.author[0].avatar}
                name={blog.author[0].name}
                caption={blog.author[0].role}
                className={'br-lg-theme'}
              />
              <div className='flex items-center  br-lg-theme self-stretch pl-lg-2'>
                <BsCalendar className='mr-2' /> {blog.created_at}
              </div>
              <div className='flex items-center pl-lg-2'>
                <BsClock className='mr-2' /> {blog.read_duration} min to read
              </div>
            </div>
            <p className='text-muted my-3'>{blog.description}</p>
          </div>
        ))}
    </>
  )
}

type LatestProps = {
  data: any
}

export const LatestCard = ({ data }: LatestProps) => {
  return (
    <div className='mx-auto'>
      {data &&
        data.map((blog, idx) => (
          <div className='card bg-white mx-auto my-1'>
            <div className='card-body'>
              <div className='flex gap-3 mb-3'>
                {blog.tags.map((tag, idx) => (
                  <Badge key={idx} title={tag} color='primary' />
                ))}
              </div>
              <h3 className='my-2'>{blog.title}</h3>
              <div className='flex gap-5'>
                <Avatar
                  picture={blog.author[0].avatar}
                  name={blog.author[0].name}
                  caption={blog.author[0].role}
                  className={'br-theme'}
                />
                <div className='flex items-center gap-3 text-lighter'>
                  <BsClock /> {blog.read_duration} min to read
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

type PostProps = {
  data: any
}

export const PostCard = ({ data }: PostProps) => {
  return (
    <>
      <div className='card'>
        <img
          style={{
            height: '191px',
            width: '100%',
            background: `url(${data.cover}) lightgray 50% / cover no-repeat`,
          }}
        />
        <div className='card-body'>
          <h5>{data.title}</h5>
          <p>{data.description}</p>
          <div className='flex gap-2 my-1'>
            {data.tags &&
              data.tags.map((tag, idx) => (
                <Badge key={idx} title={tag} color='primary' />
              ))}
          </div>
          <hr />
          <div className=' badge badge-primary mt-3'>
            <Avatar
              className={'pr-2'}
              picture={data.author[0].avatar}
              caption={data.author[0].role}
              name={data.author[0].name}
            />
          </div>
        </div>
      </div>
    </>
  )
}
