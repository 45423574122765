import { useState } from 'react'
import { PageBanner } from 'components/PageBanner'
import LegalDocList from './components/LegalDocList'
import { data, FilterTabList } from '../../data/legalDocsData'
import FilterOption from './components/FilterOptions'
import './index.scss'

const LegalDocumentsPage = () => {
  const [filteredData, setFilteredData] = useState(data)
  const [current, setCurrent] = useState('All')

  const handleTabFilters = (filter: any) => {
    let filterData = data
    setCurrent(filter)
    if (filter !== 'All') {
      filterData = data.filter((item: any) => item.category === filter)
    }
    setFilteredData(filterData)
  }

  return (
    <>
      <PageBanner>LegalDocs</PageBanner>

      <div id='legal-documents'>
        <div className='container-fluid'>
          <FilterOption data={data} updateFilteredData={setFilteredData} />

          <section id='filter' className='mt-20 mb-5'>
            <div className='flex flex-wrap justify-center-sm'>
              {FilterTabList.map((item, idx) => (
                <div className=''>
                  <button
                    onClick={() => handleTabFilters(item)}
                    className={` md:me-3 me-5 ${
                      current === item ? 'active' : ''
                    }`}
                  >
                    {item}
                  </button>
                </div>
              ))}
            </div>
          </section>

          <section className='library '>
            <div className='row'>
              {data &&
                filteredData.map((docs, idx) => (
                  <div className='col-md-3 mb-5'>
                    <LegalDocList
                      key={idx}
                      heading={docs.title}
                      body={docs.description}
                    />
                  </div>
                ))}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default LegalDocumentsPage
