import React, { useState } from 'react'
import { PageBanner } from 'components/PageBanner'
import { TableBody, TableHeader } from './components/Card'
import { data } from 'data/DocumentLibraryData'
import './index.scss'
import FilteringPane from './components/Filter'

const DocumentLibrary = () => {
  const [state, setState] = useState()
  const [libraryList, setLibraryList] = useState(data)

  return (
    <>
      <PageBanner>Document Library</PageBanner>
      <div className='container'>
        <section id='document-library'>
          <FilteringPane />
          <TableHeader />
          {data &&
            data.map((library: any, idx: any) => (
              <TableBody
                setLibraryList={setLibraryList}
                libraryList={libraryList}
                key={idx}
                data={library}
              />
            ))}
        </section>
      </div>
    </>
  )
}

export default DocumentLibrary
