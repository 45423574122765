import React from 'react'
import thumbnail from '../../../assets/card-thumbnail.svg' // Adjust the path as needed

const TopSpeakers: React.FC = () => {
  const speakers = [
    { id: 1, name: 'Jenny Kia' },
    { id: 2, name: 'John Doe' },
    { id: 3, name: 'Jane Smith' },
    // Add more speakers as needed
  ]

  return (
    <div className='mb-8'>
      <h2 className='text-xl sm:text-3xl font-bold mb-6 text-[#001A3D]'>
        Top speakers
      </h2>
      {speakers.map((speaker) => (
        <div key={speaker.id} className='flex items-center my-10'>
          <img
            src={thumbnail}
            alt={speaker.name}
            className='w-10 h-10 rounded-full mr-3'
          />
          <div className='flex flex-col'>
            <p className='font-semibold text-[#001A3D] mb-1'>{speaker.name}</p>
            <a
              href='#'
              className='text-blue-600 text-xs underline hover:text-blue-800'
            >
              View profile
            </a>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TopSpeakers
