import { Checkbox, TextField } from '@mui/material'
import React from 'react'

const PodcastNewsletter = () => {
  return (
    <div className='newsletter'>
      <h1>Join our newsletter</h1>
      <p className='my-5'>
        Some text here lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem
        ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem
        ipsum lorem ipsum lorem ipsum lorem ipsum.
      </p>
      <div className='form'>
        <TextField
          sx={{ width: { md: '400px', xs: '100%' } }}
          placeholder='Type what you are searching for...'
          name='search'
          variant='outlined'
        />
        <div className='flex items-center'>
          <Checkbox className='ps-0 ms-0' />
          <span>
            I agree to the <a href=''>Privacy policy</a> and{' '}
            <a href=''>Marketting policy</a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default PodcastNewsletter
