import React from 'react'
import emailLogo from '../assets/Social/email_logo.svg'
import twitterLogo from '../assets/Social/twitter_filled.svg'
import linkedinLogo from '../assets/Social/linkedin_filled.svg'

import twitterLogoDark from '../assets/Social/twitter_dark.svg'
import emailLogoDark from '../assets/Social/insta_dark.svg'
import linkedinLogoDark from '../assets/Social/linkedin_dark.svg'

type SiteFooterSocialLinksProps = {
  className: string
  isDark?: boolean
  variant?: 'default' | 'small'
}

export const SiteFooterSocialLinks: React.FC<SiteFooterSocialLinksProps> = ({
  className,
  isDark,
  variant = 'default',
}) => {
  const iconSize = variant === 'small' ? '24px' : '35px'

  return (
    <div className={className}>
      <a href='https://www.linkedin.com/company/legal-utopia'>
        <img
          src={isDark ? linkedinLogoDark : linkedinLogo}
          width={iconSize}
          height={iconSize}
          alt='LinkedIn logo'
        />
      </a>

      <a href='https://twitter.com/ailegalutopia'>
        <img
          src={isDark ? twitterLogoDark : twitterLogo}
          width={iconSize}
          height={iconSize}
          alt='Twitter logo'
        />
      </a>
      <a href='mailto:customerservice@legalutopia.co.uk'>
        <img
          src={isDark ? emailLogoDark : emailLogo}
          width={iconSize}
          height={iconSize}
          alt='Email icon'
        />
      </a>
    </div>
  )
}
