import {
  AiOutlineGlobal,
  AiFillInfoCircle,
  AiOutlineShareAlt,
} from 'react-icons/ai'

import {
  FaPhoneAlt,
  FaEnvelope,
  FaLinkedinIn,
  FaInfoCircle,
} from 'react-icons/fa'
import { Popup } from 'reactjs-popup'
import React, { ReactNode } from 'react'
import { FcGoogle } from 'react-icons/fc'
import { MdCopyAll } from 'react-icons/md'

import Img from '../../../components/base/Img'
import WebIcon from 'assets/FAL/WebIcon'
import InfoPopup, { MenuItem } from './InfoPopup'
import { MembershipType } from 'common/state-type'
import TrustPilotIcon from 'assets/FAL/trustpilot.png'
import { PopupPosition } from 'reactjs-popup/dist/types'

type ContactBtnsSectionProps = {
  phone: string
  email: string
  website: string
  themeColor: {
    hex: string
    class: string
  }
}

type AvatarImageProps = {
  imgSource?: string
  fullName?: string
  nameInitials?: string
}

type ProfileDetailProps = {
  name: string
  profession: string
  areas?: string[]
  subcriptionType: string
  organization: string
  experience: string
  operatingAreas: string[]
  themeColor: {
    hex: string
    class: string
  }
  bookingStatus?: React.ReactNode
}

type BookNowSectionProps = {
  btnLabel: string
  onClick: () => void
  title?: ReactNode
  themeColor: {
    hex: string
    class: string
  }
  popupTitle: string
  popupDescription: string
  titleClass?: string
}

type ContactPopupProps = {
  contactNumber?: string
  position?: PopupPosition | PopupPosition[]
  email?: string
  website?: string
  linkedInProfile?: string
  themeColor: {
    hex: string
    class: string
  }
}

type ExternalReviewLinkProps = {
  link: string
  label: string
  isTrustpilot?: boolean
  isGoogle?: boolean
}

type InfoItemsProps = {
  title: string
  content: any
  rightElement?: React.ReactNode
}

type SharePopupProps = {
  shareLink: string
  position?: PopupPosition | PopupPosition[]
}

export const ContactBtnsSection: React.FC<ContactBtnsSectionProps> = ({
  phone,
  email,
  website,
  themeColor,
}) => {
  return (
    <div className='flex flex-row my-2 self-center space-x-5 md:space-x-2'>
      <a href={'tel:' + phone}>
        <div
          className={`flex items-center justify-center h-7 w-7 rounded-full ${themeColor.class}`}
        >
          <FaPhoneAlt color='#fff' size={18} />
        </div>
      </a>
      <a href={'mailto:' + email}>
        <div
          className={`flex items-center justify-center h-7 w-7 rounded-full ${themeColor.class} `}
        >
          <FaEnvelope color='#fff' size={18} />
        </div>
      </a>
      <a href={website}>
        <div
          className={`flex items-center justify-center h-7 w-7 rounded-full ${themeColor.class}`}
        >
          <WebIcon height={18} width={18} />
        </div>
      </a>
    </div>
  )
}

export const AvatarImage: React.FC<AvatarImageProps> = ({
  imgSource,
  fullName,
  nameInitials,
}) => {
  return (
    <div
      className={`w-[70px] h-[70px] md:w-[6.77vw] md:h-[6.77vw] flex justify-center items-center rounded-full border-[#BAB9B9] border-2 text-[3.5vw] text-black`}
    >
      {!imgSource && nameInitials ? (
        nameInitials
      ) : !imgSource && fullName ? (
        Utils.getNameInitials(fullName)
      ) : (
        <Img className='rounded-full object-cover' imgSrc={imgSource} />
      )}
    </div>
  )
}

export const InfoItem: React.FC<InfoItemsProps> = ({
  title,
  content,
  rightElement,
}) => {
  return (
    <div className='text-black py-1.5 md:py-3 text-start'>
      <div className='flex flex-row space-x-1'>
        <h2 className='text-sm font-bold pb-1'> {title}</h2>
        {rightElement}
      </div>
      <p className='text-xs'>
        {typeof content === 'number'
          ? content
          : content
          ? content?.[0]?.toUpperCase() + content?.substr?.(1)
          : ''}
      </p>
    </div>
  )
}

export const ProfileDetail: React.FC<ProfileDetailProps> = ({
  name,
  profession,
  subcriptionType,
  organization,
  experience,
  areas,
  operatingAreas,
  themeColor,
  bookingStatus,
}) => {
  return (
    <div className='flex flex-col w-full mx-3'>
      <div className='flex flex-row place-content-between'>
        <div className='flex flex-row items-center'>
          <h3 className='font-bold text-black text-lg pr-2'>
            {' '}
            {Utils.capitalizeFirstLetter(name)}
          </h3>
          <p className='text-xl text-black'>{profession}</p>
        </div>
        <div>
          {subcriptionType !== MembershipType.NONE && (
            <div
              className={`text-sm px-2.5 items-center flex h-7 text-black rounded-lg font-bold ml-2 mr-[-12px] ${themeColor.class}`}
            >
              <span>{subcriptionType}</span>
            </div>
          )}
        </div>
      </div>
      <div className='my-6 flex flex-row'>
        <h3 className='font-bold text-black text-lg max-w-[260px] '>
          {Utils.capitalizeFirstLetter(organization)}
        </h3>
        {bookingStatus}
      </div>
      <span className='text-black text-lg mb-6 font-normal'>
        Experience : {experience}
      </span>
      {Boolean(operatingAreas) && (
        <>
          <span className='text-black text-lg font-normal'>
            Operating areas
          </span>
          <div className=' gap-2 py-3'>
            {operatingAreas.map((area: string, index: number) => {
              let textColor: string
              const isActive = areas?.includes(area)
              textColor = isActive ? 'text-white' : 'text-black'
              return (
                <span
                  key={area}
                  style={
                    isActive
                      ? { backgroundColor: themeColor.hex }
                      : { backgroundColor: '#F4F4F4' }
                  }
                  className={`px-2.5 py-1.5 items-center text-sm inline-block text-center whitespace-nowrap align-baseline ${textColor} rounded-lg mr-2 mb-1`}
                >
                  {area}
                </span>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export const BookNowSection: React.FC<BookNowSectionProps> = ({
  title,
  onClick,
  btnLabel,
  themeColor,
  popupTitle,
  popupDescription,
  titleClass,
}) => {
  return (
    <div className='flex flex-col justify-center '>
      {title && (
        <div className='flex flex-row justify-center'>
          <p className={`text-black pt-2 pr-1 ${titleClass}`}> {title}</p>
          <InfoPopup
            themeColor={themeColor}
            className='max-w-md'
            triggerrer={<AiFillInfoCircle color={themeColor.hex} size={16} />}
            title={popupTitle}
            description={popupDescription}
            position={
              Utils.getWindowDimensions().width > 768
                ? 'right bottom'
                : 'bottom right'
            }
          >
            <></>
          </InfoPopup>
        </div>
      )}

      <button
        onClick={onClick}
        className={`${themeColor.class} font-medium self-center rounded-lg text-white text-lg px-4 mt-1 py-2`}
      >
        {btnLabel}
      </button>
    </div>
  )
}

export const SharePopup: React.FC<SharePopupProps> = ({
  shareLink,
  position,
  ...rest
}) => {
  return (
    <Popup
      trigger={
        <button type='button' className='flex flex-col'>
          <MenuItem Icon={AiOutlineShareAlt} label='Share Now' />
        </button>
      }
      position={position ?? 'right bottom'}
      on='hover'
      closeOnDocumentClick
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      arrow
      {...rest}
    >
      <div className='bg-white py-8 px-4 rounded-lg border text-black border-gray-200'>
        <h5 className='block font-bold text-base mb-3'>Share Link</h5>

        <div className='flex flex-row items-center'>
          <p className='text-gray-500 block mr-2'>{shareLink}</p>
          <button onClick={() => navigator.clipboard.writeText(shareLink)}>
            <MdCopyAll size={20} />
          </button>
        </div>
      </div>
    </Popup>
  )
}

export const ContactPopup: React.FC<ContactPopupProps> = ({
  contactNumber,
  email,
  website,
  linkedInProfile,
  themeColor,
  position,
}) => {
  return (
    <InfoPopup
      themeColor={themeColor}
      triggerrer={<MenuItem Icon={FaInfoCircle} label='Contact Information' />}
      position={position ?? 'right bottom'}
      on='hover'
      closeOnDocumentClick
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      arrow
      MenuItems={[
        contactNumber && (
          <MenuItem
            key={'tel'}
            Icon={FaPhoneAlt}
            label='Land Line: '
            value={contactNumber}
            valuehref={'tel:' + contactNumber}
          />
        ),
        email && (
          <MenuItem
            key={'mail'}
            Icon={FaEnvelope}
            label='Email: '
            valuehref={'mailto:' + email}
            value={email}
          />
        ),
        website && (
          <MenuItem
            key={'web'}
            Icon={AiOutlineGlobal}
            label='Website: '
            valuehref={website}
            value={website}
          />
        ),
        linkedInProfile && (
          <MenuItem Icon={FaLinkedinIn} label='Linkedin: ' key='linkedin' />
        ),
      ]}
    >
      <></>
    </InfoPopup>
  )
}

export const ExternalReviewLink: React.FC<ExternalReviewLinkProps> = ({
  link,
  label,
  isGoogle,
  isTrustpilot,
}) => {
  return (
    <button
      className='flex flex-row place-items-center pb-3 md:pb-6'
      onClick={() => Utils.openInNewTab(link)}
    >
      {isGoogle && <FcGoogle size={30} />}
      {isTrustpilot && <img src={TrustPilotIcon} className='h-[30] w-[30]' />}
      <span className='text-sm text-black pl-2 text-left'>{label}</span>
    </button>
  )
}

interface ProfileCardBaseProps {
  membershipType: MembershipType
  className?: string
}

export const ProfileCardBase: React.FC<ProfileCardBaseProps> = ({
  membershipType,
  children,
  className,
  ...rest
}) => {
  const triangleStyle =
    membershipType === MembershipType.PREMIUM
      ? 'card-triangle-premium'
      : membershipType === MembershipType.STANDARD
      ? 'card-triangle-standard'
      : 'card-triangle-none'
  return (
    <div
      className={`bg-white flex flex-col shadow-lg md:ml-4 md:mr-5 rounded-2xl w-full ${className}`}
      {...rest}
    >
      <div className={`h-8 w-8 ${triangleStyle}`} />
      <div className='flex flex-row flex-1 px-5 md:px-6 pb-2 md:pb-6 '>
        {children}
      </div>
    </div>
  )
}

export default ProfileCardBase
