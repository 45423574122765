import React from 'react'

type TimeSlotProps = {
  isAvailable: boolean
  time: string
  handleAvalilablePress: (time: string) => void
  availailability: 'available' | 'unavailable' | 'selected' | 'disabled'
  handleConfirm: () => void
}

const TimeSlot: React.FC<TimeSlotProps> = ({
  time,
  handleAvalilablePress,
  availailability,
  handleConfirm,
}) => {
  const opacity = availailability === 'disabled' ? 'opacity-50' : 'opacity-100'

  const onAvailablePress = () => {
    handleAvalilablePress(time)
  }

  return (
    <div className={`${opacity}`}>
      <div className='h-[1px] w-full bg-lue-purple-400' />
      {availailability === 'selected' ? (
        <div className='flex flex-1 items-center justify-between h-11 bg-lue-purple-400 px-1 rounded-lg my-0.5'>
          <span className={`text-white text-sm`}>{time}</span>
          <div />
          <button
            onClick={handleConfirm}
            className={`font-medium self-center rounded-lg text-black text-lg py-0.5 px-8 bg-white`}
          >
            Confirm
          </button>
        </div>
      ) : (
        <div className='flex flex-1 items-center justify-between h-11'>
          <span
            className={`text-sm ${
              availailability === 'available'
                ? 'text-gray-400'
                : 'text-gray-200'
            }`}
          >
            {time}
          </span>
          {availailability === 'available' || availailability === 'disabled' ? (
            <button
              disabled={availailability === 'disabled'}
              onClick={onAvailablePress}
              className={`font-medium self-center rounded-lg text-white text-lg py-0.5 px-8 bg-lue-purple-400`}
            >
              Available
            </button>
          ) : null}
          <div />
        </div>
      )}
    </div>
  )
}

export default TimeSlot
