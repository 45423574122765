import React from 'react'
import { PageBanner } from '../../components/PageBanner'
import {
  PricingCard,
  PricingDetails,
  PricingHeader,
  PricingList,
} from './components/PricingCard'
import { data } from '../../data/PricingData'
import { useElements, useStripe } from '@stripe/react-stripe-js'

const Pricing = () => {
  const stripe = useStripe()
  const elements = useElements()

  const handleOnSubmit = async (pricing: any) => {
    if (!stripe || !elements) {
      return
    }

    const result = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: pricing.price_id,
          quantity: 1,
        },
      ],
      mode: pricing.mode,
      successUrl: `http://localhost:3000/success`,
      cancelUrl: `http://127.0.0.1:3000/pricing`,
    })

    if (result.error) {
      console.log(result.error.message)
    }
  }
  return (
    <div className='pricing'>
      <PageBanner>Pricing</PageBanner>
      <div className='container-fluid mt-20'>
        <div className='rows'>
          {data &&
            data.map((pricing, idx) => (
              <PricingCard
                key={idx}
                handleOnSubmit={() => handleOnSubmit(pricing)}
                stripe={stripe}
              >
                <PricingHeader>{pricing.name}</PricingHeader>
                <PricingDetails
                  price={`${pricing.currency[0].symbol}${pricing.amount}`}
                />
                {pricing.features.map((item, idx) => (
                  <PricingList
                    caption={pricing.caption}
                    bold={idx === 0 && true}
                    key={idx}
                    title={item}
                  />
                ))}
              </PricingCard>
            ))}
        </div>
      </div>
    </div>
  )
}

// hello

export default Pricing
