import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'

type useLegalPages = {
  legalPages?: AppState['legalPages']
  legalPagesLoading: AppState['legalPagesLoading']
  legalPagesError: AppState['legalPagesError']
  getLegalPages: (
    data: RequestTypes['getLegalPages'],
    callbacks?: Callbacks,
  ) => void
}

export default function useLegalPages(): useLegalPages {
  const { legalPages, legalPagesLoading, legalPagesError } =
    useSelector((state: AppState) => ({
      legalPages: state.legalPages,
      legalPagesLoading: state.legalPagesLoading,
      legalPagesError: state.legalPagesError,
    }))
  const dispatch = useDispatch()
  const getLegalPages = useCallback(
    (data: RequestTypes['getLegalPages'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getLegalPages(data, callbacks))
    },
    [dispatch],
  )
  return {
    legalPages,
    legalPagesLoading,
    legalPagesError,
    getLegalPages,
  }
}
