import React from 'react'
import './index.scss'
import { background } from 'assets/backgrounds'
import { icons } from '../../assets/icons'
import { LearnList } from './components/learn-list'
import { data } from 'data/learn-more.dummy'

const LearnMore = () => {
  return (
    <section id='learn-more'>
      <div className='hero position-relative'>
        <div
          className='vector'
          style={{ backgroundImage: `url(${background.LearnMore._01})` }}
        />
        <div className=' flex flex-column-sm justify-between items-center'>
          <div className='col-md-5'>
            <h1>We're here to help you in your legal needs</h1>

            <p>
              Find a Lawyer is a free service to help you find and select a
              lawyer or firm to help you with a legal need. It also provides the
              ability to schedule initial consultation appointments with your
              selected lawyer remotely.
            </p>
            <p>
              Here, we provide additional information on how you can use this
              service and how it works to assist your understanding on how to
              best use the service. This includes how we generate, rank, and
              display search results based on your use of the service.
            </p>
          </div>
          <div className='col-md-5'>
            <div className='card bg-primary'>
              <h3>Service information</h3>
              <ul>
                <li>
                  <img width={40} src={icons.CheckFill} alt='' /> How you search
                  for lawyers and firms
                </li>
                <li>
                  <img width={40} src={icons.CheckFill} alt='' /> How we
                  generate search results
                </li>
                <li>
                  <img width={40} src={icons.CheckFill} alt='' />
                  How we rank and display profiles{' '}
                </li>
                <li>
                  <img width={40} src={icons.CheckFill} alt='' />
                  How Advance Search works{' '}
                </li>
                <li>
                  <img width={40} src={icons.CheckFill} alt='' />
                  How you can make a booking{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className='vector d-none position-absolute'
          style={{ backgroundImage: `url(${background.LearnMore._02})` }}
        />
      </div>

      <div className='learn-content'>
        {data.map((item, idx) => (
          <LearnList data={item} key={idx} />
        ))}
      </div>
    </section>
  )
}

export default LearnMore
