export const data = [
  [
    {
      name: 'Top UK Cities',
      subs: [
        [
          {
            name: 'Aberdeen',
            url: '',
          },
          {
            name: 'Armagh',
            url: '',
          },
          {
            name: 'Bangor',
            url: '',
          },
          {
            name: 'Bath',
            url: '',
          },
          {
            name: 'Belfast',
            url: '',
          },
        ],
        [
          {
            name: 'Birmingham',
            url: '',
          },
          {
            name: 'Bradford',
            url: '',
          },
          {
            name: 'Brighton and Hove',
            url: '',
          },
          {
            name: 'Bristol',
            url: '',
          },
          {
            name: 'Cambridge',
            url: '',
          },
        ],
        [
          {
            name: 'Canterbury',
            url: '',
          },
          {
            name: 'Cardiff',
            url: '',
          },
          {
            name: 'Carlisle',
            url: '',
          },
          {
            name: 'Chelmsford',
            url: '',
          },
          {
            name: 'Chester',
            url: '',
          },
        ],
        [
          {
            name: 'Chichester',
            url: '',
          },
          {
            name: 'Colchester',
            url: '',
          },
          {
            name: 'Coventry',
            url: '',
          },
          {
            name: 'Delby',
            url: '',
          },
          {
            name: 'Doncaster',
            url: '',
          },
        ],
        [
          {
            name: 'Dundee',
            url: '',
          },
          {
            name: 'Dunfermline',
            url: '',
          },
          {
            name: 'Durham',
            url: '',
          },
          {
            name: 'Edinburgh',
            url: '',
          },
          {
            name: 'Ely',
            url: '',
          },
        ],
        [
          {
            name: 'Exeter',
            url: '',
          },
          {
            name: 'Glasgow',
            url: '',
          },
          {
            name: 'Gloucester',
            url: '',
          },
          {
            name: 'Hereford',
            url: '',
          },
          {
            name: 'London',
            url: '',
          },
        ],
      ],
    },
    {
      name: 'Top Lawyers',
      subs: [],
    },
    {
      name: 'Top LegalDocs',
      subs: [],
    },
    {
      name: 'Law Areas',
      subs: [
        [
          {
            name: 'Shipping',
            url: '',
          },
          {
            name: 'Land',
            url: '',
          },
          {
            name: 'Property',
            url: '',
          },
          {
            name: 'Housing',
            url: '',
          },
          {
            name: 'Intellectual Property',
            url: '',
          },
        ],
        [
          {
            name: 'Environment',
            url: '',
          },
          {
            name: 'Consumer',
            url: '',
          },
          {
            name: 'Employment',
            url: '',
          },
          {
            name: 'Tax',
            url: '',
          },
          {
            name: 'Human Rights',
            url: '',
          },
        ],
        [
          {
            name: 'Personal Injury',
            url: '',
          },
          {
            name: 'Wills and Probate',
            url: '',
          },
          {
            name: 'Family',
            url: '',
          },
          {
            name: 'Criminal',
            url: '',
          },
          {
            name: 'Data Protection',
            url: '',
          },
        ],
        [
          {
            name: 'Privacy',
            url: '',
          },
          {
            name: 'Retail',
            url: '',
          },
          {
            name: 'Energy & Natural Resources',
            url: '',
          },
          {
            name: 'Insurance',
            url: '',
          },
          {
            name: 'Estate Administration',
            url: '',
          },
        ],
        [
          {
            name: 'Construction',
            url: '',
          },
          {
            name: 'Commercial',
            url: '',
          },
          {
            name: 'Immigration',
            url: '',
          },
          {
            name: 'Dispute Resolution',
            url: '',
          },
          {
            name: 'Agriculture',
            url: '',
          },
        ],
        [
          {
            name: 'Social Welfare',
            url: '',
          },
          {
            name: 'Corporate',
            url: '',
          },
          {
            name: 'Conveyancing',
            url: '',
          },
          {
            name: 'Debt',
            url: '',
          },
          {
            name: 'Transport',
            url: '',
          },
        ],
      ],
    },
    {
      name: 'Law Firms',
      subs: [],
    },
    {
      name: 'Chambers',
      subs: [
        [
          {
            name: 'Cerulean Chambers',
            url: '',
          },
          {
            name: 'Chambers Of Ayoub Khan',
            url: '',
          },
          {
            name: 'Charles Morgan Law Ltd',
            url: '',
          },
          {
            name: 'CGSW Limited',
            url: '',
          },
          {
            name: 'CityGate Chambers Limited',
            url: '',
          },
        ],
        [
          {
            name: 'Climate Law Ltd',
            url: '',
          },
          {
            name: 'Contract Law Chambers',
            url: '',
          },
          {
            name: 'Craig Hassall Ltd',
            url: '',
          },
          {
            name: 'D Sandiford Limited',
            url: '',
          },
          {
            name: 'David Mold Limited',
            url: '',
          },
        ],
        [
          {
            name: 'DBF Law Limited',
            url: '',
          },
          {
            name: 'DBM Law Limited',
            url: '',
          },
          {
            name: 'Denise Breen-Lawton Limited',
            url: '',
          },
          {
            name: 'DL Legal Limited',
            url: '',
          },
          {
            name: 'D Hayes Public Law Practice',
            url: '',
          },
        ],
        [
          {
            name: 'Duncan Maxwell-Stewart Ltd',
            url: '',
          },
          {
            name: 'E&A Law Limited',
            url: '',
          },
          {
            name: 'EAG Legal Limited',
            url: '',
          },
          {
            name: 'Edward James Palmer Limited',
            url: '',
          },
          {
            name: 'Elderflower Solutions Limited',
            url: '',
          },
        ],
        [
          {
            name: 'Elizabeth Nicholls Ltd',
            url: '',
          },
          {
            name: 'Elysium Law Limited',
            url: '',
          },
          {
            name: 'Fiaz Siddique Limitedd',
            url: '',
          },
          {
            name: 'Five Pillars Law Limited',
            url: '',
          },
          {
            name: 'Fortis Legal Limited',
            url: '',
          },
        ],
        [
          {
            name: 'Frank Feehan KC Ltd',
            url: '',
          },
          {
            name: 'Frantz Gregory & Co Limited',
            url: '',
          },
          {
            name: 'Fredericks Shaw Ltd',
            url: '',
          },
          {
            name: 'Gaskell Law Limited',
            url: '',
          },
          {
            name: 'Geoffrey Kelly Limited',
            url: '',
          },
        ],
      ],
    },
    {
      name: 'Barristers',
      subs: [],
    },
    {
      name: 'Solicitors',
      subs: [],
    },
  ],
  [
    {
      name: 'Legal Help',
      subs: [
        [
          {
            name: 'For business & individuals',
            url: '',
          },
          {
            name: 'Find a Lawyer',
            url: '/dashboard',
          },
          {
            name: 'Create a LegalDoc',
            url: 'legal-documents',
          },
          {
            name: 'Diagnose My Issue',
            url: '',
          },
          {
            name: 'Answer My Question',
            url: '',
          },
          {
            name: 'Pricing',
            url: '/pricing',
          },
        ],
        [
          {
            name: 'For lawyers',
            url: '/dashboard',
          },
          {
            name: 'Upskill My Practise',
            url: 'http://upskillmypractice.co.uk/',
          },
          {
            name: 'Find a Lawyer',
            url: 'http://lawyers.legalutopia.co.uk/',
          },
          {
            name: 'Quality Indicators',
            url: 'http://qualityindicators.co.uk/',
          },
          {
            name: 'Data Standards',
            url: 'http://legaldatastandards.co.uk/',
          },
          {
            name: 'Pricing',
            url: '',
          },
        ],
      ],
    },
    {
      name: 'Resources',
      subs: [
        [
          {
            name: 'Discover Law',
            url: '/discover-law',
          },
          {
            name: 'Quality Indicators',
            url: 'http://qualityindicators.co.uk/',
          },
          {
            name: 'Document Dictionary',
            url: '',
          },
          {
            name: 'Legal Term Glossary',
            url: '',
          },
          {
            name: 'Law Finder',
            url: '',
          },
        ],
      ],
    },
    {
      name: 'Legal Utopia',
      subs: [
        [
          {
            name: 'Who we are',
            url: '/whoweare',
          },
          {
            name: 'Contact us',
            url: '/contact-us',
          },
          {
            name: 'Weekly email',
            url: '',
            modal: true,
          },
          {
            name: 'Blog',
            url: '/articles',
          },
          {
            name: 'Podcast',
            url: '/podcasts',
          },
          {
            name: 'Sitemap',
            url: '',
          },
        ],
      ],
    },
    {
      name: 'Legal Help',
      subs: [
        [
          {
            name: 'Cookies (site) Policy',
            url: '/legal/cookies-website-policy',
          },
          {
            name: 'Privacy Policy',
            url: '/legal/employee-privacy-policy',
          },
          {
            name: 'Terms of Use',
            url: '/legal/terms-of-use-business',
          },
          {
            name: 'More',
            url: '/legal',
          },
        ],
      ],
    },
  ],
]
