import React from 'react'
import { PostCard } from 'components/cards/PostCard'
import { articles } from 'data/ArticlesData'
import { background } from 'assets/backgrounds'

const MediaSection = () => {
  return (
    <>
      <div className=''>
        <img
          src={background.discoverLaw._02}
          className='absolute left-0 top-0 z-[-99999]'
          alt=''
        />
        <h1 className='md:text-[40px] font-bold text-[#101828] '>
          Latest media
        </h1>
        <div>
          <div>
            <p className='mb-5'>Articles and blogs</p>

            <div className='grid lg:grid-cols-4 md:grid-cols-2 justify-evenly md:gap-5'>
              {articles.slice(0, 4).map((blog, idx) => (
                <div className='mb-10'>
                  <PostCard data={blog} count={idx} key={idx} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MediaSection
