import React from 'react'
import Avatar02 from 'assets/avatar-3.jpeg'

type Props = {
  data: any
}

const SpeakerList = ({ data }: Props) => {
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='details'>
          <div
            className='img'
            style={{
              background: `url(${Avatar02}) lightgray 50% / cover no-repeat`,
            }}
          />
          <h4>{data.name}</h4>
        </div>
      </div>
    </div>
  )
}

export default SpeakerList
