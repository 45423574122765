import React from 'react'
import { background } from 'assets/backgrounds'
import { socials } from 'assets/socials'
import Image from 'components/placeholders/Image'
import { Button } from 'components/forms/Buttons'
import './index.css'
import VideoPlayer from 'components/Multimedia/VideoPlayer'
import { useVideoPlayer } from 'hooks/useVideoPlayer'
import MediaSection from './components/media-section'
import InterviewSection from './components/interview-section'
import PodcastSection from './components/podcast-section'
import OurApproach from './components/our-approach'
import PublicProfileCard from 'components/cards/PublicProfileCard'
import Slider from 'react-slick'

const LegalField = () => {
  const { videoRef } = useVideoPlayer()

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '10px',
        },
      },
    ],
  }

  return (
    <div id='legal-fields' className=''>
      <section
        className={`h-[580px] mt-[-20px] w-full bg-no-repeat`}
        style={{
          background: `url(${background.LegalField.Hero})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'auto',
        }}
      >
        <div className='md:flex items-center justify-between'>
          <div>
            <h1 className='md:text-[64px] text-[24px] text-[#122D5C] font-bold'>
              Legal field title
            </h1>
            <p className='font-bold md:text-[18px] text-[#122D5C] md:mt-[30px] md:mb-[60px] my-[15px]'>
              Dive into this legal field and discover its many specialised
              branches.
            </p>
            <div className='flex md:gap-5 gap-3 mb-5'>
              <img
                src={socials.dark.linkedin}
                className='h-[24px] w-[24px]'
                alt=''
              />
              <img
                src={socials.dark.twitter}
                className='h-[24px] w-[24px]'
                alt=''
              />
              <img
                src={socials.dark.mail}
                className='h-[24px] w-[24px]'
                alt=''
              />
            </div>
          </div>
          <div className='md:h-[260px] h-[200px] md:w-[480px]'>
            <Image
              background='#101828'
              width='100%'
              height='100%'
              color='#fff'
              display='flex'
            >
              Image
            </Image>
          </div>
        </div>
      </section>

      <section className=''>
        <div className='flex flex-wrap justify-center md:justify-end gap-4'>
          {Array(4)
            .fill('Legal sub-field')
            .map((data, idx) => (
              <Button
                key={idx}
                className='btn-default font-bold text-[#1C7D93]'
              >
                {data + ' ' + ++idx}
              </Button>
            ))}
        </div>
      </section>

      <section className='relative'>
        <div className='h-full w-[90%] max-sm:w-[93%] max-sm:left-[10px] absolute top-0  backdrop-blur-[100px] bg-white/[.4] z-[-1]'></div>
        <div className='md:py-[40px] max-sm:p-[0px]  md:px-[70px] md:text-[18px] font-bold text-[#001A3D] '>
          <MediaSection />

          <InterviewSection />

          <VideoPlayer
            ref={videoRef}
            src='https://www.youtube.com/embed/dHqg2urvK1s?enablejsapi=1'
            isYouTube
            title='Interview with John Doe'
            interviewee='John Doe'
          />

          <PodcastSection />
        </div>
      </section>
      <OurApproach />

      <section className='md:!p-[80px] shadow-[0_2px_70px_0px_rgba(217,217,217,0.7)]'>
        <h1 className='md:text-[40px] text-[18px] font-bold'>
          Get advice today
        </h1>
        <p className='md:text-[18px] text-[14px] text-[#818285] pt-[30px] pb-[80px]'>
          Connect with experienced lawyers now and get the expert advice you
          need.
        </p>
        <Slider className='' {...settings}>
          {[
            { type: 'barrister' },
            { type: 'lawfirm' },
            { type: 'barrister' },
            { type: 'lawfirm' },
            { type: 'barrister' },
          ].map((data, idx) => (
            <PublicProfileCard data={data} />
          ))}
        </Slider>
      </section>

      <section className='my-[100px] font-bold text-[18px]'>
        <div className='flex justify-center text-center '>
          <div className='space-y-'>
            <p>seeking legal help?</p>
            <p> Find an expert lawyer today.</p>
            <Button className='btn-primary mt-5'>Find</Button>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LegalField
