import React from 'react'
import { background } from '../../../assets/backgrounds'
import Image from 'components/placeholders/Image'

const DiscoverLegalPerspective = () => {
  return (
    <>
      <section className='container relative'>
        <div className='flex flex-column'>
          <img
            src={background.discoverLaw._02}
            alt=''
            className='absolute left-0 md:top-[200px] bottom-[200px] md:w-full z-[-999]'
          />
          <div className='col-md-12'>
            <h1 className='my-10 md:text-[40px] text-[18px] font-bold'>
              You might want to discover the legal perspective
            </h1>
          </div>
          <div className='flex flex-column-sm justify-between items-center'>
            <div className='col-md-5'>
              <p className='text-[14px] md:text-[24px] md:my-20'>
                Explore our expert-authored blogs and articles by top legal
                professionals to gain valuable insights into the legal services
              </p>
              <p className='mb-10'>
                Unlock a wealth of legal knowledge by diving into our exclusive
                collection of blogs and articles, authored by seasoned
                attorneys. Whether you're seeking insights on the latest legal
                trends or guidance on complex issues, our platform offers you
                direct access to the expertise of top legal professionals. Start
                exploring now to stay informed and make well-informed decisions
                for your legal needs.
              </p>
            </div>
            <div className='col-md-5 relative'>
              <Image
                height='293px'
                display='flex'
                color='#fff'
                background='#101828'
                width='100%'
              >
                Image
              </Image>
              <p className='mt-4 mb-4'>
                <strong>
                  Changes to commercial property evictions feat ABC Law
                </strong>
              </p>
              <p className='text-muted'>25th July, 2024</p>
            </div>
          </div>
        </div>
        <img
          src={background.discoverLaw._01}
          style={{ zIndex: -1 }}
          className='absolute md:h-[1475px] w-full md:w-[1569px] right-[50px] md:top-[240px]'
          alt=''
        />
      </section>
    </>
  )
}

export default DiscoverLegalPerspective
