import React, { useState } from 'react'
import { brands } from '../../../assets/brands'
import { Avatars } from '../../../assets/avatars'
import { flags } from '../../../assets/flags'
import { BsInfoCircle, BsX, BsXLg } from 'react-icons/bs'
import { navigations } from '../../../data/HeaderData'
import { Avatar } from '../../../common/components/Avatar'
import { MobileMenuHeader } from './MobileMenu'
import { AiOutlineMenu } from 'react-icons/ai'
import { Link } from 'react-router-dom'

const Header = () => {
  const [toggle, setToggle] = useState<boolean>(false)

  return (
    <header>
      <nav className='navbar px-5'>
        <Link className='navbar-brand' to='/'>
          <div
            style={{
              background: `url(${brands.logo})  -78.928px -49.983px / 494.64% 348.409% no-repeat`,
            }}
          />
          <span>Legal Utopia</span>
        </Link>

        <div className='navbar-links'>
          <ul className='navbar- flex'>
            {navigations &&
              navigations.map((outer, idx) => (
                <li className='nav-item active'>
                  <Link
                    to={outer.url}
                    className='nav-link text-[#586885] cursor-pointer'
                  >
                    {outer.name} <span className='sr-only'>(current)</span>
                  </Link>
                  <ul
                    className={`has-child ${outer.subs ? '' : 'd-none'} `}
                    aria-labelledby='navbarDropdown'
                  >
                    {outer.subs &&
                      outer.subs.map((nested, idx) => (
                        <>
                          <div className=''>
                            {nested &&
                              nested.map((inner, idx) => (
                                <li className='dropdown-item mb-3'>
                                  <Link to=''>{inner.name}</Link>
                                  {inner.subs && (
                                    <ul className='has-child w-full'>
                                      {inner.subs.map((nested, idx) => (
                                        <div className='w-full' key={idx}>
                                          {nested.map((link) => (
                                            <li className='dropdown-item'>
                                              <Link to=''>{link.name}</Link>
                                            </li>
                                          ))}
                                        </div>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              ))}
                          </div>
                        </>
                      ))}
                  </ul>
                </li>
              ))}
          </ul>
        </div>

        {toggle && (
          <div className={`mobile-nav ${!toggle && 'd-none'}`}>
            <div className='flex items-center justify-between py-3'>
              <div className='flex items-center gap-5'>
                <Avatar imgSource={Avatars._01} fullName='Users name' />
                User's name
              </div>
              <BsXLg onClick={() => setToggle(!toggle)} />
            </div>
            <hr className='my-2' />
            <ul className=''>
              <div className='flex flex-column'>
                <li>
                  <Link to=''>Profile</Link>
                </li>
                <li>
                  <Link to=''>Appointments</Link>
                </li>
                <li>
                  <Link to=''>Documents Library</Link>
                </li>
                <li>
                  <Link to=''>Favourites</Link>
                </li>
                <li>
                  <Link to=''>Purchases</Link>
                </li>
              </div>
              <hr className='my-2' />
              <MobileMenuHeader navigations={navigations} />
              <hr className='my-2' />
              <li className=''>
                <Link to=''>Sign in</Link>
              </li>
            </ul>
          </div>
        )}

        <div className='navbar-action flex flex-wrap items-center gap-8 '>
          <Link to='mailto:customerservice@legalutopia.co.uk?subject=Customer%20Support'>
            <BsInfoCircle className='inline-block mr-1' /> Customer support
          </Link>
          <img src={flags.UK} alt='' />
          <Link to='/login' className=''>
            Login
          </Link>
          <img className='avatar' src={Avatars._Dummy} alt='' />
          <button className='toggler' onClick={() => setToggle(!toggle)}>
            {toggle ? <BsX /> : <AiOutlineMenu size={25} />}
          </button>
        </div>
      </nav>
    </header>
  )
}

export default Header
