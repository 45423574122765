import { Collapse, List, ListItemButton, ListItemText } from '@mui/material'
import { useState } from 'react'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'

export const MobileMenuHeader = ({ navigations }: any) => {
  const [open, setOpen] = useState<any>(false)
  const handleClick = (index: any) => {
    open[index] ? setOpen({ [index]: false }) : setOpen({ [index]: true })
  }
  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
      }}
      component='nav'
      aria-labelledby='nested-list-subheader'
    >
      {navigations &&
        navigations.map((outer, idx) => (
          <>
            {console.log(outer)}
            {!outer.subs ? (
              <>
                <ListItemButton className='py-1 my-1'>
                  <ListItemText className='py-1 my-1' primary={outer.name} />
                </ListItemButton>
              </>
            ) : (
              <>
                <ListItemButton onClick={() => handleClick(idx)}>
                  <ListItemText primary={outer.name} />
                  {open[idx] ? (
                    <AiFillCaretUp color='#37A2BA' />
                  ) : (
                    <AiFillCaretDown color='#37A2BA' />
                  )}
                </ListItemButton>
                <Collapse in={open[idx]} timeout={'auto'} unmountOnExit>
                  {outer.subs && (
                    <List component='div' disablePadding>
                      {outer.subs.flatMap((nested, idx) => (
                        <>
                          {nested.map((innerNested) => (
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText primary={innerNested.name} />
                            </ListItemButton>
                          ))}
                        </>
                      ))}
                    </List>
                  )}
                </Collapse>
              </>
            )}
          </>
        ))}
    </List>
  )
}

export const MobileMenuFooter = ({ navigations }: any) => {
  const [open, setOpen] = useState<any>(false)
  const handleClick = (index: any) => {
    open[index] ? setOpen({ [index]: false }) : setOpen({ [index]: true })
  }
  return (
    <List
      className='footer-mobile-menu'
      sx={{
        width: '100%',
      }}
      component='nav'
      aria-labelledby='nested-list-subheader'
    >
      {navigations &&
        navigations.toReversed().map((outer, outer_idx) => (
          <>
            {outer.flatMap((outer, idx) => (
              <>
                {!outer.subs || outer.subs.length === 0 ? (
                  <>
                    <ListItemButton className='py-1 my-1'>
                      <ListItemText
                        className='py-1 my-1'
                        primary={outer.name}
                      />
                    </ListItemButton>
                  </>
                ) : (
                  <>
                    <ListItemButton onClick={() => handleClick(idx)}>
                      <ListItemText primary={outer.name} />
                      {open[idx] ? (
                        <AiFillCaretUp color='#37A2BA' />
                      ) : (
                        <AiFillCaretDown color='#37A2BA' />
                      )}
                    </ListItemButton>
                    <Collapse in={open[idx]} timeout={'auto'} unmountOnExit>
                      {outer.subs && (
                        <List component='div' disablePadding>
                          {outer.subs.flatMap((nested, idx) => (
                            <>
                              {nested.map((innerNested) => (
                                <ListItemButton sx={{ pl: 4 }}>
                                  <ListItemText primary={innerNested.name} />
                                </ListItemButton>
                              ))}
                            </>
                          ))}
                        </List>
                      )}
                    </Collapse>
                  </>
                )}
              </>
            ))}
            <div className='line my-10'></div>
          </>
        ))}
    </List>
  )
}
