export const brands = {
  logo: require('./logo.png'),
  department_of_works: require('./department-of-work-and-pension.png'),
  europian_union: require('./europian-union.png'),
  university_edinburgh: require('./university-edinburgh.png'),
  kent_county_council: require('./kent-country-council.png'),
  innovate_uk: require('./innovate-uk.png'),
  barclays: require('./barclays.png'),
  santander: require('./santander.png'),
  lawtech_panel: require('./lawtech-panel.png'),
  london: require('./london.png'),
  wayra: require('./wayra.png'),
}
