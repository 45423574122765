import { Avatar, Badge } from '../../components/Utils'

type PostProps = {
  data: any
  count?: any
}

export const PostCard = ({ data, count }: PostProps) => {
  console.log(count)
  return (
    <>
      <div
        className={`card ${count % 2 && 'flex flex-reverse'}`}
        style={{ background: '#fff' }}
      >
        <img
          style={{
            height: '191px',
            width: '100%',
            background: `url(${data.cover}) lightgray 50% / cover no-repeat`,
          }}
        />
        <div className='card-body'>
          <h5>{data.title}</h5>
          <p>{data.description}</p>
          <div className='flex gap-2 my-1'>
            {data.tags &&
              data.tags.map((tag, idx) => (
                <Badge key={idx} title={tag} color='primary' />
              ))}
          </div>
          <hr />
          <div className=' badge badge-primary mt-3'>
            <Avatar
              className={'pr-2'}
              picture={data.author[0].avatar}
              caption={data.author[0].role}
              name={data.author[0].name}
            />
          </div>
        </div>
      </div>
    </>
  )
}
