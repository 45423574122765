import React from 'react'

type Props = {
  count: number
  data: any
  length: any
}

export const Step = ({ count, data, length }: Props) => {
  return (
    <div className='step-item'>
      <div className='flex flex-column'>
        <div className='count'>
          {++count}
          {length !== count && (
            <svg
              className={`step-arrow arr-${count}`}
              xmlns='http://www.w3.org/2000/svg'
              width='220'
              height='16'
              viewBox='0 0 262 16'
              fill='none'
            >
              <path
                d='M261.41 8.70711C261.801 8.31658 261.801 7.68342 261.41 7.29289L255.046 0.928932C254.656 0.538408 254.023 0.538408 253.632 0.928932C253.242 1.31946 253.242 1.95262 253.632 2.34315L259.289 8L253.632 13.6569C253.242 14.0474 253.242 14.6805 253.632 15.0711C254.023 15.4616 254.656 15.4616 255.046 15.0711L261.41 8.70711ZM0.703125 9H260.703V7H0.703125V9Z'
                fill='#9FCDD7'
              />
            </svg>
          )}
        </div>
        <h1 className='fw-bold'>{data.title}</h1>
        <p className='text-muted'>{data.caption}</p>
      </div>
    </div>
  )
}
