import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { documents } from 'data/DocumentLibraryData'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'

const defaultFilters = {
  search: '',
  type: '',
  date_from: '2025/1/31',
  date_to: '',
}

const FilteringPane = () => {
  const [state, setState] = useState(1)
  const [filters, setFilters] = useState(defaultFilters)
  const [value, setValue] = React.useState<Dayjs | null>(dayjs('2022-04-17'))

  const handleFilterTab = (selected: any) => {
    setState(selected)
  }

  const handleInputOnChange = (e: any) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return (
    <section className='filter'>
      <div className='col'>
        <div className='filter-tab'>
          <button
            onClick={() => handleFilterTab(1)}
            className={`${state === 1 && 'active'}`}
          >
            View all
          </button>
          <button
            onClick={() => handleFilterTab(2)}
            className={`${state === 2 && 'active'}`}
          >
            Your files
          </button>
          <button
            onClick={() => handleFilterTab(3)}
            className={`${state === 3 && 'active'}`}
          >
            Shared files
          </button>
        </div>
      </div>
      <div className='col'>
        <div className='filter-search'>
          <TextField
            size='small'
            sx={{ width: { md: '320px', xs: '100%' } }}
            variant='outlined'
            label='Search'
            name='search'
            className='form-control'
            value={filters.search}
            onChange={handleInputOnChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <BsSearch fill='#000' fontWeight={900} />
                </InputAdornment>
              ),
            }}
          />

          <FormControl
            sx={{ width: { md: '196px' } }}
            className='form-control'
            size='small'
          >
            <InputLabel id='demo-simple-select-label'>Documents</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              name='type'
              id='demo-simple-select'
              value={filters.type}
              label='Documents'
              onChange={handleInputOnChange}
            >
              {documents.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className='flex gap-3 items-center'>
            <DatePicker
              className='form-control'
              sx={{ width: { md: '122px' } }}
              label='From'
              name='date_from'
              onChange={handleInputOnChange}
              disableOpenPicker
              slotProps={{ textField: { size: 'small' } }}
            />
            <div>-</div>
            <DatePicker
              className='form-control'
              sx={{ width: { md: '122px' } }}
              name='date_to'
              label='To'
              onChange={handleInputOnChange}
              disableOpenPicker
              slotProps={{ textField: { size: 'small' } }}
            />
          </div>
        </div>
        <div className='mt-5 flex justify-end gap-5 action'>
          <button>Apply filters</button>
          <button>Clear</button>
        </div>
      </div>
    </section>
  )
}

export default FilteringPane
