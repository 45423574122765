import React, { useState } from 'react'

const Heart = ({ fill }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill={fill}
  >
    <g filter='url(#filter0_d_13518_120239)'>
      <path
        d='M23 8.8125C23 15.375 13.2697 20.6869 12.8553 20.9062C12.7461 20.965 12.624 20.9958 12.5 20.9958C12.376 20.9958 12.2539 20.965 12.1447 20.9062C11.7303 20.6869 2 15.375 2 8.8125C2.00174 7.27146 2.61468 5.79404 3.70436 4.70436C4.79404 3.61468 6.27146 3.00174 7.8125 3C9.74844 3 11.4434 3.8325 12.5 5.23969C13.5566 3.8325 15.2516 3 17.1875 3C18.7285 3.00174 20.206 3.61468 21.2956 4.70436C22.3853 5.79404 22.9983 7.27146 23 8.8125Z'
        fill='#F4F4F4'
      />
      <path
        d='M12.6214 20.4643L12.6184 20.4659C12.582 20.4855 12.5413 20.4958 12.5 20.4958C12.4587 20.4958 12.418 20.4855 12.3816 20.4659L12.3786 20.4644C12.1884 20.3636 9.68979 19.0017 7.24637 16.8619C4.77664 14.699 2.50014 11.86 2.5 8.81277C2.50166 7.40425 3.06193 6.05389 4.05791 5.05791C5.05389 4.06193 6.40425 3.50166 7.81277 3.5C9.60248 3.50008 11.1446 4.26723 12.1002 5.5399L12.5 6.07243L12.8998 5.5399C13.8554 4.26723 15.3975 3.50008 17.1872 3.5C18.5957 3.50166 19.9461 4.06193 20.9421 5.05791C21.9381 6.05396 22.4984 7.40444 22.5 8.81306C22.4997 11.8601 20.2233 14.6991 17.7536 16.8619C15.3102 19.0017 12.8116 20.3636 12.6214 20.4643Z'
        stroke='#BBC7D7'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_13518_120239'
        x='0'
        y='2'
        width='25'
        height='21.9961'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='1' />
        <feGaussianBlur stdDeviation='1' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_13518_120239'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_13518_120239'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

export const HeartIcon = ({ isLiked }) => {
  const [liked, setLiked] = useState<boolean>(isLiked)
  return (
    <button
      className='flex items-center gap-2 text-sm'
      onClick={() => setLiked(!liked)}
    >
      <Heart fill={liked ? '#EF4444' : 'none'} />
      <span className='text-sm'>20 plays</span>
    </button>
  )
}
