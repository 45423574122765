import Project from '../../common/project'
import { FirebaseApp, initializeApp } from 'firebase/app'
let app: FirebaseApp
const firebaseConfig = {
  apiKey: 'AIzaSyDomcLY-xoazL9KnRBNN9b_oROG_SMO9m8',
  authDomain: 'legal-utopia-production-app.firebaseapp.com',
  projectId: 'legal-utopia-production-app',
  storageBucket: 'legal-utopia-production-app.appspot.com',
  messagingSenderId: '285776613871',
  appId: '1:285776613871:web:0d93b7cda5291c434b261a',
  measurementId: 'G-T6PJTSJEDR',
}
app = initializeApp(firebaseConfig)
export default app
