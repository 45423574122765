import { InputHTMLAttributes, TextareaHTMLAttributes } from 'react'
import cn from 'classnames'

type InputProps = (
  | InputHTMLAttributes<HTMLInputElement>
  | TextareaHTMLAttributes<HTMLTextAreaElement>
) & {
  isLarge?: boolean
  isDropdown?: boolean
  isNew?: boolean
  backgroundColor?: string
  multiLine?: number
}

const getCursorClassname = (disabled?: boolean, isDropdown?: boolean) => {
  if (disabled) {
    return 'cursor-not-allowed'
  }

  if (isDropdown) {
    return 'cursor-pointer'
  }

  return 'cursor-text'
}

export const Input = ({
  className,
  isLarge,
  disabled,
  isDropdown,
  isNew,
  backgroundColor,
  multiLine,
  ...props
}: InputProps) => {
  const sharedClassNames = cn(
    'w-full outline-none',
    'bg-transparent',
    isNew
      ? 'text-sm font-normal leading-5 tracking-[0.4px] placeholder:text-[#818285]'
      : 'py-2 text-lg text-b33 font-normal placeholder:text-b33',
    getCursorClassname(disabled, isDropdown),
    isLarge ? 'lg:py-6 px-5' : 'px-4',
    'drop-shadow-[0_0.5px_2px_rgba(0,0,0,0.25)]',
     'transition-[background-color] duration-[0ms]',
    className,
  )

  if (multiLine && multiLine > 1) {
    return (
      <textarea
        className={sharedClassNames}
        disabled={disabled}
        rows={multiLine}
        {...(props as TextareaHTMLAttributes<HTMLTextAreaElement>)}
      />
    )
  }

  return (
    <input
      className={sharedClassNames}
      disabled={disabled}
      {...(props as InputHTMLAttributes<HTMLInputElement>)}
    />
  )
}
