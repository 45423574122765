import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { filters } from 'data/PodcastData'

type Props = {
  setFilterData: Function
  data: any
}

const defaultState = {
  search: '',
  filter_by: '',
}

const PodcastFilter = ({ setFilterData, data }: Props) => {
  const oldData = data
  const [state, setState] = useState<any>(defaultState)

  const handleOnChange = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  useEffect(() => {
    let result = data.filter(
      (object: any) =>
        object.title
          .toString()
          .toLowerCase()
          .indexOf(state.search.toString().toLowerCase()) > -1,
    )
    setFilterData(result)
  }, [state, setFilterData])

  const handleReset = () => {
    setFilterData(oldData)
    setState(defaultState)
  }

  return (
    <div className='filters'>
      <TextField
        onChange={handleOnChange}
        value={state?.search}
        sx={{ width: { md: '600px', xs: '100%' } }}
        variant='outlined'
        label='Type what you are searching for...'
        name='search'
        className='form-control'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <BsSearch fill='#000' fontWeight={900} />
            </InputAdornment>
          ),
        }}
      />
      <FormControl
        sx={{ width: { md: '196px', xs: '70%' } }}
        className='form-control'
      >
        <InputLabel id='demo-simple-select-label'>Filter by</InputLabel>
        <Select
          onChange={handleOnChange}
          value={state?.filter_by}
          labelId='demo-simple-select-label'
          name='filter_by'
          id='demo-simple-select'
          label='Filter by'
        >
          {filters.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <button onClick={handleReset}>Clear filters</button>
    </div>
  )
}

export default PodcastFilter
