import React, { FC, MouseEventHandler, useState } from 'react'
import PhoneIcon from '../../../assets/phone_light.svg'
import InfoIcon from '../../../assets/info_light.svg'
import EmailIcon from '../../../assets/email_light.svg'

import { IconButtons2, IconButton } from '../lawfirm/LawfirmProfileCard'
import { LawyerListObject, MembershipType } from 'common/state-type'
import { getCardTheme } from 'utils/StylesUtil'
import useAreasOfPractice from 'common/providers/useAreasOfPractice'
import { InfoItem } from '../components'
import useFavourite from 'common/providers/useFavourite'
import { AccountType } from '../../../../../common/state-type'
import { Button } from '../../../../../common/components/Authentication/Button'
import { CardMode } from '../barrister/BarristerProfileCard'

type LawyerProfileCardType = {
  onMouseEnter?: MouseEventHandler
  data: LawyerListObject
  longitude?: any
  latitude?: any
  areas?: string[]
  onFavPress?: () => void
  onBookNow?: () => void
  onReviewBooking?: () => void
  bookingStatus?: React.ReactNode
  cardStyle?: string
  className?: string
  mode: CardMode
}

export const LawyerProfileCard: FC<LawyerProfileCardType> = ({
  data,
  onMouseEnter,
  onBookNow,
  onReviewBooking,
  bookingStatus,
  onFavPress,
  mode,
  className,
}) => {
  const { areasOfPractice } = useAreasOfPractice()
  const { createFavourite, deleteFavourite } = useFavourite()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isFavorited, setIsFavorited] = useState(!!data?.favourite_id)
  const [showPhoneNumber, setShowPhoneNumber] = useState(false)

  const aop =
    data?.organisation?.areas_of_practice &&
    areasOfPractice &&
    areasOfPractice.filter((e) =>
      data.organisation.areas_of_practice.find((area) => area.id === e.id),
    )

  const operatingAreas = aop?.map((item) => item.name)
  const membershipType: MembershipType = data.membership_type
  const themeColor = getCardTheme(membershipType)

  const toggleExpand = () => setIsExpanded(!isExpanded)

  const handleFavPress = () => {
    if (data?.id) {
      onFavPress()
      if (isFavorited) {
        const del = {
          id: data.id,
          type: AccountType.BARRISTER,
        }
        deleteFavourite(del)
        data.favourite_id = null
      } else {
        const type = Utils.getUserType(AccountType.LAWYER)

        createFavourite({ [type]: data.id })
        // .then((response) => {
        //   data.favourite_id = response.id
        // })
        // .catch((error) => {
        //   console.error('Error creating favourite:', error)
        // })
      }
      onFavPress && onFavPress()
    }
    setIsFavorited(!isFavorited)
  }

  const handlePhoneClick = () => {
    setShowPhoneNumber(!showPhoneNumber)
  }

  const handleEmailClick = () => {
    window.location.href = `mailto:customerservice@legalutopia.co.uk`
  }

  const handleWebsiteClick = () => {
    if (data.website_url) {
      window.open(data.website_url, '_blank')
    }
  }

  return (
    <div
      id={`profile-${data.id}`}
      onMouseEnter={onMouseEnter}
      className={`relative w-80 ${
        mode === CardMode.FAVOURITE ? 'h-[30rem]' : 'h-[18rem]'
      } bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 ${
        className ?? ''
      }`}
    >
      <div
        className={` ${mode === CardMode.FAVOURITE ? ' h-[30%]' : ' h-[50%]'}
           bg-gradient-to-b from-blue-300 to-blue-100 transform -skew-y-6 origin-top-left`}
      ></div>

      <div
        className={`relative ${
          mode === CardMode.FAVOURITE ? '-mt-16' : '-mt-12'
        } px-6`}
      >
        <div className='flex justify-between items-start mb-4'>
          <div className='space-y-2 -mt-14'>
            <IconButton iconSrc={EmailIcon} onClick={handleEmailClick} />

            <div className='relative'>
              <IconButton iconSrc={PhoneIcon} onClick={handlePhoneClick} />

              {showPhoneNumber && (
                <button
                  onClick={handlePhoneClick}
                  className='absolute left-0 top-0 bg-white rounded-full py-2 px-2 text-xs shadow-md whitespace-nowrap'
                >
                  {data.phone_number || 'N/A'}
                </button>
              )}
            </div>
            {mode === CardMode.FAVOURITE ? (
              <IconButton iconSrc={InfoIcon} onClick={handleWebsiteClick} />
            ) : (
              <></>
            )}
          </div>
          <div className='flex flex-col items-center -mt-12'>
            <h2 className='text-lg font-semibold text-center mb-8 '>
              {data.user.first_name} {data.user.last_name}
            </h2>
            <div className='w-20 h-20 bg-blue-100 rounded-full flex items-center justify-center shadow-md'>
              <span className='text-2xl font-bold'>
                {data.user.first_name?.[0] ?? ''}
                {data.user.last_name?.[0] ?? ''}
              </span>
            </div>
          </div>
          <div className='space-y-2 -mt-14'>
            <IconButtons2
              isFavorited={isFavorited}
              onFavPress={onFavPress}
              className='space-y-4'
            />
          </div>
        </div>

        {mode === CardMode.FAVOURITE ? (
          <div className='my-2 overflow-y-auto'>
            <p className='text-sm text-gray-600 text-center mt-2'>
              {data.bio || 'No bio available'}
            </p>
          </div>
        ) : (
          <></>
        )}

        <div
          className={`${
            isExpanded ? 'max-h-32 overflow-y-auto' : 'max-h-0 overflow-hidden'
          } transition-all duration-300`}
        >
          <div className='space-y-1 text-xs text-gray-600'>
            <InfoItem
              title='SRA Authorised'
              content={data.sra_authorised ? 'Yes' : 'No'}
            />
            <InfoItem title='SRA Number' content={data.sra_number || 'N/A'} />
            <InfoItem
              title='Academic Qualifications'
              content={
                data.qualifications?.map((q) => q.name).join(', ') ?? 'N/A'
              }
            />
            <InfoItem
              title='Memberships'
              content={data.memberships?.map((m) => m.name).join(', ') ?? 'N/A'}
            />
          </div>
        </div>

        {operatingAreas && operatingAreas.length > 0 && (
          <div className='mt-4'>
            <h3 className='text-sm font-semibold mb-2'>Areas of law</h3>
            <div className='flex flex-wrap gap-2'>
              {operatingAreas.slice(0, 3).map((area, index) => (
                <span
                  key={index}
                  className='px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-xs'
                >
                  {area}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className='absolute bottom-0 left-0 right-0 bg-[#6596D7] p-4'>
        <div className='flex justify-between items-center'>
          {mode === CardMode.APPOINTMENT ? (
            <>
              <div className='text-white'>
                <div className='text-lg font-medium leading-normal'>
                  From £{data.rate_per_hour_excluding_vat?.toFixed(2) ?? 'Nil'}
                </div>
              </div>
              <Button
                type='submit'
                variant='secondary'
                className='!px-0 !mx-0 !w-[20%]'
                onClick={onReviewBooking}
              >
                <span className='!px-0 !mx-0 '>View</span>
              </Button>
            </>
          ) : (
            <>
              <div className='text-white'>
                <div className='flex items-baseline'>
                  <span className='text-lg font-bold'>
                    From £
                    {data.rate_per_hour_excluding_vat?.toFixed(2) ?? 'Nil'}
                  </span>
                  <span className='text-xs ml-1'>p/hr</span>
                </div>
                <p className='text-xs'>Estimated cost</p>
              </div>

              <Button
                type='submit'
                variant='secondary'
                className='!px-0 !mx-0 !w-auto'
                onClick={onBookNow}
              >
                <span className='px-1'>{'Book Now'}</span>
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
