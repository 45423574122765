import { Amplify } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useOffices from 'common/providers/useOffices'

import {
  AccountType,
  BarristerProfile,
  LawyerListObject,
} from 'common/state-type'
import Project from 'common/project'
import { OfficeSummary } from 'common/state-type'
import useFavourite from 'common/providers/useFavourite'
import { LawyerProfileCard } from './lawyer/LawyerProfileCard'
import useBarristers from 'common/providers/useBarristers'
import { LawfirmProfileCard } from './lawfirm/LawfirmProfileCard'
import useLawyerProfiles from 'common/providers/useLawyerProfiles'
import {
  BarristerProfileCard,
  CardMode,
} from './barrister/BarristerProfileCard'
import { BookingPopup, CreateBooking, BookingProfileType } from './booking'
import PaginationBar from 'components/CWP Sections/components/PaginationBar'

const mileToKm = 1.60934
export type position = {
  lat: number
  lng: number
}

type RegionType = {
  latitude: number
  longitude: number
  latitudeDelta: number
  longitudeDelta: number
}

Amplify.configure(Project.cognitoWeb)
const isMobile = Utils.getWindowDimensions().width < 768

const Favourites = () => {
  const navigate = useNavigate()

  const { getOffices, offices: _offices } = useOffices()
  const { createFavourite, deleteFavourite } = useFavourite()
  const { getBarristers, barristers: _barristers } = useBarristers()
  const { getLawyerProfiles, lawyerProfiles: _lawyers } = useLawyerProfiles()
  const [bookingProfile, setBookingProfile] = useState<BookingProfileType>(null)

  const kilometers = 20 * mileToKm
  const delta = kilometers / 100
  const initialRegionValue = {
    latitude: 51.5074,
    longitude: 0.1278,
    latitudeDelta: delta,
    longitudeDelta: delta,
  }
  const [region, setRegion] = useState<RegionType>(initialRegionValue)
  const [activeMarker, setActiveMarker] = useState<string | null>(null)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (navigator.geolocation) {
      ;(
        navigator.permissions?.query?.({ name: 'geolocation' }) ||
        Promise.resolve()
      ).then(() => {
        navigator.geolocation.getCurrentPosition((position) => {
          setRegion({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            latitudeDelta: delta,
            longitudeDelta: delta,
          })
        })
      })
    }
  }, [])

  const fetchParams = {
    page_size: 20,
    favourites_only: true,
  }

  useEffect(() => {
    getLawyerProfiles(fetchParams)
    getBarristers(fetchParams)
    getOffices(fetchParams)
  }, [])

  const solicitor = _lawyers?.results?.map((lawyer) => ({
    ...lawyer,
    type: AccountType.LAWYER,
  }))

  const barristers = _barristers?.results?.map((barrister) => ({
    ...barrister,
    type: AccountType.BARRISTER,
  }))

  const lawfirms = _offices?.results?.map((office) => ({
    ...office,
    type: AccountType.BUSINESS,
  }))

  const allProfiles =
    solicitor && barristers && lawfirms
      ? [...solicitor, ...barristers, ...lawfirms]
      : []

  const handleFavPress = (
    item: LawyerListObject | BarristerProfile | OfficeSummary,
    accountType: AccountType,
  ) => {
    if (item.favourite_id) {
      const data = {
        id: item.favourite_id,
        type: accountType,
      }
      deleteFavourite(data)
    }
  }

  const handleBookPress = (
    item: LawyerListObject | BarristerProfile,
    accountType: AccountType,
  ) => {
    setBookingProfile({ profile: item, lawyerType: accountType })
  }

  const handlePageClick = (clickEvent) => {
    if (clickEvent.isNext) {
      _lawyers.next && getLawyerProfiles({ link: _lawyers.next })
      _barristers.next && getBarristers({ link: _barristers.next })
      _offices.next && getOffices({ link: _offices.next })
    }
    if (clickEvent.isPrevious) {
      _lawyers.previous && getLawyerProfiles({ link: _lawyers.previous })
      _barristers.previous && getBarristers({ link: _barristers.previous })
      _offices.previous && getOffices({ link: _offices.previous })
    }
  }

  return (
    <>
      <div
        className='h-16 flex items-center justify-center'
        style={{
          background:
            'linear-gradient(90deg, #CCE0DD 0%, #9FB7D7 50%, rgba(190, 190, 223, 0.98) 100%)',
        }}
      >
        <h1 className='text-white text-2xl font-bold'>Favourites</h1>
      </div>

      <div className='max-w-7xl mx-auto'>
        <div className='flex flex-row px-1  overflow-scroll w-full   pb-8'>
          <div
            id='profile-list'
            className=' flex  flex-col space-y-4 md:space-y-1 md:flex-row  space-x-8 pt-8'
          >
            {allProfiles.map((profile) => {
              if (profile.type === AccountType.LAWYER) {
                return (
                  <LawyerProfileCard
                    key={profile.id}
                    mode={CardMode.FAVOURITE}
                    onMouseEnter={() => setActiveMarker(profile.id)}
                    data={profile as LawyerListObject}
                    onFavPress={() =>
                      handleFavPress(profile, AccountType.LAWYER)
                    }
                    onBookNow={() =>
                      handleBookPress(
                        profile as LawyerListObject,
                        AccountType.LAWYER,
                      )
                    }
                  />
                )
              }
              if (profile.type === AccountType.BARRISTER) {
                return (
                  <BarristerProfileCard
                    mode={CardMode.FAVOURITE}
                    onMouseEnter={() => setActiveMarker(profile.id)}
                    data={profile as BarristerProfile}
                    onFavPress={() =>
                      handleFavPress(profile, AccountType.BARRISTER)
                    }
                    onBookNow={() =>
                      handleBookPress(
                        profile as BarristerProfile,
                        AccountType.BARRISTER,
                      )
                    }
                  />
                )
              }
              if (profile.type === AccountType.BUSINESS) {
                return (
                  <LawfirmProfileCard
                    mode={CardMode.FAVOURITE}
                    key={profile.id}
                    onMouseEnter={() => setActiveMarker(profile.id)}
                    latitude={region.latitude}
                    longitude={region.longitude}
                    data={profile as OfficeSummary}
                    onFavPress={() =>
                      handleFavPress(profile, AccountType.BUSINESS)
                    }
                    onBookNow={() =>
                      navigate(`/lawfirms/${profile.id}/members`)
                    }
                  />
                )
              }
            })}
          </div>

          <PaginationBar onClick={handlePageClick} />
        </div>
        {bookingProfile && (
          <BookingPopup>
            <CreateBooking
              lawyer={bookingProfile.profile}
              lawyerType={bookingProfile.lawyerType}
              handleClose={() => setBookingProfile(null)}
            />
          </BookingPopup>
        )}
      </div>
    </>
  )
}

export default Favourites
