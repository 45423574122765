import { useState, useEffect } from 'react';
import app from './firebase'
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';

const storage = getStorage(app);

export const useFirebaseCloudStorage = (folderName: string) => {
  const [images, setImages] = useState<string[]>([]);
  const [videos, setVideos] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const folderRef = ref(storage, folderName);
        const result = await listAll(folderRef);

        const mediaPromises = result.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return { url, name: itemRef.name };
        });

        const mediaItems = await Promise.all(mediaPromises);

        const imageUrls = mediaItems
          .filter(item => !item.name.toLowerCase().endsWith('.mp4'))
          .map(item => item.url);

        const videoUrls = mediaItems
          .filter(item => item.name.toLowerCase().endsWith('.mp4'))
          .map(item => item.url);

        setImages(imageUrls);
        setVideos(videoUrls);
        setLoading(false);
      } catch (err) {
        setError('Error fetching media');
        setLoading(false);
      }
    };

    fetchMedia();
  }, [folderName]);

  const getImages = () => images;
  const getAudios = () => videos;

  return { getImages, getAudios, loading, error };
};