import { Button } from 'components/forms/Buttons'
import React from 'react'

export const CTA_WhoWeAre = () => {
  return (
    <div id='cta-whoweare' className='flex justify-center'>
      <div className='col-md-7 text-center'>
        <h1 className='mb-5'>
          Our everyday is making law work for everyone. We're the people making
          that happen.
        </h1>
        <Button className='btn-primary'>Who we are</Button>
      </div>
    </div>
  )
}
