import './utils'
import { Actions } from './app-actions'
import produce, { enableES5 } from 'immer'
enableES5() // required for react native hermes
// eslint-disable-next-line no-unused-vars
import {
  appendItem,
  deleteItem,
  itemError,
  itemLoaded,
  itemLoading,
  itemSaved,
  itemSaving,
} from './utils/reducer-helpers'
import {
  AppState,
  BarristerProfile,
  LawyerListObject,
  RequestTypes,
} from './state-type'

const defaultReducer = produce((state: AppState, action): AppState | void => {
  if (typeof window === 'undefined') {
    API.log('SERVER', action.type, action.data)
    if (action.type.includes('ERROR')) {
      API.log('SERVER', action)
    }
  } else {
    API.log('DISPATCHER', action.type)
  }
  switch (action.type) {
    case Actions.LOGIN_LOADED:
    case Actions.RESET_PASSWORD_LOADED:
    case Actions.CHANGE_PASSWORD_LOADED:
    case Actions.UPDATE_USER_LOADED:
    case Actions.CONFIRM_EMAIL_LOADED:
    case Actions.REGISTER_LOADED:
      itemLoaded(state, 'user', action)
      state.challengeName = null
      state.user = null
      break
    case Actions.LOGIN_ERROR:
    case Actions.RESET_PASSWORD_ERROR:
    case Actions.CHANGE_PASSWORD_ERROR:
    case Actions.CONFIRM_EMAIL_ERROR:
    case Actions.REGISTER_ERROR:
    case Actions.UPDATE_USER_ERROR:
      itemError(state, 'user', action)
      break
    case Actions.UPDATE_USER:
    case Actions.CONFIRM_EMAIL:
    case Actions.LOGIN:
    case Actions.RESET_PASSWORD:
    case Actions.CHANGE_PASSWORD:
      itemLoading(state, 'user')
      state.challengeName = null
      break
    case Actions.REGISTER:
      itemLoading(state, 'user')
      const profileData = {
        id: '',
        username: '',
        first_name: action.data.first_name,
        last_name: action.data.last_name,
        email: action.data.email,
        account_type: action.data.account_type,
        accepted_marketing_policy: action.data.accepted_marketing,
        premium: false,
        date_of_birth: action.data.date_of_birth,
        gender: action.data.gender,
        how_user_heard_lu: action.data.how_user_heard_lu,
      }
      state.profile = profileData
      break
    case Actions.CLEAR_USER:
      state.user = null
      state.inviteLink = null
      state.menuItems = null
      state.profile = null
      state.challengeName = null
      state.lawyersProfile = null
      state.verifyMfa = null
      state.authWithCronofy = null
      break
    case Actions.STARTUP_LOADED:
      Object.keys(action.data).map((k) => {
        state[k] = action.data[k]
      })
      break
    case Actions.SET_ACTIVE_SCREEN:
      itemLoaded(state, 'activeScreen', action, true)
      break
    case Actions.GET_PROFILE:
      return itemLoading(state, 'profile')
    case Actions.GET_PROFILE_LOADED:
      state.userLoading = false
      state.challengeName = null
      return itemLoaded(state, 'profile', action)
    case Actions.GET_PROFILE_ERROR:
      state.userLoading = false
      return itemError(state, 'profile', action)
    case Actions.UPDATE_PROFILE:
      return itemSaving(state, 'profile')
    case Actions.UPDATE_PROFILE_LOADED:
      return itemSaved(state, 'profile', action)
    case Actions.UPDATE_PROFILE_ERROR:
      return itemError(state, 'profile', action)
    case Actions.GET_FEEDBACK_SUMMARY:
      return itemLoading(state, 'feedbackSummary')
    case Actions.GET_FEEDBACK_SUMMARY_LOADED:
      return itemLoaded(state, 'feedbackSummary', action)
    case Actions.GET_FEEDBACK_SUMMARY_ERROR:
      return itemError(state, 'feedbackSummary', action)
    case Actions.GET_FEEDBACK:
      return itemLoading(state, 'feedback')
    case Actions.GET_FEEDBACK_LOADED:
      const data: AppState['feedback'] = action.data
      if (!data.previous) {
        return itemLoaded(state, 'feedback', action)
      } else {
        const newData: AppState['feedback'] = {
          ...data,
          results: (state.feedback?.results || []).concat(data.results),
        }
        return itemLoaded(state, 'feedback', {
          ...action,
          data: newData,
        })
      }
    case Actions.GET_FEEDBACK_ERROR:
      return itemError(state, 'feedback', action)
    case Actions.CREATE_FEEDBACK:
      return itemSaving(state, 'feedback')
    case Actions.CREATE_FEEDBACK_LOADED:
      return itemSaved(state, 'feedback', action, false, true)
    case Actions.CREATE_FEEDBACK_ERROR:
      return itemError(state, 'feedback', action)

    case Actions.REDEEM:
      return itemSaving(state, 'redeem')

    case Actions.REDEEM_LOADED:
      return itemSaved(state, 'redeem', action, false, true)

    case Actions.REDEEM_ERROR:
      return itemError(state, 'redeem')

    case Actions.GET_FAQS:
      return itemLoading(state, 'faqs')
    case Actions.GET_FAQS_LOADED:
      return itemLoaded(state, 'faqs', action)
    case Actions.GET_FAQS_ERROR:
      return itemError(state, 'faqs', {})

    //Reducer for LegalChecker Roles, Status, Remedy
    case Actions.GET_LEGAL_CHECKER:
      return itemLoading(state, 'legalChecker')
    case Actions.GET_LEGAL_CHECKER_LOADED:
      return itemLoaded(state, `legalChecker`, action)
    case Actions.GET_LEGAL_CHECKER_ERROR:
      return itemError(state, 'legalChecker', action)
    case Actions.SET_LEGAL_CHECKER_OPTIONS:
      state.legalCheckerOptions = {
        ...state.legalCheckerOptions,
        ...action.data,
      }
      return

    case Actions.SET_LEGAL_CHECKER_META:
      state.legalCheckerMeta = {
        ...state.legalCheckerMeta,
        ...action.data,
      }
      return

    //Reducer for LegalChecker Domains & Domain
    case Actions.GET_LEGAL_CHECKER_DOMAINS:
      return itemLoading(state, 'legalCheckerDomains')
    case Actions.GET_LEGAL_CHECKER_DOMAINS_LOADED:
      return itemLoaded(state, `legalCheckerDomains`, action)
    case Actions.GET_LEGAL_CHECKER_DOMAINS_ERROR:
      return itemError(state, 'legalCheckerDomains', action)
    case Actions.GET_LEGAL_CHECKER_SUB_DOMAINS:
      return itemLoading(state, 'legalCheckerSubDomains')
    case Actions.GET_LEGAL_CHECKER_SUB_DOMAINS_LOADED:
      return itemLoaded(state, `legalCheckerSubDomains`, action)
    case Actions.GET_LEGAL_CHECKER_SUB_DOMAINS_ERROR:
      return itemError(state, 'legalCheckerSubDomains', action)

    //Reducer for the CustomMenu
    case Actions.SET_MENU_ICONS:
      return itemLoaded(state, 'menuItems', action)
    default:
      break

    case Actions.GET_QUESTIONS:
      return itemLoading(state, 'legalCheckerQuestions')
    case Actions.GET_QUESTIONS_LOADED:
      return itemLoaded(state, 'legalCheckerQuestions', action)
    case Actions.GET_QUESTIONS_ERROR:
      return itemError(state, 'legalCheckerQuestions', action)
    case Actions.GET_SCENARIOS:
      return itemLoading(state, 'legalCheckerScenarios')
    case Actions.GET_SCENARIOS_LOADED:
      return itemLoaded(state, 'legalCheckerScenarios', action)
    case Actions.GET_SCENARIOS_ERROR:
      return itemError(state, 'legalCheckerScenarios', action)
    case Actions.CREATE_LEGAL_CHECKER_SUBMISSION:
      return itemSaving(state, 'legalCheckerSubmission')
    case Actions.CREATE_LEGAL_CHECKER_SUBMISSION_LOADED:
      return itemSaved(state, 'legalCheckerSubmission', action)
    case Actions.CREATE_LEGAL_CHECKER_SUBMISSION_ERROR:
      return itemError(state, 'legalCheckerSubmission', action)

    case Actions.GET_LEGAL_CHECKER_RESULTS:
      return itemSaving(state, 'legalCheckerResults')
    case Actions.GET_LEGAL_CHECKER_RESULTS_LOADED:
      return itemSaved(state, 'legalCheckerResults', action)
    case Actions.GET_LEGAL_CHECKER_RESULTS_ERROR:
      return itemError(state, 'legalCheckerResults', action)

    case Actions.GET_LEGAL_CHECKER_SERVICES:
      return itemSaving(state, 'legalCheckerServices')
    case Actions.GET_LEGAL_CHECKER_SERVICES_LOADED:
      return itemSaved(state, 'legalCheckerServices', action)
    case Actions.GET_LEGAL_CHECKER_SERVICES_ERROR:
      return itemError(state, 'legalCheckerServices', action)

    case Actions.GET_LEGAL_CHECKER_PORTALS:
      return itemSaving(state, 'legalCheckerPortals')
    case Actions.GET_LEGAL_CHECKER_PORTALS_LOADED:
      return itemSaved(state, 'legalCheckerPortals', action)
    case Actions.GET_LEGAL_CHECKER_PORTALS_ERROR:
      return itemError(state, 'legalCheckerPortals', action)

    case Actions.GET_LEGAL_CHECKER_GUIDANCE:
      return itemSaving(state, 'legalCheckerGuidance')
    case Actions.GET_LEGAL_CHECKER_GUIDANCE_LOADED:
      return itemSaved(state, 'legalCheckerGuidance', action)
    case Actions.GET_LEGAL_CHECKER_GUIDANCE_ERROR:
      return itemError(state, 'legalCheckerGuidance', action)

    case Actions.GET_LEGAL_CHECKER_RESOURCES:
      return itemSaving(state, 'legalChekerResources')
    case Actions.GET_LEGAL_CHECKER_RESOURCES_LOADED:
      return itemSaved(state, 'legalCheckerResources', action)
    case Actions.GET_LEGAL_CHECKER_RESOURCES_ERROR:
      return itemError(state, 'legalCheckerResources', action)

    case Actions.CREATE_LEGAL_CHECKER_RESULT:
      return itemSaving(state, 'legalCheckerResult')
    case Actions.CREATE_LEGAL_CHECKER_RESULT_LOADED:
      return itemSaved(state, 'legalCheckerResult', action)
    case Actions.CREATE_LEGAL_CHECKER_RESULT_ERROR:
      return itemError(state, 'legalCheckerResult', action)
    case Actions.GET_LEGAL_CHECKER_RESULT:
      return itemLoading(state, 'legalCheckerResult')
    case Actions.GET_LEGAL_CHECKER_RESULT_LOADED:
      state.legalCheckerResultFull = action.data
      state.legalCheckerResultLoading = false
      state.legalCheckerResultError = null
      return state
    case Actions.GET_LEGAL_CHECKER_RESULT_ERROR:
      return itemError(state, 'legalCheckerResult', action)
    case Actions.UPDATE_LEGAL_CHECKER_RESULT:
      return itemSaving(state, 'legalCheckerResult')
    case Actions.UPDATE_LEGAL_CHECKER_RESULT_LOADED:
      return itemSaved(state, 'legalCheckerResult', action)
    case Actions.UPDATE_LEGAL_CHECKER_RESULT_ERROR:
      return itemError(state, 'legalCheckerResult', action)
    case Actions.DELETE_LEGAL_CHECKER_RESULT:
      return itemSaving(state, 'legalCheckerResult')
    case Actions.DELETE_LEGAL_CHECKER_RESULT_LOADED:
      return itemSaved(state, 'legalCheckerResult', action)
    case Actions.DELETE_LEGAL_CHECKER_RESULT_ERROR:
      return itemError(state, 'legalCheckerResult', action)
    case Actions.GET_LEGAL_AID_QUESTIONS:
      return itemLoading(state, 'legalAidQuestions')
    case Actions.GET_LEGAL_AID_QUESTIONS_LOADED:
      return itemLoaded(state, 'legalAidQuestions', action)
    case Actions.GET_LEGAL_AID_QUESTIONS_ERROR:
      return itemError(state, 'legalAidQuestions', action)
    case Actions.CREATE_GUIDANCE_FEEDBACK:
      return itemSaving(state, 'guidanceFeedback')
    case Actions.CREATE_GUIDANCE_FEEDBACK_LOADED:
      return itemSaved(state, 'guidanceFeedback', action)
    case Actions.CREATE_GUIDANCE_FEEDBACK_ERROR:
      return itemError(state, 'guidanceFeedback', action)
    case Actions.UPDATE_GUIDANCE_FEEDBACK:
      return itemSaving(state, 'guidanceFeedback')
    case Actions.UPDATE_GUIDANCE_FEEDBACK_LOADED:
      return itemSaved(state, 'guidanceFeedback', action)
    case Actions.UPDATE_GUIDANCE_FEEDBACK_ERROR:
      return itemError(state, 'guidanceFeedback', action)
    case Actions.GET_LEGAL_AID_CONTENT:
      return itemLoading(state, 'legalAidContent')
    case Actions.GET_LEGAL_AID_CONTENT_LOADED:
      return itemLoaded(state, 'legalAidContent', action)
    case Actions.GET_LEGAL_AID_CONTENT_ERROR:
      return itemError(state, 'legalAidContent', action)

    case Actions.GET_LEGAL_CHECKER_FUTHER_GUIDANCE:
      return itemSaving(state, 'legalCheckerFurtherGuidance')
    case Actions.GET_LEGAL_CHECKER_FUTHER_GUIDANCE_LOADED:
      return itemSaved(state, 'legalCheckerFurtherGuidance', action)
    case Actions.GET_LEGAL_CHECKER_FUTHER_GUIDANCE_ERROR:
      return itemError(state, 'legalCheckerFurtherGuidance', action)
    case Actions.CREATE_VERIFY_CREDIT_PURCHASE:
      return itemSaving(state, 'verifyCreditPurchase')
    case Actions.CREATE_VERIFY_CREDIT_PURCHASE_LOADED:
      return itemSaved(state, 'verifyCreditPurchase', action)
    case Actions.CREATE_VERIFY_CREDIT_PURCHASE_ERROR:
      return itemError(state, 'verifyCreditPurchase', action)
    case Actions.GET_IAPS:
      return itemLoading(state, 'iaps')
    case Actions.GET_IAPS_LOADED:
      return itemLoaded(state, 'iaps', action)
    case Actions.GET_IAPS_ERROR:
      return itemError(state, 'iaps', action)
    case Actions.GET_SUBSCRIPTIONS:
      return itemLoading(state, 'subscriptions')
    case Actions.GET_SUBSCRIPTIONS_LOADED:
      return itemLoaded(state, 'subscriptions', action)
    case Actions.GET_SUBSCRIPTIONS_ERROR:
      return itemError(state, 'subscriptions', action)
    case Actions.GET_RESULT_CREDITS:
      return itemLoading(state, 'resultCredits')
    case Actions.GET_RESULT_CREDITS_LOADED:
      return itemLoaded(state, 'resultCredits', action)
    case Actions.GET_RESULT_CREDITS_ERROR:
      return itemError(state, 'resultCredits', action)
    case Actions.CREATE_UNLOCK:
      return itemSaving(state, 'unlock')
    case Actions.CREATE_UNLOCK_LOADED:
      return itemSaved(state, 'unlock', action)
    case Actions.CREATE_UNLOCK_ERROR:
      return itemError(state, 'unlock', action)
    case Actions.CREATE_VIEW_RESULT:
      return itemSaving(state, 'viewResult')
    case Actions.CREATE_VIEW_RESULT_LOADED:
      return itemSaved(state, 'viewResult', action)
    case Actions.CREATE_VIEW_RESULT_ERROR:
      return itemError(state, 'viewResult', action)
    case Actions.GET_LEGAL_DOCUMENT_TYPES:
      return itemLoading(state, 'legalDocumentTypes')
    case Actions.GET_LEGAL_DOCUMENT_TYPES_LOADED:
      return itemLoaded(state, 'legalDocumentTypes', action)
    case Actions.GET_LEGAL_DOCUMENT_TYPES_ERROR:
      return itemError(state, 'legalDocuments', action)
    case Actions.GET_LEGAL_DOCUMENT_TEMPLATE_FILTERS:
      return itemLoading(state, 'legalDocumentTemplateFilters')
    case Actions.GET_LEGAL_DOCUMENT_TEMPLATE_FILTERS_LOADED:
      return itemLoaded(state, 'legalDocumentTemplateFilters', action)
    case Actions.GET_LEGAL_DOCUMENT_TEMPLATE_FILTERS_ERROR:
      return itemError(state, 'legalDocumentTemplateFilters', action)
    case Actions.GET_LEGAL_DOCUMENTS:
      return itemLoading(state, 'legalDocuments')
    case Actions.GET_LEGAL_DOCUMENTS_LOADED:
      const docs: AppState['legalDocuments'] = action.data
      if (docs.previous) {
        action.data.results = state.legalDocuments.results.concat(docs.results)
      }
      return itemLoaded(state, 'legalDocuments', action)
    case Actions.GET_LEGAL_DOCUMENTS_ERROR:
      return itemError(state, 'legalDocuments', action)
    case Actions.GET_LEGAL_DOCUMENT:
      return itemLoading(state, 'legalDocument')
    case Actions.GET_LEGAL_DOCUMENT_LOADED:
      return itemLoaded(state, 'legalDocument', action)
    case Actions.GET_LEGAL_DOCUMENT_ERROR:
      return itemError(state, 'legalDocument', action)
    case Actions.GET_LEGAL_DOCUMENT_RATINGS:
      return itemLoading(state, 'legalDocumentRatings')
    case Actions.GET_LEGAL_DOCUMENT_RATINGS_LOADED:
      return itemLoaded(state, 'legalDocumentRatings', action)
    case Actions.GET_LEGAL_DOCUMENT_RATINGS_ERROR:
      return itemError(state, 'legalDocumentRatings', action)
    case Actions.GET_LEGAL_DOCUMENT_CODE:
      return itemLoading(state, 'legalDocumentCode')
    case Actions.GET_LEGAL_DOCUMENT_CODE_LOADED:
      return itemLoaded(state, 'legalDocumentCode', action)
    case Actions.GET_LEGAL_DOCUMENT_CODE_ERROR:
      return itemError(state, 'legalDocumentCode', action)
    case Actions.CREATE_LEGAL_DOCUMENT_RATING:
      return itemSaving(state, 'legalDocumentRating')
    case Actions.CREATE_LEGAL_DOCUMENT_RATING_LOADED:
      return itemSaved(state, 'legalDocumentRating', action)
    case Actions.CREATE_LEGAL_DOCUMENT_RATING_ERROR:
      return itemError(state, 'legalDocumentRating', action)
    case Actions.UPDATE_LEGAL_DOCUMENT_RATING:
      return itemSaving(state, 'legalDocumentRating')
    case Actions.UPDATE_LEGAL_DOCUMENT_RATING_LOADED:
      return itemSaved(state, 'legalDocumentRating', action)
    case Actions.UPDATE_LEGAL_DOCUMENT_RATING_ERROR:
      return itemError(state, 'legalDocumentRating', action)
    case Actions.GET_GOVERNMENT_SERVICES:
      return itemLoading(state, 'governmentServices')
    case Actions.GET_GOVERNMENT_SERVICES_LOADED:
      return itemLoaded(state, 'governmentServices', action)
    case Actions.GET_GOVERNMENT_SERVICES_ERROR:
      return itemError(state, 'governmentServices', action)
    case Actions.CREATE_CLOSE_ACCOUNT:
      return itemSaving(state, 'closeAccount')
    case Actions.CREATE_CLOSE_ACCOUNT_LOADED:
      state.user = null
      state.inviteLink = null
      state.menuItems = null
      state.profile = null
      return itemSaved(state, 'closeAccount', action)
    case Actions.CREATE_CLOSE_ACCOUNT_ERROR:
      return itemError(state, 'closeAccount', action)
    case Actions.SET_INVITE_LINK:
      state.inviteLink = action.data.inviteLink
      state.acceptedLink = action.data.acceptedLink
      return state
    case Actions.GET_COVID_REGULATIONS:
      return itemLoading(state, 'covidRegulations')
    case Actions.GET_COVID_REGULATIONS_LOADED:
      const cov: AppState['covidRegulations'] = action.data
      if (cov.previous) {
        action.data.results = state.covidRegulations.results.concat(cov.results)
      }

      return itemLoaded(state, 'covidRegulations', action)
    case Actions.GET_COVID_REGULATIONS_ERROR:
      return itemError(state, 'covidRegulations', action)
    case Actions.CREATE_LEGAL_RESULT_FEEDBACK:
      return itemSaving(state, 'legalResultFeedback')
    case Actions.CREATE_LEGAL_RESULT_FEEDBACK_LOADED:
      return itemSaved(state, 'legalResultFeedback', action)
    case Actions.CREATE_LEGAL_RESULT_FEEDBACK_ERROR:
      return itemError(state, 'legalResultFeedback', action)
    case Actions.GET_INDUSTRIES:
      return itemLoading(state, 'industries')
    case Actions.GET_INDUSTRIES_LOADED:
      return itemLoaded(state, 'industries', action)
    case Actions.GET_INDUSTRIES_ERROR:
      return itemError(state, 'industries', action)
    case Actions.GET_INVITE_TOKEN:
      return itemLoading(state, 'inviteToken')
    case Actions.GET_INVITE_TOKEN_LOADED:
      return itemLoaded(state, 'inviteToken', action)
    case Actions.GET_INVITE_TOKEN_ERROR:
      return itemError(state, 'inviteToken', action)
    case Actions.GET_AREAS_OF_PRACTICE:
      return itemLoading(state, 'areasOfPractice')
    case Actions.GET_AREAS_OF_PRACTICE_LOADED:
      return itemLoaded(state, 'areasOfPractice', action)
    case Actions.GET_AREAS_OF_PRACTICE_ERROR:
      return itemError(state, 'areasOfPractice', action)
    case Actions.GET_OFFICES:
      return itemLoading(state, 'offices')
    case Actions.GET_OFFICES_LOADED:
      return itemLoaded(state, 'offices', action)
    case Actions.GET_OFFICES_ERROR:
      return itemError(state, 'offices', action)
    case Actions.GET_OFFICE:
      return itemLoading(state, 'office')
    case Actions.GET_OFFICE_LOADED:
      return itemLoaded(state, 'office', action)
    case Actions.GET_OFFICE_ERROR:
      return itemError(state, 'office', action)
    case Actions.GET_AREA_CATEGORIES:
      return itemLoading(state, 'areaCategories')
    case Actions.GET_AREA_CATEGORIES_LOADED:
      return itemLoaded(state, 'areaCategories', action)
    case Actions.GET_AREA_CATEGORIES_ERROR:
      return itemError(state, 'areaCategories', action)
    case Actions.GET_DOCUMENT_CATEGORIES:
      return itemLoading(state, 'documentCategories')
    case Actions.GET_DOCUMENT_CATEGORIES_LOADED:
      return itemLoaded(state, 'documentCategories', action)
    case Actions.GET_DOCUMENT_CATEGORIES_ERROR:
      return itemError(state, 'documentCategories', action)
    case Actions.GET_DOCUMENT_FILTERS:
      return itemLoading(state, 'documentFilters')
    case Actions.GET_DOCUMENT_FILTERS_LOADED:
      return itemLoaded(state, 'documentFilters', action)
    case Actions.GET_DOCUMENT_FILTERS_ERROR:
      return itemError(state, 'documentFilters', action)
    case Actions.GET_LAWYER_AVAILABILITY:
      state.lawyerAvailability = null
      return itemLoading(state, 'lawyerAvailability')
    case Actions.GET_LAWYER_AVAILABILITY_LOADED:
      return itemLoaded(state, 'lawyerAvailability', action)
    case Actions.GET_LAWYER_AVAILABILITY_ERROR:
      return itemError(state, 'lawyerAvailability', action)
    case Actions.CREATE_CHALLENGE_NAME:
      const actionData: RequestTypes['createChallengeName'] = action.data
      state.challengeName = actionData
      return state
    case Actions.CREATE_PASSWORD:
      return itemSaving(state, 'password')
    case Actions.CREATE_PASSWORD_LOADED:
      return itemSaved(state, 'password', action)
    case Actions.CREATE_PASSWORD_ERROR:
      return itemError(state, 'password', action)
    case Actions.GET_LAWYERS_PROFILE:
      return itemLoading(state, 'lawyersProfile')
    case Actions.GET_LAWYERS_PROFILE_LOADED:
      return itemLoaded(state, 'lawyersProfile', action)
    case Actions.GET_LAWYERS_PROFILE_ERROR:
      return itemError(state, 'lawyersProfile', action)
    case Actions.CREATE_CRONOFY_AUTH_LINK:
      return itemSaving(state, 'cronofyAuthLink')
    case Actions.CREATE_CRONOFY_AUTH_LINK_LOADED:
      return itemSaved(state, 'cronofyAuthLink', action)
    case Actions.CREATE_CRONOFY_AUTH_LINK_ERROR:
      return itemError(state, 'cronofyAuthLink', action)
    case Actions.CREATE_AUTH_WITH_CRONOFY:
      return itemSaving(state, 'authWithCronofy')
    case Actions.CREATE_AUTH_WITH_CRONOFY_LOADED:
      return itemSaved(state, 'authWithCronofy', action)
    case Actions.CREATE_AUTH_WITH_CRONOFY_ERROR:
      return itemError(state, 'authWithCronofy', action)
    case Actions.CREATE_SETUP_MFA:
      return itemSaving(state, 'setupMfa')
    case Actions.CREATE_SETUP_MFA_LOADED:
      return itemSaved(state, 'setupMfa', action)
    case Actions.CREATE_SETUP_MFA_ERROR:
      return itemError(state, 'setupMfa', action)
    case Actions.CREATE_VERIFY_MFA:
      return itemSaving(state, 'verifyMfa')
    case Actions.CREATE_VERIFY_MFA_LOADED:
      state.setupMfa = null
      return itemSaved(state, 'verifyMfa', action)
    case Actions.CREATE_VERIFY_MFA_ERROR:
      return itemError(state, 'verifyMfa', action)
    case Actions.GET_LAWYER_PROFILES:
      return itemLoading(state, 'lawyerProfiles')
    case Actions.GET_LAWYER_PROFILES_LOADED:
      return itemLoaded(state, 'lawyerProfiles', action)
    case Actions.GET_LAWYER_PROFILES_ERROR:
      return itemError(state, 'lawyerProfiles', action)
    case Actions.CREATE_CONFIRM_SIGNIN:
      return itemSaving(state, 'confirmSignin')
    case Actions.CREATE_CONFIRM_SIGNIN_LOADED:
      return itemSaved(state, 'confirmSignin', action)
    case Actions.CREATE_CONFIRM_SIGNIN_ERROR:
      return itemError(state, 'confirmSignin', action)
    case Actions.GET_BOOKINGS:
      return itemLoading(state, 'bookings')
    case Actions.GET_BOOKINGS_LOADED:
      if (action.data.previous) {
        action.data.results = state.bookings.results.concat(action.data.results)
      }
      return itemLoaded(state, 'bookings', action)
    case Actions.GET_BOOKINGS_ERROR:
      return itemError(state, 'bookings', action)
    case Actions.GET_ACCEPTED_BOOKINGS:
      return itemLoading(state, 'acceptedBookings')
    case Actions.GET_ACCEPTED_BOOKINGS_LOADED:
      if (action.data.previous) {
        action.data.results = state.acceptedBookings.results.concat(
          action.data.results,
        )
      }
      return itemLoaded(state, 'acceptedBookings', action)

    case Actions.GET_ACCEPTED_BOOKINGS_ERROR:
      return itemError(state, 'acceptedBookings', action)
    case Actions.GET_PENDING_BOOKINGS:
      return itemLoading(state, 'pendingBookings')
    case Actions.GET_PENDING_BOOKINGS_LOADED:
      if (action.data.previous) {
        action.data.results = state.pendingBookings.results.concat(
          action.data.results,
        )
      }
      return itemLoaded(state, 'pendingBookings', action)
    case Actions.GET_PENDING_BOOKINGS_ERROR:
      return itemError(state, 'pendingBookings', action)
    case Actions.GET_DECLINED_BOOKINGS:
      return itemLoading(state, 'declinedBookings')
    case Actions.GET_DECLINED_BOOKINGS_LOADED:
      if (action.data.previous) {
        action.data.results = state.declinedBookings.results.concat(
          action.data.results,
        )
      }
      return itemLoaded(state, 'declinedBookings', action)
    case Actions.GET_DECLINED_BOOKINGS_ERROR:
      return itemError(state, 'declinedBookings', action)
    case Actions.DELETE_BOOK_A_LAWYER:
      return itemSaving(state, 'bookALawyer')
    case Actions.DELETE_BOOK_A_LAWYER_LOADED:
      return itemSaved(state, 'bookALawyer', action)
    case Actions.DELETE_BOOK_A_LAWYER_ERROR:
      return itemError(state, 'bookALawyer', action)
    case Actions.CREATE_BOOK_A_LAWYER:
      return itemSaving(state, 'bookALawyer')
    case Actions.CREATE_BOOK_A_LAWYER_LOADED:
      return itemSaved(state, 'bookALawyer', action)
    case Actions.CREATE_BOOK_A_LAWYER_ERROR:
      return itemError(state, 'bookALawyer', action)
    case Actions.GET_BOOK_A_LAWYER:
      return itemLoading(state, 'bookALawyer')
    case Actions.GET_BOOK_A_LAWYER_LOADED:
      return itemLoaded(state, 'bookALawyer', action)
    case Actions.GET_BOOK_A_LAWYER_ERROR:
      return itemError(state, 'bookALawyer', action)
    case Actions.UPDATE_BOOK_A_LAWYER:
      return itemSaving(state, 'bookALawyer')
    case Actions.UPDATE_BOOK_A_LAWYER_LOADED:
      return itemSaved(state, 'bookALawyer', action)
    case Actions.UPDATE_BOOK_A_LAWYER_ERROR:
      return itemError(state, 'bookALawyer', action)
    case Actions.SET_BOOK_A_LAWYER_APPOINTMENT:
      state.bookALawyerAppointment = {
        ...state.bookALawyerAppointment,
        ...action.data,
      }
      return state
    case Actions.CREATE_ZOOM_AUTH_LINK:
      return itemSaving(state, 'zoomAuthLink')
    case Actions.CREATE_ZOOM_AUTH_LINK_LOADED:
      return itemSaved(state, 'zoomAuthLink', action)
    case Actions.CREATE_ZOOM_AUTH_LINK_ERROR:
      return itemError(state, 'zoomAuthLink', action)
    case Actions.GET_COMPANIES:
      return itemLoading(state, 'companies')
    case Actions.GET_COMPANIES_LOADED:
      return itemLoaded(state, 'companies', action)
    case Actions.GET_COMPANIES_ERROR:
      return itemError(state, 'companies', action)
    case Actions.GET_STRIPE_PRODUCT_DETAILS:
      return itemLoading(state, 'stripeProductDetails')
    case Actions.GET_STRIPE_PRODUCT_DETAILS_LOADED:
      return itemLoaded(state, 'stripeProductDetails', action)
    case Actions.GET_STRIPE_PRODUCT_DETAILS_ERROR:
      return itemError(state, 'stripeProductDetails', action)
    case Actions.GET_STRIPE_CHECKOUT_URL:
      return itemLoading(state, 'stripeCheckoutUrl')
    case Actions.GET_STRIPE_CHECKOUT_URL_LOADED:
      return itemLoaded(state, 'stripeCheckoutUrl', action)
    case Actions.GET_STRIPE_CHECKOUT_URL_ERROR:
      return itemError(state, 'stripeCheckoutUrl', action)
    case Actions.CREATE_ACTION_APPOINTMENT:
      return itemSaving(state, 'actionAppointment')
    case Actions.CREATE_ACTION_APPOINTMENT_LOADED:
      return itemSaved(state, 'actionAppointment', action)
    case Actions.CREATE_ACTION_APPOINTMENT_ERROR:
      return itemError(state, 'actionAppointment', action)
    case Actions.GET_CRONOFY_CONFERENCING_PROFILES:
      return itemLoading(state, 'cronofyConferencingProfiles')
    case Actions.GET_CRONOFY_CONFERENCING_PROFILES_LOADED:
      return itemLoaded(state, 'cronofyConferencingProfiles', action)
    case Actions.GET_CRONOFY_CONFERENCING_PROFILES_ERROR:
      return itemError(state, 'cronofyConferencingProfiles', action)
    case Actions.GET_BOOKING:
      return itemLoading(state, 'booking')
    case Actions.GET_BOOKING_LOADED:
      return itemLoaded(state, 'booking', action)
    case Actions.GET_BOOKING_ERROR:
      return itemError(state, 'booking', action)
    case Actions.GET_LAWYER_MEMBERSHIP_TYPES:
      return itemLoading(state, 'lawyerMembershipTypes')
    case Actions.GET_LAWYER_MEMBERSHIP_TYPES_LOADED:
      return itemLoaded(state, 'lawyerMembershipTypes', action)
    case Actions.GET_LAWYER_MEMBERSHIP_TYPES_ERROR:
      return itemError(state, 'lawyerMembershipTypes', action)
    case Actions.GET_LAWYER_QUALIFICATION_TYPES:
      return itemLoading(state, 'lawyerQualificationTypes')
    case Actions.GET_LAWYER_QUALIFICATION_TYPES_LOADED:
      return itemLoaded(state, 'lawyerQualificationTypes', action)
    case Actions.GET_LAWYER_QUALIFICATION_TYPES_ERROR:
      return itemError(state, 'lawyerQualificationTypes', action)
    case Actions.GET_DETAILED_LAWYER_PROFILE:
      return itemLoading(state, 'detailedLawyerProfile')
    case Actions.GET_DETAILED_LAWYER_PROFILE_LOADED:
      return itemLoaded(state, 'detailedLawyerProfile', action)
    case Actions.GET_DETAILED_LAWYER_PROFILE_ERROR:
      return itemError(state, 'detailedLawyerProfile', action)
    case Actions.GET_BARRISTERS:
      return itemLoading(state, 'barristers')
    case Actions.GET_BARRISTERS_LOADED:
      return itemLoaded(state, 'barristers', action)
    case Actions.GET_BARRISTERS_ERROR:
      return itemError(state, 'barristers', action)
    case Actions.GET_BARRISTER:
      return itemLoading(state, 'barrister')
    case Actions.GET_BARRISTER_LOADED:
      return itemLoaded(state, 'barrister', action)
    case Actions.GET_BARRISTER_ERROR:
      return itemError(state, 'barrister', action)
    case Actions.GET_BARRISTER_HONOURS:
      return itemLoading(state, 'barristerHonours')
    case Actions.GET_BARRISTER_HONOURS_LOADED:
      return itemLoaded(state, 'barristerHonours', action)
    case Actions.GET_BARRISTER_HONOURS_ERROR:
      return itemError(state, 'barristerHonours', action)
    case Actions.GET_BARRISTERS_SEARCH:
      return itemLoading(state, 'barristersSearch')
    case Actions.GET_BARRISTERS_SEARCH_LOADED:
      return itemLoaded(state, 'barristersSearch', action)
    case Actions.GET_BARRISTERS_SEARCH_ERROR:
      return itemError(state, 'barristersSearch', action)
    case Actions.UPDATE_BARRISTER:
      return itemSaving(state, 'barrister')
    case Actions.UPDATE_BARRISTER_LOADED:
      return itemSaved(state, 'barrister', action)
    case Actions.UPDATE_BARRISTER_ERROR:
      return itemError(state, 'barrister', action)
    case Actions.CREATE_LAWYERS_PROFILE:
      return itemSaving(state, 'lawyersProfile')
    case Actions.CREATE_LAWYERS_PROFILE_LOADED:
      return itemSaved(state, 'lawyersProfile', action)
    case Actions.CREATE_LAWYERS_PROFILE_ERROR:
      return itemError(state, 'lawyersProfile', action)
    case Actions.GET_ORGANISATION_BY_SRA:
      return itemLoading(state, 'organisationBySra')
    case Actions.GET_ORGANISATION_BY_SRA_LOADED:
      return itemLoaded(state, 'organisationBySra', action)
    case Actions.GET_ORGANISATION_BY_SRA_ERROR:
      return itemError(state, 'organisationBySra', action)
    case Actions.CREATE_UPLOAD_AVATAR:
      return itemSaving(state, 'uploadAvatar')
    case Actions.CREATE_UPLOAD_AVATAR_LOADED:
      return itemSaved(state, 'uploadAvatar', action)
    case Actions.CREATE_UPLOAD_AVATAR_ERROR:
      return itemError(state, 'uploadAvatar', action)
    case Actions.CREATE_UPLOAD_USER_AVATAR:
      return itemSaving(state, 'uploadUserAvatar')
    case Actions.CREATE_UPLOAD_USER_AVATAR_LOADED:
      return itemSaved(state, 'uploadUserAvatar', action)
    case Actions.CREATE_UPLOAD_USER_AVATAR_ERROR:
      return itemError(state, 'uploadUserAvatar', action)
    case Actions.UPDATE_LAWYERS_PROFILE:
      return itemSaving(state, 'lawyersProfile')
    case Actions.UPDATE_LAWYERS_PROFILE_LOADED:
      return itemSaved(state, 'lawyersProfile', action)
    case Actions.UPDATE_LAWYERS_PROFILE_ERROR:
      return itemError(state, 'lawyersProfile', action)
    case Actions.GET_ORGANISATION:
      return itemLoading(state, 'organisation')
    case Actions.GET_ORGANISATION_LOADED:
      action.index = action.data.id
      return itemLoaded(state, 'organisation', action)
    case Actions.GET_ORGANISATION_ERROR:
      return itemError(state, 'organisation', action)
    case Actions.CREATE_REPORT_BARRISTER:
      return itemSaving(state, 'reportBarrister')
    case Actions.CREATE_REPORT_BARRISTER_LOADED:
      return itemSaved(state, 'reportBarrister', action)
    case Actions.CREATE_REPORT_BARRISTER_ERROR:
      return itemError(state, 'reportBarrister', action)
    case Actions.CREATE_REPORT_ORGANISATION:
      return itemSaving(state, 'reportOrganisation')
    case Actions.CREATE_REPORT_ORGANISATION_LOADED:
      return itemSaved(state, 'reportOrganisation', action)
    case Actions.CREATE_REPORT_ORGANISATION_ERROR:
      return itemError(state, 'reportOrganisation', action)
    case Actions.UPDATE_BOOKING:
      return itemSaving(state, 'booking')
    case Actions.UPDATE_BOOKING_LOADED:
      return itemSaved(state, 'booking', action)
    case Actions.UPDATE_BOOKING_ERROR:
      return itemError(state, 'booking', action)
    case Actions.GET_STRIPE_SUBSCRIPTIONS:
      return itemLoading(state, 'stripeSubscriptions')
    case Actions.GET_STRIPE_SUBSCRIPTIONS_LOADED:
      return itemLoaded(state, 'stripeSubscriptions', action)
    case Actions.GET_STRIPE_SUBSCRIPTIONS_ERROR:
      return itemError(state, 'stripeSubscriptions', action)
    case Actions.CREATE_STRIPE_CHECKOUT:
      return itemSaving(state, 'stripeCheckout')
    case Actions.CREATE_STRIPE_CHECKOUT_LOADED:
      return itemSaved(state, 'stripeCheckout', action)
    case Actions.CREATE_STRIPE_CHECKOUT_ERROR:
      return itemError(state, 'stripeCheckout', action)
    case Actions.DELETE_SUBSCRIPTION:
      return itemSaving(state, 'subscription')
    case Actions.DELETE_SUBSCRIPTION_LOADED:
      return itemSaved(state, 'subscription', action)
    case Actions.DELETE_SUBSCRIPTION_ERROR:
      return itemError(state, 'subscription', action)
    case Actions.CREATE_QUALIFICATION:
      return itemSaving(state, 'qualification')
    case Actions.CREATE_QUALIFICATION_LOADED:
      return itemSaved(state, 'qualification', action)
    case Actions.CREATE_QUALIFICATION_ERROR:
      return itemError(state, 'qualification', action)
    case Actions.CREATE_MEMBERSHIP:
      return itemSaving(state, 'membership')
    case Actions.CREATE_MEMBERSHIP_LOADED:
      return itemSaved(state, 'membership', action)
    case Actions.CREATE_MEMBERSHIP_ERROR:
      return itemError(state, 'membership', action)
    case Actions.create_area:
      return itemSaving(state, 'area')
    case Actions.create_area_LOADED:
      return itemSaved(state, 'area', action)
    case Actions.create_area_ERROR:
      return itemError(state, 'area', action)
    case Actions.CREATE_FAVOURITE:
      return itemSaving(state, 'favourites')
    case Actions.CREATE_FAVOURITE_LOADED: {
      const type = action.data.barrister
        ? 'barristers'
        : action.data.lawyer
        ? 'lawyerProfiles'
        : 'offices'

      const profile = state[type].results
      const newArray = profile?.map((item) => {
        if (
          item.id === action.data.lawyer ||
          item.id === action.data.barrister ||
          item.id === action.data.office
        ) {
          item.favourite_id = action.data.id
        }
        return item
      })
      const newState = {
        ...state,
        [type]: { ...state[type], results: newArray },
      }
      return itemSaved(newState, 'favourites', action)
    }

    case Actions.CREATE_FAVOURITE_ERROR:
      return itemError(state, 'favourites', action)

    case Actions.DELETE_FAVOURITE:
      return itemSaving(state, 'favourites')
    case Actions.DELETE_FAVOURITE_LOADED: {
      const itemType = action.originalAction?.data?.type
      const type = Utils.getProfileTypeStoreKey(itemType)
      const profile = state[type].results
      const newArray = profile.map((item) => {
        if (item.favourite_id === action.originalAction.data.id) {
          item.favourite_id = null
        }
        return item
      })
      const newState = {
        ...state,
        [type]: { ...state[type], results: newArray },
      }
      return newState
    }
    case Actions.DELETE_FAVOURITE_ERROR:
      return itemError(state, 'favourites', action)

    case Actions.GET_PROFILE_DETAIL:
      return itemLoading(state, 'profileDetail')
    case Actions.GET_PROFILE_DETAIL_LOADED:
      return itemLoaded(state, 'profileDetail', action)
    case Actions.GET_PROFILE_DETAIL_ERROR:
      return itemError(state, 'profileDetail', action)

    case Actions.CREATE_RESPONSE_TO_REVIEW:
      return itemSaving(state, 'responseToReview')
    case Actions.CREATE_RESPONSE_TO_REVIEW_LOADED:
      return itemSaved(state, 'responseToReview', action)
    case Actions.CREATE_RESPONSE_TO_REVIEW_ERROR:
      return itemError(state, 'responseToReview', action)
    case Actions.CREATE_LEADERSHIP_ARTICLE:
      return itemSaving(state, 'leadershipArticle')
    case Actions.CREATE_LEADERSHIP_ARTICLE_LOADED:
      return itemSaved(state, 'leadershipArticle', action)
    case Actions.CREATE_LEADERSHIP_ARTICLE_ERROR:
      return itemError(state, 'leadershipArticle', action)
    case Actions.CREATE_APPOINTMENT_BOOKING:
      return itemSaving(state, 'appointmentBooking')
    case Actions.CREATE_APPOINTMENT_BOOKING_LOADED:
      return itemSaved(state, 'appointmentBooking', action)
    case Actions.CREATE_APPOINTMENT_BOOKING_ERROR:
      return itemError(state, 'appointmentBooking', action)
    case Actions.CREATE_REVIEW:
      return itemSaving(state, 'review')
    case Actions.CREATE_REVIEW_LOADED:
      return itemSaved(state, 'review', action)
    case Actions.CREATE_REVIEW_ERROR:
      return itemError(state, 'review', action)
    case Actions.GET_DETAILED_LAWFIRM_PROFILE:
      return itemLoading(state, 'detailedLawfirmProfile')
    case Actions.GET_DETAILED_LAWFIRM_PROFILE_LOADED:
      return itemLoaded(state, 'detailedLawfirmProfile', action)
    case Actions.GET_DETAILED_LAWFIRM_PROFILE_ERROR:
      return itemError(state, 'detailedLawfirmProfile', action)
    case Actions.GET_DETAILED_BARRISTER_PROFILE:
      return itemLoading(state, 'detailedBarristerProfile')
    case Actions.GET_DETAILED_BARRISTER_PROFILE_LOADED:
      return itemLoaded(state, 'detailedBarristerProfile', action)
    case Actions.GET_DETAILED_BARRISTER_PROFILE_ERROR:
      return itemError(state, 'detailedBarristerProfile', action)
    case Actions.CREATE_BOOKING_ACTION:
      return itemSaving(state, 'bookingAction')
    case Actions.CREATE_BOOKING_ACTION_LOADED:
      return itemSaved(state, 'bookingAction', action)
    case Actions.CREATE_BOOKING_ACTION_ERROR:
      return itemError(state, 'bookingAction', action)

    case Actions.GET_LAWYER_BOOKINGS:
      return itemLoading(state, 'lawyerBookings')
    case Actions.GET_LAWYER_BOOKINGS_LOADED:
      return itemLoaded(state, 'lawyerBookings', action)
    case Actions.GET_LAWYER_BOOKINGS_ERROR:
      return itemError(state, 'lawyerBookings', action)
    case Actions.GET_BARRISTER_BOOKINGS:
      return itemLoading(state, 'barristerBookings')
    case Actions.GET_BARRISTER_BOOKINGS_LOADED:
      return itemLoaded(state, 'barristerBookings', action)
    case Actions.GET_BARRISTER_BOOKINGS_ERROR:
      return itemError(state, 'barristerBookings', action)
    case Actions.GET_OCCUPATIONS:
      return itemLoading(state, 'occupations')
    case Actions.GET_OCCUPATIONS_LOADED:
      return itemLoaded(state, 'occupations', action)
    case Actions.GET_OCCUPATIONS_ERROR:
      return itemError(state, 'occupations', action)
    case Actions.GET_LEGAL_PAGES:
      return itemLoading(state, 'legalPages')
    case Actions.GET_LEGAL_PAGES_LOADED:
      return itemLoaded(state, 'legalPages', action)
    case Actions.GET_LEGAL_PAGES_ERROR:
      return itemError(state, 'legalPages', action)

    // END OF REDUCER
    // KEEP THE ABOVE LINE IN, IT IS USED BY OUR CLI
  }
})

export default defaultReducer
