import { brands } from 'assets/brands'
import React from 'react'

const PioneerSection = () => {
  return (
    <section className='text-center my-[100px]'>
      <h1 className='md:text-[40px] text-[#001A3D] font-bold'>
        We have been a recognised{' '}
        <span className='text-[#65C2D7]'>pioneer</span>.
      </h1>
      <p>
        Selected to deliver on our mission and share our vision around the
        world.{' '}
      </p>
      <div className='flex flex-wrap justify-center gap-10 md:gap-20 my-10'>
        <img src={brands.barclays} className='h-[50px]' alt='' />
        <img src={brands.santander} alt='' className='h-[50px]' />
        <img src={brands.lawtech_panel} alt='' className='h-[50px]' />
        <img src={brands.london} alt='' className='h-[50px]' />
        <img src={brands.wayra} alt='' className='h-[50px]' />
      </div>
    </section>
  )
}

export default PioneerSection
