import ContentLayout from 'components/layouts/ContentLayout'
import useLegalPages from 'common/providers/useLegalPages'
import { useEffect, useState } from 'react'
import { Button } from 'components/forms/Buttons'
import { BsCaretLeftFill, BsCaretRightFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

const LegalPages = () => {
  const { legalPages, getLegalPages, legalPagesLoading } = useLegalPages()
  const [currentPage, setCurrentPage] = useState<any>(1)
  const navigate = useNavigate()

  useEffect(() => {
    getLegalPages({ id: '?page=' + currentPage })
  }, [getLegalPages, currentPage])

  const handlePagination = (url: string) => {
    if (url) {
      const query = new URLSearchParams('?' + url.split('?')[1])
      setCurrentPage(query.get('page') || 1)
    }
  }

  return (
    <>
      {!legalPagesLoading && (
        <ContentLayout title={'Legal'}>
          <div className='max-sm:my-[100px]'>
            <h1 className='text-[#001A3D] md:text-[40px] text-[16px] font-bold md:mb-[40px] max-sm:text-center'>
              Our terms, policies, and disclaimers
            </h1>
            <div className=' grid md:grid-cols-3 gap-[26px] justify-between md:my-[50px] my-[20px]'>
              {legalPages &&
                legalPages.results.map((item, idx) => (
                  <div
                    key={idx}
                    className='shadow-[0_.2px_4px_0_rgba(0,0,0,0.3)] p-[20px] rounded-md text-[#25395C] '
                  >
                    <h1 className='md:text-[18px] font-bold text-[14px] pt-[10px]'>
                      {item.title}
                    </h1>
                    <p className='md:text-[14px] text-[12px] mt-[10px]'>
                      {item.summary.substring(0, 150) + '...'}
                    </p>
                    <div className='flex justify-end '>
                      <Button
                        onClick={() =>
                          navigate(
                            item.title
                              .replace(/[{()}]/g, '')
                              .toLowerCase()
                              .replaceAll(' ', '-'),
                            { state: item, replace: true },
                          )
                        }
                        className='btn-default mt-[20px] text-[#1C7D93]'
                      >
                        View
                      </Button>
                    </div>
                  </div>
                ))}
            </div>

            <div className='flex justify-end gap-5'>
              <BsCaretLeftFill
                className={`${
                  !legalPages?.previous ? 'text-text-muted' : 'cursor-pointer'
                }`}
                onClick={() => handlePagination(legalPages.previous)}
                size={32}
              />
              <BsCaretRightFill
                className={`${
                  !legalPages?.next ? 'text-text-muted' : 'cursor-pointer'
                }`}
                onClick={() => handlePagination(legalPages.next)}
                size={32}
              />
            </div>
          </div>
        </ContentLayout>
      )}
    </>
  )
}
export default LegalPages
