import React from 'react'

export const Play = () => (
  <svg
    width='49'
    height='49'
    viewBox='0 0 49 49'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Exception / circle button'>
      <g id='Ellipse 1' filter='url(#filter0_d_13518_120242)'>
        <circle cx='24.5' cy='24' r='20' fill='#F4F4F4' />
        <circle
          cx='24.5'
          cy='24'
          r='19.8'
          stroke='#A0ABBE'
          stroke-width='0.4'
        />
      </g>
      <g id='16px / arrow'>
        <g id='Vector' filter='url(#filter1_d_13518_120242)'>
          <path
            d='M21.5 29.9194C21.5 30.7579 22.4699 31.2241 23.1247 30.7002L30.5239 24.7809C31.0243 24.3805 31.0243 23.6195 30.5239 23.2191L23.1247 17.2998C22.4699 16.7759 21.5 17.2421 21.5 18.0806V29.9194Z'
            fill='#00323D'
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id='filter0_d_13518_120242'
        x='0.5'
        y='0.4'
        width='48'
        height='48'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='0.4' />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_13518_120242'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_13518_120242'
          result='shape'
        />
      </filter>
      <filter
        id='filter1_d_13518_120242'
        x='17.5'
        y='17.0781'
        width='17.3984'
        height='21.8438'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_13518_120242'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_13518_120242'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

export const PlayIcon = () => (
  <button className='flex items-center gap-2 text-sm'>
    <Play />
  </button>
)
