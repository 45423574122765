import React from 'react'
import Image from '../../../components/placeholders/Image'

export const LearnList = ({ data }: any) => {
  return (
    <div className='row p-5'>
      <div className='col-md-9'>
        <h1>{data.title}</h1>
        <p>{data.description}</p>
      </div>
      <div className='col-md-3'>
        <Image
          height='195px'
          width='100%'
          display='flex'
          backgroundColor='#001A3D'
          color='#fff'
          padding='40px'
        >
          Image
        </Image>
      </div>
    </div>
  )
}
