import React from 'react'
import PlayIcon from 'assets/play_icon.svg'
import { FiHeart } from 'react-icons/fi'

const AudioPlayer = () => {
  return (
    <div className='controls mt-2'>
      <div className='action flex gap-2 items-center'>
        <FiHeart size={20} /> 2 plays
      </div>
      <div className='ms-4 flex items-center'>
        1 hr, 24 min
        <div className='duration'>
          <div className='timer'></div>
        </div>
      </div>
      <button className='play'>
        <img className='icon' src={PlayIcon} alt='' />
      </button>
    </div>
  )
}

export default AudioPlayer
