import React, { useState } from 'react'
import { data } from '../../../data/footerData'
import { socials } from '../../../assets/socials'
import AppStore from '../../../assets/AppStore/apple-app-store-badge-light.svg'
import { MobileMenuFooter } from './MobileMenu'
import { useNavigate } from 'react-router-dom'
import Newsletter from 'components/popups/Newsletter'
const Footer = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [activeClass, setActiveClass] = useState(0)

  const handleFooterLinks = (nav: any) => {
    if (nav.modal) {
      setIsModalOpen(true)
    } else {
      setIsModalOpen(false)
      if (/(http(s?)):\/\//i.test(nav.url)) {
        window.open(nav.url, '_blank')
        return null
      }

      navigate(nav.url)
    }
  }

  return (
    <footer className='footer'>
      <MobileMenuFooter navigations={data} />
      <div className='footer-upper'>
        {data && (
          <>
            <ul className=''>
              {data[0].map((top, idx) => (
                <li
                  className={`${activeClass === idx && 'active'}`}
                  onMouseEnter={() => setActiveClass(idx)}
                >
                  <a href='#'>{top.name}</a>
                  <ul className=''>
                    {top.subs.map((sub, idx) => (
                      <div key={idx}>
                        {sub.map((inner, idx) => (
                          <li key={idx}>
                            <a
                              className='cursor-pointer'
                              onClick={() => handleFooterLinks(inner)}
                            >
                              {inner.name}
                            </a>
                          </li>
                        ))}
                      </div>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className='line'></div>
      <div className='footer-mid flex justify-between my-20'>
        {data[1].map((inner, first_inner) => (
          <div>
            <h4 className='mb-5'>{inner.name}</h4>
            <div className='flex gap-10'>
              {inner.subs.map((subs, idx) => (
                <ul>
                  {subs.map((sub, idx) => (
                    <li className='mb-2'>
                      <a
                        onClick={() => handleFooterLinks(sub)}
                        className={`cursor-pointer ${
                          first_inner === 0 && idx === 0
                            ? 'footer-text-dark'
                            : ''
                        }`}
                      >
                        {sub.name}
                      </a>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className='line'></div>
      <div
        className='footer-brand row items gap-20 my-20 '
        style={{ height: 'auto' }}
      >
        <div className='col-md-3 flex-column '>
          <a onClick={() => handleFooterLinks('/')}>
            {/* <div
              style={{ backgroundImage: `url(${brands.logo})` }}
              className='z-10   absolute top-0 left-0   bg-no-repeat'
            /> */}
            <span>Legal Utopia</span>
          </a>
          <h1 className='h1' style={{ fontSize: '50px', lineHeight: '50px' }}>
            Law is for everyone
          </h1>
          <div className='img flex gap-5 mt-5'>
            <img src={socials.light.linkedin} alt='' />
            <img src={socials.light.twitter} alt='' />
            <img src={socials.light.mail} alt='' />
          </div>
        </div>
        <div className='col-md-2'>
          <h3 className='mb-2' style={{ fontSize: '32px', lineHeight: '50px' }}>
            Get the app today
          </h3>
          <img
            className='img'
            style={{ width: '100px' }}
            src={AppStore}
            alt=''
          />
        </div>
      </div>
      <div className='footer-bottom'>
        <p>
          Legal Utopia, Legal Utopia - The A.I Way and LegalCrowd are the
          trademarks and trading names of Legal Utopia Limited, a company
          registered in England and Wales under company number 10909418
          operating from and registered address Legal Utopia Limited 86-90 Paul
          Street London EC2A 4NE. (C) Legal Utopia Limited 2019-2022. Legal
          Utopia is patent-pending in the United Kingdom, United States of
          America and Singapore. All Rights Reserved. Google Play and the Google
          Play logo are trademarks of Google LLC. iPhone and Apple are
          trademarks of Apple Inc., registered in the U.S. and other countries.
          Apple App Store logos are trademarks of Apple Inc., registered in the
          U.S. and other countries. Legal Utopia Limited is registered for VAT
          under registration number: GB 404 391 911.
        </p>
      </div>
      <Newsletter open={isModalOpen} closeModal={() => setIsModalOpen(false)} />
    </footer>
  )
}

export default Footer
