import React from 'react'
import { icons } from 'assets/icons'
import { socials } from 'assets/socials'
import { Button } from 'components/forms/Buttons'
import './index.scss'

type Props = {
  data: any
}

const PublicProfileCard = ({ data }: Props) => {
  return (
    <div className={`card relative w-[306px]`}>
      <div
        className={`flex h-60  justify-between items-start bg-${data.type}-gradient p-3`}
      >
        <div className='flex flex-col gap-5'>
          <img
            src={socials.light.linkedin}
            className='h-[24px] w-[24px]'
            alt=''
          />
          <img src={socials.light.mail} className='h-[24px] w-[24px]' alt='' />
        </div>

        <div className='mt-3 text-center'>
          <h1 className='md:text-[18px] font-bold'>Name + Surname</h1>
          <p className='md:text-[14px] mb-6'>10y of experience</p>
          <div
            className={`relative flex mx-auto h-[40px] w-[40px] bg-gray-100 rounded-full border-${data.type}`}
          >
            <h1 className='m-auto transform rotate-[52deg]'>SG</h1>
          </div>
        </div>
        <div className='flex gap-5 flex-col justify-between items-center h-full'>
          <div className='flex flex-col items-center gap-5'>
            <img
              src={icons.Heart}
              // isFavorited={isFavorited}
              className='h-[24px] w-[24px] cursor-pointer'
              alt=''
            />
          </div>
        </div>
      </div>
      <div className='p-3 mt-[-100px] mb-[70px]'>
        <p className='text-[14px] text-text-muted '>
          Short bio: lorem ipsum lorem ispum lorem ispum lorem ispum lorem ispum
          lorem ip ispum lorem ispum lorem ispum lorem ispum lorem ispum lorem
          ispum lorem ispum lorem ispum lorem.
        </p>
        <h1 className='mt-4 mb-3'>Areas of law</h1>
        <div className='flex gap-3'>
          <div className='badge bg-[#EBF3FF]'>Legal area</div>
          <div className='badge bg-[#EBF3FF]'>Legal area 2</div>
          <div className='badge bg-[#EBF3FF]'>Legal area 3</div>
        </div>
      </div>
      <div className={`absolute bottom-0 left-0 right-0 p-4 bg-${data.type}`}>
        <div className='flex justify-between items-center'>
          <div className='text-[14px] text-white'>
            <div className='flex gap-1'>
              <p>From £40.00 </p>
              <p className='mt-[-7px]'> p/hr</p>
            </div>
            <p>Estimated cost</p>
          </div>
          <Button type='submit' className='btn-default text-light text-[12px]'>
            View
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PublicProfileCard
