export const background = {
  discoverLaw: {
    _01: require('./discover-law.png'),
    _02: require('./discover-law-1.png'),
    _03: require('./discover-law-2.png'),
    _04: require('./discover-law-4.png'),
    _05: require('./discover-law-5.png'),
    _06: require('./discover-law-6.png'),
  },
  Home: {
    _01: require('./home-curve.png'),
    _02: require('./home-curve-2.png'),
    _03: require('./home-curve-3.png'),
    _04: require('./home-curve-4.png'),
  },
  WhoWeAre: {
    Hero: require('./who-we-are.png'),
    Tree: require('./who-we-are-tree.png'),
    LawTech: require('./lawtech.png'),
    CurveLeft: require('./who-we-are-lawtech-l.png'),
    CurveRight: require('./who-we-are-lawtech-r.png'),
    Spiral: require('./who-we-are-spiral.png'),
  },
  LearnMore: {
    _01: require('./learn-more-vector.png'),
    _02: require('./learn-more-vector-2.png'),
  },
  Podcast: {
    Hero: require('./podcast-hero.png'),
  },
  LegalField: {
    Hero: require('./legal-field-hero.png'),
    Hero2: require('./legal-field-hero-2.png'),
  },
  Episode: {
    Hero: require('./episode-hero.png'),
  },
  Content: {
    _01: require('./content-bar.png'),
  },
}
