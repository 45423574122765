import React from 'react'

type Props = {
  title: any
  color: string
  className?: string
}

const Badge = ({ className, title, color }: Props) => {
  return (
    <div className={`badge ${className}`} style={{ backgroundColor: color }}>
      {title}
    </div>
  )
}

export default Badge
