export const data = [
  {
    name: 'Premium Doc',
    amount: 24.99,
    currency: [
      {
        id: 1,
        name: 'Pounds',
        symbol: '£',
        rate: 0.5,
      },
    ],
    price_id: 'price_1Q8jsxQB5v2eqsuxlV1SfqcK',
    mode: 'payment',
    caption: `A Premium document is a moderately complex legal document. It may be a contract, policy, or correspondence  template.`,
    features: [
      '1x Premium Document',
      'Download and create',
      'Available 24/7',
      'Secure, centralised data',
      "Auto-save and store in the user's document library",
      'Collaborate and access guidance to get things right first-time',
      'Download and print your document',
      'Identify the document you need yourself',
      'Access DIY legal drafting guidance',
      'DocuSign for e-signature, and email confirmation',
      'Access DIY legal drafting guidance',
      'Draft & Customise via questionnaire',
      'Collaborate on drafting questionnaire.',
      'Access document library for purchase and document history',
      'Access to Q&A legal support',
      'Access to DocReview via Book a Lawyer (separate fee)',
      'One fixed payment',
      'Technical support',
      'Customer service support',
      'Companies House integration',
    ],
  },
  {
    name: 'Premium+ Doc',
    amount: 44.99,
    currency: [
      {
        id: 1,
        name: 'Pounds',
        symbol: '£',
        rate: 0.5,
      },
    ],
    price_id: 'price_1Q8juYQB5v2eqsuxADssauRn',
    mode: 'payment',
    caption: `A Premium document is a moderately complex legal document. It may be a contract, policy, or correspondence  template.`,
    features: [
      '1x Premium Document',
      'Download and create',
      'Available 24/7',
      'Secure, centralised data',
      "Auto-save and store in the user's document library",
      'Collaborate and access guidance to get things right first-time',
      'Download and print your document',
      'Identify the document you need yourself',
      'Access DIY legal drafting guidance',
      'DocuSign for e-signature, and email confirmation',
      'Access DIY legal drafting guidance',
      'Draft & Customise via questionnaire',
      'Collaborate on drafting questionnaire.',
      'Access document library for purchase and document history',
      'Access to Q&A legal support',
      'Access to DocReview via Book a Lawyer (separate fee)',
      'One fixed payment',
      'Technical support',
      'Customer service support',
      'Companies House integration',
    ],
  },
  {
    name: 'Specialist Doc',
    amount: 175,
    currency: [
      {
        id: 1,
        name: 'Pounds',
        symbol: '£',
        rate: 0.5,
      },
    ],
    price_id: 'price_1Q8jw2QB5v2eqsuxjL5L3N92',
    mode: 'subscription',
    caption: `A Premium document is a moderately complex legal document. It may be a contract, policy, or correspondence  template.`,
    features: [
      '1x Premium Document',
      'Download and create',
      'Available 24/7',
      'Secure, centralised data',
      "Auto-save and store in the user's document library",
      'Collaborate and access guidance to get things right first-time',
      'Download and print your document',
      'Identify the document you need yourself',
      'Access DIY legal drafting guidance',
      'DocuSign for e-signature, and email confirmation',
      'Access DIY legal drafting guidance',
      'Draft & Customise via questionnaire',
      'Collaborate on drafting questionnaire.',
      'Access document library for purchase and document history',
      'Access to Q&A legal support',
      'Access to DocReview via Book a Lawyer (separate fee)',
      'One fixed payment',
      'Technical support',
      'Customer service support',
      'Companies House integration',
    ],
  },
  {
    name: 'Pro+ Access',
    amount: 149.99,
    currency: [
      {
        id: 1,
        name: 'Pounds',
        symbol: '£',
        rate: 0.5,
      },
    ],
    price_id: 'price_1Q8jxcQB5v2eqsuxOccSNFra',
    mode: 'subscription',
    caption: `A Premium document is a moderately complex legal document. It may be a contract, policy, or correspondence  template.`,
    features: [
      '1x Premium Document',
      'Download and create',
      'Available 24/7',
      'Secure, centralised data',
      "Auto-save and store in the user's document library",
      'Collaborate and access guidance to get things right first-time',
      'Download and print your document',
      'Identify the document you need yourself',
      'Access DIY legal drafting guidance',
      'DocuSign for e-signature, and email confirmation',
      'Access DIY legal drafting guidance',
      'Draft & Customise via questionnaire',
      'Collaborate on drafting questionnaire.',
      'Access document library for purchase and document history',
      'Access to Q&A legal support',
      'Access to DocReview via Book a Lawyer (separate fee)',
      'One fixed payment',
      'Technical support',
      'Customer service support',
      'Companies House integration',
    ],
  },
]
