import { Modal } from '@mui/material'
import { Button, InputField } from 'components/forms/'
import React from 'react'
import { BsXLg } from 'react-icons/bs'

const Newsletter = ({ open, closeModal }) => {
  return (
    <>
      <Modal
        className=' !bg-[rgba(234, 236, 240, 0.50)] backdrop-blur-[12.5px]'
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            closeModal()
          }
        }}
      >
        <>
          <button
            onClick={closeModal}
            className='absolute right-10 text-white top-10 '
          >
            <BsXLg size={18} fontWeight={700} stroke='bold' />
          </button>
          <div className='modal-dialog'>
            <div className='modal-header w-100'>
              <div className='flex justify-between'>
                <div></div>
                <BsXLg onClick={() => closeModal()} className='pointer' />
              </div>
            </div>
            <div className='modal-content p-5'>
              <h1
                className='fw-bold text-center text-success'
                style={{ fontSize: '24px', letterSpacing: '0.4px' }}
              >
                Stay updated with <br /> our latest
                <span className='bg-warning p-1 ms-2 br-1'>news</span>
              </h1>
              <div className='my-3 text-center'>
                <InputField className='mb-5' label='Email address' required />
                <input type='checkbox' name='' id='' /> I agree to the{' '}
                <a href='' className='text-light'>
                  Privacy policy
                </a>{' '}
                and{' '}
                <a href='' className='text-light'>
                  Marketing policy
                </a>
              </div>
              <div className='flex justify-end'>
                <Button className='btn-primary'>Send</Button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  )
}

export default Newsletter
