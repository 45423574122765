import React, { useRef } from 'react'
import InterviewCardLarge from './InterviewCardLarge'
import interview1 from '../../../assets/Interview_1.png'
import interview2 from '../../../assets/Interview_2.png'
import interview3 from '../../../assets/Interview_3.png'
import { InterviewCard } from '../Discover Law/InterviewSection'
import TopSpeakers from './TopSpeakers'
import SearchByLaw from './SearchByLaw'
import Sectors from './Sectors'
import VideoPlayer from '../../Multimedia/VideoPlayer'
import PublishedCategories from './PublishedCategories'
import NewsletterSignup from './Newsletter'
import downloadIcon from '../../../assets/download_icon.svg'
import { Button } from '../../../../../common/components/Authentication/Button'
import { SearchAndFilter } from '../../search/SearchAndFilter'

const SvgIcon = ({ src, alt, ...props }) => (
  <img src={src} alt={alt} {...props} />
)

const InterviewPage = () => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const interviews = [
    {
      id: 1,
      image: interview1,
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      duration: '1 hr, 24 min',
    },
    {
      id: 1,
      image: interview2,
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      duration: '1 hr, 24 min',
    },
    {
      id: 3,
      image: interview3,
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      duration: '1 hr, 24 min',
    },
  ]

  const handleSearch = (searchTerm: string) => {
    console.log('Searching for:', searchTerm)
    // Implement search functionality
  }

  const handleFilter = (filterOption: string) => {
    console.log('Filtering by:', filterOption)
    // Implement filter functionality
  }

  const handleClearFilters = () => {
    console.log('Clearing filters')
    // Implement clear filters functionality
  }

  return (
    <div className={'bg-gradient-to-t from-white to-[#EBF3FF]'}>
      <div className='flex flex-col px-4 py-8 mx-[5%] sm:pt-[10vh]'>
        <div className='text-xl sm:text-4xl font-bold tracking-[0.4px] mb-[4vh] sm:my-16 sm:my-[16vh]'>
          Most viewed
        </div>
        <div className='flex flex-col sm:flex-row gap-4 sm:mb-8'>
          {interviews.slice(0, 3).map((interview) => (
            <InterviewCardLarge key={interview.id} {...interview} />
          ))}
        </div>

        <div className='text-xl sm:text-4xl font-bold tracking-[0.4px] mb-[4vh] my-16 sm:my-[16vh]'>
          Latest
        </div>
        <div className='flex flex-col gap-4 mb-8 mx-[3vw]'>
          {[1, 2].map((interview) => (
            <InterviewCard
              key={interview}
              author={{
                name: 'Martin Manson',
                publishDate: '25/05/2024',
              }}
              title='Title'
              description='Lorem ipsum dolor sit amet...'
              duration='1 hr, 24 min'
              tags={['Conveyancing', 'Legal long title', 'Conveyancing']}
              plays={20}
            />
          ))}
        </div>

        <div className='mb-14 sm:mb-[20vh] mt-[6%]'>
          <div className='flex items-end text-end justify-end space-x-4 ml-[30vw] sm:ml-0'>
            <Button variant='quaternary' className='text-sm'>
              <SvgIcon
                src={downloadIcon}
                alt='Download'
                className='w-4 h-4 mr-2'
              />
              Download transcript
            </Button>
          </div>
          <VideoPlayer
            ref={videoRef}
            src='https://www.youtube.com/embed/dHqg2urvK1s?enablejsapi=1'
            isYouTube
            title='Interview with John Doe'
            interviewee='John Doe'
          />
        </div>

        <div className='flex flex-col md:flex-row gap-1 sm:gap-12'>
          <div className='w-full md:w-[70%]'>
            <div className='my-[2%]'>
              <div className='text-xl sm:text-4xl font-bold tracking-[0.4px] mb-[4vh] sm:my-[16vh]'>
                Life through the legal lens
              </div>
              <SearchAndFilter
                onSearch={handleSearch}
                onFilter={handleFilter}
                onClearFilters={handleClearFilters}
              />
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4'>
              {interviews.slice(0, 6).map((interview) => (
                <InterviewCardLarge key={interview.id} {...interview} />
              ))}
            </div>
            <div className='flex justify-center my-6 sm:my-12'>
              <div className='flex items-start gap-[35px]'>
                <button>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='37'
                    viewBox='0 0 32 37'
                    fill='none'
                  >
                    <g filter='url(#filter0_d_6562_57468)'>
                      <path
                        d='M25.5859 5.1861C25.5859 3.49042 23.6082 2.56411 22.3056 3.64966L9.42967 14.3796C8.47016 15.1792 8.47016 16.6529 9.42967 17.4525L22.3056 28.1824C23.6082 29.2679 25.5859 28.3416 25.5859 26.6459L25.5859 5.1861Z'
                        fill='#37A2BA'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_d_6562_57468'
                        x='4.70996'
                        y='3.18213'
                        width='24.876'
                        height='33.4678'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                      >
                        <feFlood
                          flood-opacity='0'
                          result='BackgroundImageFix'
                        />
                        <feColorMatrix
                          in='SourceAlpha'
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                          result='hardAlpha'
                        />
                        <feOffset dy='4' />
                        <feGaussianBlur stdDeviation='2' />
                        <feComposite in2='hardAlpha' operator='out' />
                        <feColorMatrix
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
                        />
                        <feBlend
                          mode='normal'
                          in2='BackgroundImageFix'
                          result='effect1_dropShadow_6562_57468'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_dropShadow_6562_57468'
                          result='shape'
                        />
                      </filter>
                    </defs>
                  </svg>
                </button>
                <button>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='37'
                    viewBox='0 0 32 37'
                    fill='none'
                  >
                    <g filter='url(#filter0_d_6562_57467)'>
                      <path
                        d='M7.58594 26.6459C7.58594 28.3416 9.56365 29.2679 10.8663 28.1824L23.7422 17.4525C24.7017 16.6529 24.7017 15.1792 23.7422 14.3796L10.8663 3.64966C9.56366 2.56412 7.58594 3.49042 7.58594 5.1861L7.58594 26.6459Z'
                        fill='#37A2BA'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_d_6562_57467'
                        x='3.58594'
                        y='3.18213'
                        width='24.876'
                        height='33.4678'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                      >
                        <feFlood
                          flood-opacity='0'
                          result='BackgroundImageFix'
                        />
                        <feColorMatrix
                          in='SourceAlpha'
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                          result='hardAlpha'
                        />
                        <feOffset dy='4' />
                        <feGaussianBlur stdDeviation='2' />
                        <feComposite in2='hardAlpha' operator='out' />
                        <feColorMatrix
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
                        />
                        <feBlend
                          mode='normal'
                          in2='BackgroundImageFix'
                          result='effect1_dropShadow_6562_57467'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_dropShadow_6562_57467'
                          result='shape'
                        />
                      </filter>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className='w-full md:w-[30%]'>
            <TopSpeakers />
            <SearchByLaw />
            <Sectors />
            <PublishedCategories />
          </div>
        </div>

        <NewsletterSignup />
      </div>
    </div>
  )
}

export default InterviewPage
