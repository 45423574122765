import { Amplify } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useLoadScript } from '@react-google-maps/api'
import { useNavigate, useParams } from 'react-router-dom'

import Map from '../Map'
import Project from 'common/project'
import { AccountType } from 'common/state-type'
import { LawfirmProfileCard } from './LawfirmProfileCard'
import useProfile from 'common/providers/useProfile'
import useFavourite from 'common/providers/useFavourite'
import useAreasOfPractice from 'common/providers/useAreasOfPractice'
import useDetailedLawfirmProfile from 'common/providers/useDetailedLawfirmProfile'
import { CardMode } from '../barrister/BarristerProfileCard'

const mileToKm = 1.60934 // mile to km

export type position = {
  lat: number
  lng: number
}

Amplify.configure(Project.cognitoWeb)

const LawfirmProfile = () => {
  const navigate = useNavigate()
  const params = Utils.fromParam()
  const { id } = useParams()
  const { getDetailedLawfirmProfile } = useDetailedLawfirmProfile()
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDomcLY-xoazL9KnRBNN9b_oROG_SMO9m8',
  })
  const { profile } = useProfile()
  const { createFavourite, deleteFavourite, favouritesSaving } = useFavourite()
  const { getAreasOfPractice, areasOfPractice } = useAreasOfPractice()

  const [radiusKM] = useState(5 * mileToKm)
  const [profileData, setProfileData] = useState(null)
  const [activeMarker, setActiveMarker] = useState<string | null>(null)
  const [areas] = useState<string[]>(
    params?.field ? params?.field.split(',') : undefined,
  )
  const kilometers = +radiusKM * 1.621371
  const delta = kilometers / 100
  const [region, setRegion] = useState<{
    latitude: number
    longitude: number
    latitudeDelta: number
    longitudeDelta: number
  }>({
    latitude: 51.5072178,
    longitude: -0.1275862,
    latitudeDelta: delta,
    longitudeDelta: delta,
  })

  // Initially setting the center of map to user's geolocation
  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setRegion({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        latitudeDelta: delta,
        longitudeDelta: delta,
      })
    })
  }

  useEffect(() => {
    if (navigator.permissions && navigator.permissions.query) {
      ;(
        navigator.permissions?.query?.({ name: 'geolocation' }) ||
        Promise.resolve()
      ).then(function (result) {
        const permission = result.state
        if (permission === 'granted' || permission === 'prompt') {
          getCurrentLocation()
        }
      })
    } else if (navigator.geolocation) {
      getCurrentLocation()
    }
  }, [])

  useEffect(() => {
    if (!favouritesSaving) {
      getAreasOfPractice({})
      getDetailedLawfirmProfile(
        { id },
        {
          onSuccess: (data) => {
            setProfileData(data)
          },
        },
      )
    }
  }, [id, favouritesSaving])

  const handleFavPress = () => {
    if (profile?.id) {
      if (profileData.favourite_id) {
        const data = {
          id: profileData.favourite_id,
          type: AccountType.BUSINESS,
        }
        deleteFavourite(data)
      } else {
        const type = Utils.getUserType(AccountType.BUSINESS)
        createFavourite({ [type]: profileData.id })
      }
    } else {
      navigate('/login')
    }
  }

  const getAreaNames = (areas: string[]) => {
    return areasOfPractice
      ?.filter((v) => areas?.includes(v.id))
      .map((v) => v.name)
  }

  return (
    <>
      <div className='flex flex-row w-auto'>
        <div className='flex flex-col px-1 relative overfloww-scroll w-[42.29vw] h-[100vh] bg-zinc-100 pb-8'>
          <div id='profile-list' className='py-4'>
            {!!profileData && (
              <LawfirmProfileCard
                mode={CardMode.FAVOURITE}
                onMouseEnter={() => setActiveMarker(profileData.id)}
                latitude={region.latitude}
                longitude={region.longitude}
                data={profileData}
                onFavPress={handleFavPress}
                onBookNow={() =>
                  navigate(`/lawfirms/${profileData.id}/members`)
                }
              />
            )}
          </div>
        </div>

        {isLoaded && !!profileData ? (
          <Map
            setActiveMarker={setActiveMarker}
            activeMarker={activeMarker}
            center={{
              lat: region.latitude,
              lng: region.longitude,
            }}
            lawyers={null}
            barristers={null}
            offices={{
              results: [profileData],
              next: null,
              previous: null,
            }}
          />
        ) : null}
      </div>
    </>
  )
}

export default LawfirmProfile
