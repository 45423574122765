import React from 'react'
import { Button } from '../../../components/forms/Buttons'
import VideoPlayer from 'components/Multimedia/VideoPlayer'
import { useVideoPlayer } from '../../../hooks/useVideoPlayer'
import { BsDownload } from 'react-icons/bs'

export const LifeThroughLegalLensSection = () => {
  const { videoRef } = useVideoPlayer()

  return (
    <section className='mb-12 mt-16'>
      <h2 className='text-2xl font-semibold py-12'>
        Life through the legal lens
      </h2>
      <div className='flex flex-col sm:flex-row gap-12 justify-around'>
        <div className='w-full sm:w-1/2'>
          <div className='flex justify-end items-center gap-3 mb-3'>
            <BsDownload />
            <a className='' href=''>
              Download Transcript
            </a>
          </div>
          <VideoPlayer
            ref={videoRef}
            src='https://www.youtube.com/embed/dHqg2urvK1s?enablejsapi=1'
            isYouTube
            title='Interview with John Doe'
            interviewee='John Doe'
          />
        </div>
        <div className='w-full sm:w-1/3 flex flex-col justify-center mt-8 sm:mt-0'>
          <div>
            <h3 className='text-xl font-semibold mb-10'>Section explanation</h3>
            <p className='text-[#6E7785] mb-4'>
              Here's a wealth of legal knowledge for diving into our extensive
              collection of blogs and articles, authored by top legal
              professionals. Start exploring now to discover valuable insights
              on your legal needs.
            </p>
            <Button className=' btn-default text-light'>Show more</Button>
          </div>
        </div>
      </div>
    </section>
  )
}
