import {
  Box,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { FilterByList, SortByList } from 'data/legalDocsData'

type Props = {
  data?: any
  updateFilteredData: Function
}

const defaultState = {
  search: '',
  filter: FilterByList[0],
  sort: SortByList[0],
}

const FilterOptions = ({ data, updateFilteredData }: Props) => {
  const [state, setState] = useState(defaultState)
  // const [filteredData, setFilteredData] = useState(data)

  const handleInputOnChange = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleSubmit = (e: any) => {
    let filterData = data.filter((filter: any) => {
      return filter.title.toLowerCase().includes(state.search.toLowerCase())
    })
    console.log(filterData)
    updateFilteredData(filterData)
  }

  const handleReset = () => {
    console.log(data)
    updateFilteredData(data)
    setState({ ...defaultState })
  }

  return (
    <>
      <div className='filter  '>
        <h4 className='mt-10 mb-5'>Create, sign and share in minutes</h4>

        <div className='flex flex-column-sm'>
          <div className='components me-0 md:me-4'>
            <FormControl
              sx={{
                width: { xs: '100%', md: '40ch' },
                me: '20px',
                border: 'none',
              }}
              variant='filled'
              className='inputStyle'
            >
              <InputLabel htmlFor='filled-adornment-password'>
                Search
              </InputLabel>
              <FilledInput
                onChange={handleInputOnChange}
                id='filled-adornment-password'
                name='search'
                value={state.search}
                endAdornment={
                  <InputAdornment position='end'>
                    <BsSearch />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className='flex justify-center mt-5 md:mt-0 '>
            <Box
              component='form'
              className='components'
              sx={{
                width: { md: '20ch', xs: '100%' },
                me: '20px',
              }}
              noValidate
              autoComplete='off'
            >
              <TextField
                select
                name='filter'
                defaultValue={state.filter}
                onChange={handleInputOnChange}
                sx={{ width: '95%' }}
              >
                {FilterByList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box
              className='components'
              component='form'
              sx={{ width: { md: '20ch', xs: '100%' } }}
              noValidate
              autoComplete='off'
            >
              <TextField
                select
                defaultValue={state.sort}
                name='sort'
                sx={{ width: '95%' }}
                onChange={handleInputOnChange}
              >
                {SortByList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </div>

          <div className='flex justify-end mt-5 md:mt-0'>
            <button onClick={handleSubmit} className='md:mx-3 components'>
              Apply Filters
            </button>
            <button className='ms-3 components' onClick={handleReset}>
              Clear
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterOptions
