import ZoomIcon from 'assets/FAL/ZoomIcon.svg'
import Img from 'components/base/Img'
import React, { useState } from 'react'
import useProfile from 'common/providers/useProfile'
import { useFormik } from 'formik'
import HeaderBar from './HeaderBar'

const RadioButton = ({ label, ...rest }) => {
  return (
    <div className='flex items-center'>
      <input
        type='radio'
        name='accessibility-radio'
        className='w-5 h-5 text-[#5858DF] bg-white border-[#20BFE7] focus:ring-0'
        {...rest}
      />
      <label className='ml-2 text-sm  text-black'>{label}</label>
    </div>
  )
}

const GuestInput = ({ onChangeEmail }) => {
  return (
    <div className='pt-4 text-base w-[80%]'>
      <label className='block font-bold pt-3'>Email</label>
      <input
        type={'email'}
        className='w-full border border-lawBlue text-sm rounded-md p-2 mt-2 '
        placeholder='JohnSmit@'
        onChange={onChangeEmail}
      />
    </div>
  )
}

interface AddBookingDetailProps {
  getBookingDetail: (values: {
    notes: string
    guests: string[]
    is_video: boolean
    accessibility_requirements: string
  }) => void
  isLoading: boolean
  officeName: string
  fullName: string
  avatar: string
  onClose: () => void
}

const AddBookingDetail: React.FC<AddBookingDetailProps> = ({
  getBookingDetail,
  isLoading,
  avatar,
  fullName,
  onClose,
  officeName,
}) => {
  const { profile, updateProfile } = useProfile()
  const [userPhone, setUserPhone] = useState('')
  const { values, handleChange, handleSubmit, setFieldValue, errors } =
    useFormik({
      initialValues: {
        notes: '',
        guests: [],
        is_video: null,
        accessibility_requirements: null,
      },
      onSubmit: (values) => {
        if (userPhone !== '' && userPhone.length >= 10) {
          updateProfile(
            { phone_number: userPhone },
            {
              onSuccess: () => {
                getBookingDetail(values)
              },
            },
          )
        } else {
          getBookingDetail(values)
        }
      },
    })

  const handleAddGuest = () => {
    if (values.guests?.length < 2) {
      setFieldValue('guests', [...values.guests, { email: '', name: '' }])
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <HeaderBar
        avatar={avatar}
        fullName={fullName}
        officeName={officeName}
        onClose={onClose}
      />
      <div className='px-7 mt-4 text-black'>
        <label className='font-bold text-sm'>Notes</label>
        <textarea
          className='w-full h-32 border border-lue-purple-400 text-sm rounded-md p-2 mt-2 '
          placeholder='Notes'
          name='notes'
          onChange={handleChange}
          value={values.notes}
        />
        {values.guests.map((guest, index) => (
          <GuestInput
            key={index}
            onChangeEmail={(e) => {
              setFieldValue(`guests[${index}].email`, e.target.value)
            }}
          />
        ))}

        {values.guests.length <= 1 && (
          <button
            type='button'
            onClick={handleAddGuest}
            className={`font-medium self-center rounded-lg text-white mt-8 text-lg py-0.5 px-8 bg-lue-purple-400`}
          >
            Add Guest
          </button>
        )}
        <span className='b font-bold text-sm pt-8 block'>
          How do we contact you ?
        </span>
        <div className='flex flex-row place-content-between gap-6 py-4'>
          <div className='flex flex-col'>
            <button
              type='button'
              onClick={() => {
                setFieldValue('is_video', true)
              }}
              className={`font-medium self-center rounded-lg text-white text-lg py-0.5 px-8 bg-lue-purple-400 w-full`}
            >
              Video
            </button>

            {values.is_video && (
              <div className='flex flex-row text-base justify-center place-items-center pt-1'>
                <Img imgSrc={ZoomIcon} className='pr-2' />
                Zoom
              </div>
            )}
          </div>
          <div className='flex flex-col'>
            <button
              type='button'
              onClick={() => {
                setFieldValue('is_video', false)
              }}
              className={`font-medium self-center rounded-lg text-white text-lg py-0.5 px-8 bg-lue-purple-400 w-full`}
            >
              Phone
            </button>
            {values.is_video === false && (
              <div className='flex flex-row justify-center text-sm md:text-base pt-1 text-[#8C959A]'>
                {profile.phone_number ? (
                  profile.phone_number
                ) : (
                  <input
                    className='border bg-lue-purple-400 text-sm md:text-base rounded-lg p-2 mt-2 drop-shadow-xl '
                    type='tel'
                    onChange={(e) => setUserPhone(e.target.value)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <span className='font-bold text-sm'>
          Do you have any accessibility requirements ?
        </span>
        <div className='flex flex-row place-content-between mt-3'>
          <RadioButton
            label='No'
            onClick={() => {
              setFieldValue('accessibility_requirements', null)
            }}
          />
          <RadioButton
            label='Yes (please specify below)'
            onClick={() => {
              setFieldValue('accessibility_requirements', '')
            }}
          />
        </div>

        {values.accessibility_requirements !== null && (
          <textarea
            className='w-full h-32 border border-lawBlue text-sm rounded-md p-2 mt-2 '
            placeholder='Notes'
            name='accessibility_requirements'
            onChange={handleChange}
          />
        )}

        <div className='flex flex-1 items-center justify-center pt-12 pb-6'>
          <button
            type='submit'
            className={`font-medium rounded-lg text-white text-lg py-0.5 px-8 bg-lue-purple-400 self-center`}
            disabled={isLoading}
          >
            {isLoading ? 'Confirming...' : 'Confirm'}
          </button>
        </div>
      </div>
    </form>
  )
}

export default AddBookingDetail
