import { Input } from './Input'
import { InputErrorText } from './InputErrorText'
import { InputWrapper } from './InputWrapper'
import cn from 'classnames'
import { InputHTMLAttributes, ReactElement, useState, useEffect } from 'react'

export type InputGroupProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string
  id: string
  isFirst?: boolean
  className?: string
  imgBefore?: ReactElement | string
  imgAfter?: ReactElement | string
  error?: string
  theme?: 'default' | 'grey'
  isLabelVisible?: boolean
  isNew?: boolean
  multiLine?: number
  hasOverflow?: boolean
}

export const InputGroup = ({
  label,
  id,
  isFirst,
  className,
  imgBefore,
  imgAfter,
  error,
  theme = 'default',
  isLabelVisible,
  isNew,
  hasOverflow,
  multiLine,
  value,
  placeholder,
  ...restProps
}: InputGroupProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const [hasValue, setHasValue] = useState(false)

  useEffect(() => {
    setHasValue(!!value)
  }, [value])

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasValue(e.target.value.length > 0)
    if (restProps.onChange) restProps.onChange(e)
  }

  return (
    <div className={className}>
      {isLabelVisible && !isNew && (
        <label
          className='font-medium mb-1 block text-brand-primary'
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <InputWrapper
        isFirst={isFirst}
        imgBefore={imgBefore}
        imgAfter={imgAfter}
        hasError={!!error}
        theme={theme}
         className={cn(
        isNew &&  "border rounded-lg bg-white transition-all duration-200",
          isFocused   && isNew  ? "border-brand-primary" : "border-gray-300"
        )}
        isNew={isNew}
        hasOverflow={hasOverflow}
      >
        {isNew && isLabelVisible && (
          <label
            className={cn(
              'absolute left-4 transition-all duration-200 pointer-events-none text-xs',
              error
                ? 'text-[#CE2815]'
                : isFocused
                ? 'text-[#B3EBF9]'
                : 'text-[#818285]',

              isFocused || hasValue ? 'top-2' : 'top-2',
            )}
            htmlFor={id}
          >
            {isFocused || hasValue ? label : placeholder || label}
          </label>
        )}
        <Input
          id={id}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          isNew={isNew}
          value={value}
          multiLine={multiLine}
          placeholder={isNew ? '' : placeholder}
          className={cn(
            'transition-all duration-200',
            isNew && isLabelVisible ? 'py-2' : 'py-4',
            imgBefore && !isNew ? 'pl-2 lg:pl-3 pr-5' : 'px-5',
            className,
          )}
          {...restProps}
        />
      </InputWrapper>
      {error && <InputErrorText>{error}</InputErrorText>}
    </div>
  )
}
