import React, { useState } from 'react'
import { Hero, HowItWorks } from './components'
import { Button, InputGroupField } from 'components/forms'
import { glasses, works } from 'data/home.dummy'
import BoxIcon from '../../components/utils/BoxIcon'
import GlassCard from 'components/cards/GlassCard'
import { background } from 'assets/backgrounds'
import { CTA_WhoWeAre } from 'components/cta'
import './index.scss'
import { Newsletter, Feedback } from 'components/popups'

const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <section id='home'>
      <Button
        className='fixed left-[10px] hover:left-[20px] top-[60vh] bg-white  rounded-r-lg shadow-md transform -rotate-90 origin-left z-[1000] p-4 pt-8 '
        onClick={() => setIsModalOpen(true)}
      >
        <span className='text-[#37A2BA] font-semibold '>
          Give us your feedback
        </span>
      </Button>

      <Hero />
      <HowItWorks />

      <div className='flex  justify-center' style={{ marginTop: '120px' }}>
        <div className='col-md-8 text-center'>
          <div className='row items-center text-center mb-3'>
            {works.map((work) => (
              <div className='col-md-4'>
                <BoxIcon
                  title={work.title}
                  icon={work.icon}
                  caption={work.caption}
                  bgColor={work.bgColor}
                  size={20}
                />
              </div>
            ))}
          </div>
          <p>Every Find a Lawyer book comes with complaint support.</p>
          <a href='' className='text-primary text-underline'>
            Learn more about Complaint Cover
          </a>
        </div>
      </div>

      <div
        className='flex justify-center position-relative'
        style={{ marginTop: '250px' }}
      >
        <div
          className='w-full bg-no-repeat bg-cover bg-left py-32 position-absolute'
          style={{
            backgroundImage: `url(${background.Home._01})`,
            backgroundSize: '1284px 590px',
            backgroundAttachment: 'contain',
            top: '100px',
          }}
        />
        <div className='col-md-12 '>
          <div className='flex flex-column-sm justify-around'>
            {glasses.map((glass, idx) => (
              <div className='col-md-5 mb-5'>
                <GlassCard key={idx}>
                  <div className='d-flex my-auto' style={{ height: '100%' }}>
                    <h1>{glass.heading}</h1>
                    <h4 className='my-2'>{glass.title}</h4>
                    <p>{glass.description}</p>
                    <Button className='btn-default my-3 text-primary md-3'>
                      Read
                    </Button>
                  </div>
                </GlassCard>
              </div>
            ))}
          </div>
        </div>
        <div
          className='w-full bg-no-repeat bg-cover bg-right py-32  position-absolute'
          style={{
            backgroundImage: `url(${background.Home._02})`,
            backgroundSize: '860px 296px',
            backgroundAttachment: 'contain',
            bottom: 0,
            zIndex: '-1',
          }}
        />
      </div>

      <div id='documents' className='relative ' style={{ marginTop: '150px' }}>
        <div
          className='w-full bg-no-repeat bg-cover bg-left py-32 '
          style={{
            backgroundImage: `url(${background.Home._03})`,
            backgroundSize: 'auto 100%',
            backgroundAttachment: 'contain',
          }}
        />
        <div
          className='flex justify-center text-center'
          style={{ marginBottom: '300px' }}
        >
          <div className='col-md-6'>
            <h1>Document Dictionary</h1>
            <p className='mb-5'>
              Type in the name of a legal document to get its purpose.
            </p>
            <InputGroupField
              className={'search mx-auto '}
              placeholder='Legal documents name.'
              icon={''}
            />
          </div>
        </div>

        <div className='flex justify-center text-center'>
          <div className='col-md-6'>
            <h1>Law finder</h1>
            <p className='mb-5'>
              Type in an explanation to get help finding the correct area of
              law.
            </p>
            <InputGroupField
              className={'search mx-auto '}
              placeholder='Type in your need.'
              icon={''}
            />
          </div>
        </div>
        <div
          className='w-full bg-no-repeat bg-cover bg-right py-32 '
          style={{
            backgroundImage: `url(${background.Home._04})`,
            backgroundSize: 'auto 100%',
            backgroundAttachment: 'contain',
          }}
        />
      </div>
      <CTA_WhoWeAre />

      <Feedback open={isModalOpen} closeModal={() => setIsModalOpen(false)} />
    </section>
  )
}

export default HomePage
