import { background } from 'assets/backgrounds'
import { brands } from 'assets/brands'
import React from 'react'

const FundedBy = () => {
  return (
    <section className='md:px-[100px] px-2 md:my-[250px] relative'>
      <h1 className='md:ml-10 md:text-[40px] font-bold mb-10 text-[#001A3D]'>
        Funded By
      </h1>

      <div className='flex  md:justify-center justify-around items-center flex-wrap md:gap-20 gap-5'>
        <img
          src={brands.innovate_uk}
          alt=''
          className='w-auto md:h-[80px] h-[45px]'
        />
        <img
          src={brands.europian_union}
          alt=''
          className='w-auto md:h-[80px] h-[45px]'
        />
        <img
          src={brands.university_edinburgh}
          alt=''
          className='w-auto md:h-[80px] h-[80px] md:flex'
        />
        <img
          src={brands.department_of_works}
          alt=''
          className='w-auto md:h-[80px] h-[45px]'
        />
        <img
          src={brands.kent_county_council}
          alt=''
          className='w-auto md:h-[80px] h-[45px]'
        />
      </div>

      <div className='flex flex-col md:w-[60%] md:mt-[900px] mt-[100px] p-3'>
        <img
          className='max-sm:hidden  absolute right-0 md:top-[35%] bottom-[-30%] w-[1100px]'
          src={background.WhoWeAre.Spiral}
          alt=''
        />
        <div className='md:mt-[-15px]  md:ml-36'>
          <h1 className='md:text-[18px] text-[12px] text-[#7B7BDF] font-bold'>
            Delivering Innovation
          </h1>
          <p className='md:text-[36px] text-[14px] max-sm:my-3 font-bold text-[#405985]'>
            We’ve delivered industry research in collaboration with leading
            British institutions.
          </p>
          <p className='md:text-[16px]  text-end text-[12px] pull-right text-[#7B7BDF]'>
            Leading UK advancements in the field of computer science and law.
          </p>
        </div>
        <img
          className='md:hidden md:w-[1100px] mr-[-120px] mt-5'
          src={background.WhoWeAre.Spiral}
          alt=''
        />
      </div>
    </section>
  )
}

export default FundedBy
