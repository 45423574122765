import './index.css'
import './fonts.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'tw-elements'
import './project/polyfill'
import { Link } from 'react-router-dom'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import LanguageHandler from 'common/LanguageHandler'
import createStore from 'common/store'
import { LinkProvider } from 'common/providers/LinkProvider'
import { AuthPageProvider } from 'common/providers/AuthPageProvider'
import vectorBg from './assets/vector-bg.png'
import luLogo from './assets/lu-logo.svg'
import { AccountType } from 'common/state-type'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const store = createStore().store
const persistor = createStore().persistor

const LinkComponent = ({ href, className, children }) => (
  <Link to={href} className={className}>
    {children}
  </Link>
)
const stripePromise = loadStripe(
  'pk_test_51Q7uykQB5v2eqsuxPQxnxteanF3NscPKVtw6FTYK8Y3uWChVBNejrTY3Mt8Ikn5kOYWPdyxIiOUA56XvjEgyTsPG00FrK0N41X',
)

const rootElement = document.getElementById('root')
if (rootElement) {
  rootElement.classList.add('h-full')
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <LanguageHandler>
          <LinkProvider LinkComponent={LinkComponent}>
            <AuthPageProvider
              hasSocialSignIn
              hasNameFields
              hasMarketing
              logoBackLink='/'
              tagline='Find your lawyer'
              backgroundImageSrc={vectorBg}
              logoSrc={luLogo}
              accountType={AccountType.CONSUMER}
            >
              <Elements stripe={stripePromise}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <App />
                </LocalizationProvider>
              </Elements>
            </AuthPageProvider>
          </LinkProvider>
        </LanguageHandler>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  rootElement,
)
