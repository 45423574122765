import React from 'react'
import { DiscoverLegalPerspective, FeaturedArticle } from './components/'
import { articles } from '../../data/ArticlesData'
import { PostCard } from '../../components/cards/PostCard'
import './index.scss'
import { Button } from 'components/forms/Buttons'
import AudioPlayer from 'components/Multimedia/AudioPlayer'
import { PodcastSection } from './components/podcast-section'
import { DiscoverMore } from './components/discover-more-section'
import { LifeThroughLegalLensSection } from './components/life-through-legal-lense'
import Image from 'components/placeholders/Image'
import DiscoverLawFilterSection from './components/filter-section'
import InterviewList from 'components/cards/InterviewList'
import { background } from 'assets/backgrounds'

const DiscoverLaw = () => {
  return (
    <main id='discover-law'>
      <FeaturedArticle />

      <DiscoverLawFilterSection />

      <div className=' py-8 '>
        <DiscoverLegalPerspective />
      </div>

      <div className='w-full relative'>
        <section className='mt-[-20px] w-full h-full '>
          <div className='flex flex-wrap h-min-[100%]'>
            {articles.slice(0, 8).map((blog, idx) => (
              <div className='col-md-3 mb-10'>
                <PostCard data={blog} count={idx} key={idx} />
              </div>
            ))}
          </div>
          {/* <img
            src={background.discoverLaw._03}
            className='absolute z-[-999] h-[514px]   right-0 bottom-0'
            alt=''
          /> */}
        </section>
      </div>
      <div className=' flex justify-center my-20 w-full'>
        <Button className='btn-primary '>Show more</Button>
      </div>

      <section className='relative'>
        <h1 className='text-[30px] mb-10 font-bold'>
          <span className='bg-warning rounded-lg p-3'>Discover Law</span> -
          Podcast
        </h1>
        {/* <img
          src={background.discoverLaw._04}
          alt=''
          className='absolute w-[1207px] z-[-999]  left-0 top-[150px]'
        /> */}

        <div className='rounded-lg py-6 '>
          <img
            src={background.discoverLaw._04}
            alt=''
            className='absolute left-0 mt-[-100px] z-[-3]'
          />
          <div className='flex flex-col lg:flex-row justify-between relative z-10 space-y-6 lg:space-y-0 lg:space-x-6'>
            <div className='lg:w-1/2'>
              <AudioPlayer
                title='Legal Utopia Discover the Law Jodie Hill'
                author='Legal Utopia'
              />
            </div>
            <div className='lg:w-1/2'>
              <Image height='289px' padding='20px' background='#001A3D'>
                <h3 className='font-semibold text-white mb-4'>
                  About this podcast
                </h3>
                <p className='text-sm text-white'>
                  Lorem ipsum lorem ispum lorem ispum lorem ispum lorem ispum
                  lorem ispum lorem ispum lorem ispum lorem ispum lorem ispum
                  lorem ispum lorem ispum lorem ispum lorem ispum lorem ispum
                  lorem ispum lorem ispum lorem ispum lorem ispum lorem ispum
                  lorem ispum lorem ispum lorem ispum lorem ispum lorem ispum
                  lorem ispum lorem ispum lorem ispum lorem ispum lorem ispum
                  lorem ispum lorem ispum lorem ispum lorem ispum lorem ispum
                  lorem ispum lorem ispum lorem ispum lorem ispum lorem ispum
                </p>
              </Image>
            </div>
          </div>
        </div>
      </section>

      <PodcastSection />

      <DiscoverMore />

      <LifeThroughLegalLensSection />

      <section className='md:!px-[200px]'>
        <div className='md:p-[20px] h-[520px] overflow-auto'>
          {Array(3)
            .fill(0)
            .map(() => (
              <div className='mb-4'>
                <InterviewList />
              </div>
            ))}
        </div>
      </section>

      <div className='flex text-center justify-center my-[100px]'>
        <div>
          <p className='text-[#101828] text-[18px] font-bold pb-4 pt-12 text-center'>
            Looking to contribute? Got something to share?
          </p>
          <Button className='btn-primary mt-4 '>Email us</Button>
        </div>
      </div>
    </main>
  )
}

export default DiscoverLaw
