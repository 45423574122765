export const data = [
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Specialist',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Specialist',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Specialist',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Premium',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Premium',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Premium',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Premium+',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Premium+',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Pro+Access',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Pro+Access',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Pro+Access',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Pro+Access',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Pro+Access',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Pro+Access',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Pro+Access',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Pro+Access',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Pro+Access',
  },
  {
    title: 'Key Components of a UK Employer Absence Policy:',
    description:
      'A UK employer absence policy document for the management of employees to outline the company position on employee.',
    category: 'Pro+Access',
  },
]

export const FilterTabList = [
  'All',
  'Specialist',
  'Premium',
  'Premium+',
  'Pro+Access',
]

export const FilterByList = [
  'All templates',
  'Form',
  'Letter',
  'Notice',
  'Template',
  'Policy',
  'English',
  'Welsh',
  'Scottish',
]

export const SortByList = [
  'Name (A-Z)',
  'Name (Z-A)',
  'Newest to oldest',
  'Oldest to newest',
]

export const highlights = [
  {
    name: 'Source',
    value: 'Practical Law Company Limited',
  },
  {
    name: 'Date uploaded',
    value: '20th December 2022',
  },
  {
    name: 'Status',
    value: 'Specialist',
  },
  {
    name: 'Jurisdiction',
    value: 'England & Wales',
  },
  {
    name: 'Governing law',
    value: 'England & Wales',
  },
  {
    name: 'Language',
    value: 'English (UK)',
  },
  {
    name: 'Document type',
    value: 'Register',
  },
]

export const TagList = [
  {
    name: 'Language:',
    tags: ['English', 'Spanish'],
    color: '#D0E2FA',
  },
  {
    name: 'Area of law:',
    tags: ['Conveyancing', 'Conveyancing', 'Conveyancing'],
    color: '#D3D3FF',
  },
  {
    name: 'Business category:',
    tags: ['Conveyancing', 'Conveyancing', 'Conveyancing', 'Conveyancing'],
    color: '#9FCDD7',
  },
  {
    name: 'Industry:',
    tags: [
      'Conveyancing',
      'Conveyancing',
      'Conveyancing',
      'Conveyancing',
      'Conveyancing',
    ],
    color: '#EBF3FF',
  },
]

export const FaqData = [
  {
    title:
      'What is an Assignment of Intellectual Property Rights, and why would an assignor adopt a pro-assignor stance?',
    message:
      'Pro-assignor agreements typically address several important considerations, including the scope and limitations of the assignment, any retained rights or licenses, compensation or royalties, restrictions on use by the assignee, and provisions for dispute resolution.',
  },
  {
    title:
      'What key considerations should be addressed in a pro-assignor Assignment of Intellectual Property Rights?',
    message:
      'Pro-assignor agreements typically address several important considerations, including the scope and limitations of the assignment, any retained rights or licenses, compensation or royalties, restrictions on use by the assignee, and provisions for dispute resolution.',
  },
  {
    title:
      'Can an assignor retain some rights to the intellectual property being assigned?',
    message:
      'Pro-assignor agreements typically address several important considerations, including the scope and limitations of the assignment, any retained rights or licenses, compensation or royalties, restrictions on use by the assignee, and provisions for dispute resolution.',
  },
  {
    title:
      'How does a pro-assignor agreement handle disputes or breaches of the assignment terms?',
    message:
      'Pro-assignor agreements typically address several important considerations, including the scope and limitations of the assignment, any retained rights or licenses, compensation or royalties, restrictions on use by the assignee, and provisions for dispute resolution.',
  },
]
