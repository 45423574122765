import { Box, TextField } from '@mui/material'
import React, { useState } from 'react'
import { BsStar, BsStarFill } from 'react-icons/bs'

const defaultRating = { init: 5, fill: 0 }

const Feedback = () => {
  const [toggle, setToggle] = useState(false)
  const [rating, setRating] = useState(defaultRating)
  const handleFeedback = () => {
    setToggle(true)
  }

  const handleRating = (count: any) => {
    setRating({ ...defaultRating })
    setRating({ fill: count + 1, init: 5 })
  }
  return (
    <div className='feedback mt-10'>
      <div className='flex justify-center mb-4'>
        <h6>How helpful was this page?</h6>
      </div>
      <div className='flex justify-center mb-4'>
        {Array(rating.fill)
          .fill('star')
          .map((num, idx) => (
            <BsStarFill
              onClick={() => handleRating(idx)}
              key={idx}
              size={23}
              className='me-1'
              color='#093269'
            />
          ))}
        {Array(rating.init - rating.fill)
          .fill('star')
          .map((num, idx) => (
            <BsStar
              onClick={() => handleRating(idx)}
              key={idx}
              size={23}
              className='me-1'
              color='#093269'
            />
          ))}
      </div>
      <div className='flex justify-between px-20'>
        <button onClick={handleFeedback}>Not helpful</button>
        <button onClick={handleFeedback}>Very helpful</button>
      </div>
      <Box
        className={`${!toggle ? 'd-none' : ''} mt-5`}
        component={'form'}
        sx={{ width: '100%' }}
      >
        <p className='text-center mb-3'>How was your overall experience?</p>
        <TextField id='outlined-basic' sx={{ width: '100%' }} />
        <div className='flex justify-end'>
          <button className='submit'>Submit</button>
        </div>
      </Box>
    </div>
  )
}

export default Feedback
