import { CSSProperties } from 'react'
import {Button}  from '../../../common/components/Authentication/Button'



interface LegalPolicy {
  _id: string
  title: string
  description: string
}

const legalPolicies: LegalPolicy[] = [
  {
    _id: '1',
    title: 'Cookies (App) Policy.docx',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    _id: '2',
    title: 'Cookies (Site) Policy.docx',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    _id: '3',
    title: 'Disclaimer (Legal Checker AI).docx',
    description:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    _id: '4',
    title: 'Disclaimer (Legal Docs).docx',
    description:
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  },
  {
    _id: '5',
    title: 'Disclaimer.docx',
    description:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    _id: '6',
    title: 'Terms of Sale (Subscription) (Business).docx',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    _id: '7',
    title: 'Terms of Sale (Subscription) (Lawyer).docx',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    _id: '8',
    title: 'Terms of Use (Business).docx',
    description:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    _id: '9',
    title: 'Terms of Use (Consumer).docx',
    description:
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  },
  {
    _id: '10',
    title: 'Terms of Use (Lawyer).docx',
    description:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
]

export function DataSourcePage() {
  const headingStyle: CSSProperties = {
    fontFamily: 'DM Sans',
    letterSpacing: '0.4px',
  }

  const cardStyle: CSSProperties = {
    borderRadius: '16px',
    border: '3px solid var(--Secondary-Blue_1-900, #001A3D)',
    background: 'var(--Grey-scale-White, #FFF)',
    boxShadow:
      '-10px -10px 30px 0px rgba(39, 92, 141, 0.12), 10px 10px 30px 0px rgba(39, 92, 141, 0.12)',
  } as CSSProperties

  const cardStyle1: CSSProperties = {
    borderRadius: '16px',
    border: '3px solid var(--Secondary-Blue_1-900, #001A3D)',
    background: 'var(--Grey-scale-White, #001A3D)',
    boxShadow:
      '-10px -10px 30px 0px rgba(39, 92, 141, 0.12), 10px 10px 30px 0px rgba(39, 92, 141, 0.12)',
  } as CSSProperties

  const titleStyle: CSSProperties = {
    fontFamily: 'DM Sans',
    letterSpacing: '0.4px',
    lineHeight: '24px',
  }

  const descriptionStyle: CSSProperties = {
    fontFamily: 'DM Sans',
    letterSpacing: '0.4px',
    lineHeight: '20px',
  }

  const termsOfUseStyle: CSSProperties = {
    color: 'var(--Secondary-Purple-900, #020251)',
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'DM Sans',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  }

  const termsDescriptionStyle: CSSProperties = {
    color: 'var(--secondary-blue-3500-main, #122D5C)',
    fontFamily: 'DM Sans',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.4px',
  }

  return (
    <div className='relative'>
      <div
        className='py-12'
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='494' viewBox='0 0 1920 494' fill='none'%3E%3Cpath d='M0 0H1920V363.381L0 494V0Z' fill='%23EBF3FF'/%3E%3C/svg%3E")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='container mx-auto px-4'>
          <div className='flex flex-col md:flex-row items-center justify-between mb-8'>
            <div className='flex flex-col w-[100%]'>
              <h2
                className='text-3xl sm:text-4xl md:text-5xl font-bold mb-4 md:mb-0 w-[60%] ml-[10%] translate-y-[-20%]'
                style={termsOfUseStyle}
              >
                Data Source
              </h2>
            </div>

            <div
              className='text-white p-6 rounded-lg sm:w-[100%] md:w-[100%] lg:w-[40%] translate-y-[20%]'
              style={cardStyle1}
            >
              <h3 className='text-xl font-semibold mb-4'>
                Company Information
              </h3>
              <p className='mb-2'>Company Name: Legal Utopia Limited</p>
              <p className='mb-2'>Company No: 10909418</p>
              <p className='mb-2'>Registered Address:</p>
              <p className='mb-4'>
                3rd Floor, 86-90 Paul Street, London, EC2A 4NE
              </p>
              <div className='mt-8'>
                <h4 className='font-semibold mb-2'>Got a question?</h4>
                <p className='mb-1'>Director of Operations</p>
                <p className='mb-1'>Fraser J Matcham</p>
                <p>fraser@legalutopia.co.uk</p>
              </div>
            </div>
            <div className='w-[20%]'></div>
          </div>
        </div>
      </div>
      <div className='container mx-auto px-4 py-8 mt-[8%]'>
        <h2
          className='text-secondary-blue-1-900 text-4xl font-bold mb-12 '
          style={headingStyle}
        >
          Data Sources
        </h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
          {legalPolicies.map((policy) => (
            <div
              key={policy._id}
              className='bg-white shadow-custom rounded-lg p-6'
              style={cardStyle}
            >
              <h3
                className='text-secondary-blue-4500-main text-lg font-bold mb-2'
                style={titleStyle}
              >
                {policy.title}
              </h3>
              <p
                className='text-tones-less-saturation-blue-3400 text-sm mb-4'
                style={descriptionStyle}
              >
                {policy.description}
              </p>
              <div className='flex justify-end'>
                <Button
                  variant='secondary'
                  className='!w-[20%] px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  <a href={`/legal/${policy._id}`}>View</a>
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
