import { useState, useEffect } from 'react'
import app from './firebase'
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  DocumentData,
  QueryConstraint,
} from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

const db = getFirestore(app)
const storage = getStorage(app)

interface FirestoreHookResult<T> {
  data: T | null
  loading: boolean
  error: Error | null
  setData: (data: T) => Promise<void>
}

function useFirestore<T extends DocumentData>(
  collectionName: string,
  docId?: string,
  queryConstraints?: QueryConstraint[],
): FirestoreHookResult<T> {
  const [data, setData] = useState<T | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    async function fetchData() {
      try {
        if (docId) {
          const docRef = doc(db, collectionName, docId)
          const docSnap = await getDoc(docRef)
          if (docSnap.exists()) {
            setData(docSnap.data() as T)
          }
        } else if (queryConstraints) {
          const q = query(collection(db, collectionName), ...queryConstraints)
          const querySnapshot = await getDocs(q)
          const results: T[] = []
          querySnapshot.forEach((doc) => {
            results.push(doc.data() as T)
          })
          setData(results as unknown as T)
        } else {
          const querySnapshot = await getDocs(collection(db, collectionName))
          const results: T[] = []
          querySnapshot.forEach((doc) => {
            results.push(doc.data() as T)
          })
          setData(results as unknown as T)
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error('An error occurred'))
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [collectionName, docId, queryConstraints])

  const setDataToFirestore = async (newData: T) => {
    try {
      if (docId) {
        await setDoc(doc(db, collectionName, docId), newData)
      } else {
        await setDoc(doc(collection(db, collectionName)), newData)
      }
      setData(newData)
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error('An error occurred while setting data'),
      )
    }
  }

  return { data, loading, error, setData: setDataToFirestore }
}

// Function to save user feedback
async function saveFeedback(
  rating: number,
  feedback: string,
  userProfile: { id: string; username: string; email: string } | null,
  file?: File,
): Promise<void> {
  if (!userProfile) {
    throw new Error('User profile is not available. Cannot save feedback.')
  }

  try {
    let fileData = null
    if (file) {
      const downloadURL = await uploadFile(file, userProfile.id)
      fileData = {
        name: file.name,
        url: downloadURL,
      }
    }

    const feedbackData = {
      rating,
      feedback,
      timestamp: new Date().toISOString(),
      userId: userProfile.id,
      username: userProfile.username,
      email: userProfile.email,
      file: fileData,
    }

    // Create a reference to the feedbacks collection
    const feedbacksRef = collection(db, 'feedbacks')

    // Add a new document with a generated id
    const newFeedbackRef = doc(feedbacksRef)
    await setDoc(newFeedbackRef, feedbackData)

    console.log('Feedback saved successfully with ID:', newFeedbackRef.id)
  } catch (error) {
    console.error('Error saving feedback:', error)
    throw error
  }
}

const uploadFile = async (file: File, userId: string): Promise<string> => {
  try {
    const uniqueFileName = `${Date.now()}_${file.name}`
    const storageRef = ref(
      storage,
      `feedback-images/${userId}/${uniqueFileName}`,
    )
    const snapshot = await uploadBytes(storageRef, file)
    const downloadURL = await getDownloadURL(snapshot.ref)
    return downloadURL
  } catch (error) {
    console.error('Error uploading file: ', error)
    throw error
  }
}

export { useFirestore, saveFeedback, uploadFile }
