import React from 'react'

const SearchByLaw: React.FC = () => {
  const lawTags = [
    'Commercial',
    'Employment',
    'Healthcare',
    'Data',
    'Probate',
    'Family',
    'Insurance',
    'Technology',
    'Advice',
    'DocReview',
  ]

  return (
    <div className='mb-8'>
      <h2 className="text-[#001A3D] font-['DM_Sans'] text-xl sm:text-3xl md:text-[32px] font-bold leading-normal mb-6 sm:mb-8 md:mb-12">
        Search by law
      </h2>
      <div className='relative mb-4'>
        <input
          type='text'
          placeholder='Search for a tag'
          className='border rounded-md px-3 py-2 w-full pr-10 text-sm sm:text-base'
        />
        <svg
          className='absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 sm:w-5 sm:h-5 text-[#001A3D]'
          fill='none'
          stroke='currentColor'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
          />
        </svg>
      </div>
      <div className='flex flex-wrap gap-2 sm:gap-3 md:gap-4'>
        {lawTags.map((tag) => (
          <span
            key={tag}
            className='inline-flex items-center h-6 sm:h-7 md:h-8 px-2 py-1 rounded-full text-xs sm:text-sm bg-blue-100 text-blue-800'
          >
            {tag}
          </span>
        ))}
      </div>
    </div>
  )
}

export default SearchByLaw
