import React, { useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useAuth } from '../../providers/useAuth'
import { InputGroup } from '../Inputs/InputGroup'
import { FormPageWrapper } from './FormPageWrapper'
import { FormMessage } from './FormMessage'
import { Button } from './Button'
import { FooterText } from './FooterText'


const schema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be valid')
    .required('Email address is required'),
});

const ForgottenPasswordPage = () => {
  const { userLoading, resetPassword } = useAuth()
  const [userError, setError] = useState<string | null>(null)

  const { handleSubmit, errors, setTouched, handleChange, values } = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      resetPassword(
        { email: values.email },
        {
          onSuccess: () => {
            document.location.href = `/reset-password?email=${encodeURIComponent(values.email)}`
          },
          onError: (error) => {
            setError(error.message || 'Something went wrong. Please try again.')
          },
        }
      )
    },
  })

  const onSendCodeClick = (e: any) => {
    setError(null)
    const newTouched = {}
    Object.keys(errors).forEach((v) => {
      newTouched[v] = true
    })
    setTouched(newTouched)
    handleSubmit(e)
  }

  return (
    <FormPageWrapper className={'w-full md:w-[84%] '} title='Forgotten Password'>
      <FormMessage type='error'>{userError}</FormMessage>
      <form
        className='space-y-6'
        onSubmit={handleSubmit}
        autoComplete='off'
      >
        <div className='text-center text-[#0C5F71] mt-8'>Enter the email address connected to your account</div>
        <InputGroup
          id='email'
          name='email'
          theme='grey'
          isNew


          label='Email address'
          placeholder='Email Address'
          onChange={handleChange}
          value={values.email}
          error={errors.email}
        />
        <Button
          type='submit'
          onClick={onSendCodeClick}
          className='!w-[40%] !mx-auto mt-10 !mb-10'
        >
          Continue
        </Button>
        <FooterText
          text="Remember your password?"
          linkHref='./login'
          linkText='Sign In'
        />
      </form>
    </FormPageWrapper>
  )
}

export default ForgottenPasswordPage
