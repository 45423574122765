import React, { useRef, useState } from 'react'
import './index.scss'
import { data } from '../../data/PodcastData'
import { background } from 'assets/backgrounds'
import { HeartIcon } from './components/HeartIcon'
import { ShareIcon } from './components/ShareIcon'
import { PlayIcon } from './components/PlayIcon'
import { GooglePodcastIcon } from './components/GooglePodcastIcon'
import { ApplePodcastIcon } from './components/ApplePodcastIcon'
import { SpotifyIcon } from './components/SpotifyIcon'

// Custom Tag component
const Tag = ({ children, color }) => (
  <div className={`p-3 text-sm rounded-full text-[#020251] ${color}`}>
    {children}
  </div>
)

// Custom Avatar component
const Avatar = ({ src, alt }) => (
  <div className='w-12 h-12 rounded-full overflow-hidden'>
    <img src={src} alt={alt} className='w-full h-full object-cover' />
  </div>
)

// Custom Badge component
const Badge = ({ children, variant = 'blue' }) => (
  <span className='px-2 py-0.5 text-xs rounded-full bg-blue-50 text-blue-700'>
    {children}
  </span>
)

const Episode = () => {
  const sliderRef = useRef<any>(null)
  const next = () => {
    sliderRef.current.slickNext()
  }
  const previous = () => {
    sliderRef.current.slickPrev()
  }
  const [filterData, setFilterData] = useState<any>(data)
  const [episodeToShow, setEpisodeToShow] = useState(4)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const handleShowMore = () => {
    let newRecord = 0
    if (episodeToShow < filterData.length) {
      newRecord = episodeToShow + 4
    } else {
      newRecord = episodeToShow - 4
    }
    console.log(newRecord)
    setEpisodeToShow(newRecord)
  }

  return (
    <section id='podcasts'>
      <div className='banner'>
        <div className='layer'>
          <h1>About this podcast</h1>
          <p className='mt-10'>
            Lorem ipsum dolor sit amet consectetur. In risus sollicitudin
            viverra purus in at. Pretium id tincidunt morbi egestas eros
            sagittis. Risus amet scelerisque et neque aliquet egestas velit.
            Nunc morbi lectus consectetur arcu. Habitant nunc imperdiet
            vestibulum ac mi facilisis aliquam nulla. Amet at venenatis bibendum
            aliquet nisi. Pellentesque suspendisse scelerisque tellus habitant
            libero non amet eu. Tortor auctor dis amet aliquet et. Eu non quam
            dui nibh. Nunc tellus etiam sit consequat dui at in sit mauris. Mi
            enim nunc nunc pellentesque aliquet nibh. Orci ultricies tempus orci
            commodo commodo dolor adipiscing auctor. Justo egestas elit
            elementum bibendum blandit. Diam purus aliquam enim augue lectus.
            Varius non pulvinar nibh sit nisi. Purus nunc quam massa sapien
            aenean condimentum nibh pellentesque aliquet. Auctor fermentum quis
            quam aliquam nunc nisi posuere fringilla ornare. Eget malesuada sed
            leo felis vulputate feugiat massa fermentum. Neque eget blandit
            posuere ipsum amet in elementum fames. Phasellus elit arcu
            pellentesque ultricies aliquet vulputate faucibus. Et sed dui amet
            vulputate risus dictumst tellus. Duis sit ultrices mattis purus sed
            facilisi consectetur lorem volutpat.
          </p>
        </div>
        <div className='layer'>
          <img src={background.Episode.Hero} alt='' />
        </div>
      </div>

      <div className='latest-podcasts'>
        <h1 className='heading'>Podcast Title</h1>
        <div className='max-w-8xl mx-auto bg-white rounded-xl p-6 shadow-sm'>
          {/* Tags and Time Row */}
          <div className='flex flex-wrap gap-2 justify-between items-center mb-4'>
            <div className='flex justify-between items-center gap-2'>
              <Tag color='bg-[#EBF3FF]'>Conveyancing</Tag>
              <Tag color='bg-[#D2F6FF]'>Legal long title</Tag>
              <Tag color='bg-[#EBF3FF]'>Conveyancing</Tag>
              <Tag color='bg-[#D2F6FF]'>Legal long title</Tag>
              <Tag color='bg-[#EBF3FF]'>Conveyancing</Tag>
            </div>
            <div className='ml-auto flex items-center gap-3'>
              <span className='text-sm text-gray-600'>1 hr, 24 min</span>
              <div className='h-1 w-48 bg-gray-200 rounded-full'>
                <div className='h-1 w-1/3 bg-blue-600 rounded-full'></div>
              </div>

              <div className='flex items-center gap-4'>
                <div className='w-[1px] h-[40px] bg-[#D8D8DF]' />
                <HeartIcon isLiked={true} />
                <ShareIcon />
                <PlayIcon />
              </div>
            </div>
            {/* Listen Via */}
            <div className='ml-auto flex flex-col justify-center items-center gap-2'>
              <span className='text-sm text-gray-600'>Listen via:</span>
              <div className='flex justify-between items-center gap-2'>
                <SpotifyIcon />
                <GooglePodcastIcon />
                <ApplePodcastIcon />
              </div>
            </div>
          </div>

          {/* Host Section */}
          <div className='mb-8 p-4 border-2 rounded-[8px] border-[#BBC7D7] border-solid'>
            <div className='flex items-start gap-4 mb-4'>
              <Avatar src='/api/placeholder/48/48' alt='Martin Manson' />
              <div>
                <div className='flex items-center gap-2 mb-1'>
                  <h2 className='font-semibold'>Martin Manson</h2>
                  <Badge>Host</Badge>
                </div>
                <p className='text-sm text-gray-600'>
                  Position · Job title · Company
                </p>
              </div>
            </div>
            <p className='text-gray-600 text-sm leading-relaxed'>
              Lorem ipsum dolor sit amet consectetur. In risus sollicitudin
              viverra purus in at. Pretium id tincidunt morbi egestas eros
              sagittis. Risus amet scelerisque et neque aliquet egestas velit.
              Nunc morbi lectus consectetur arcu. Habitant nunc imperdiet
              vestibulum ac mi facilisis aliquam nulla. Amet at venenatis
              bibendum aliquet nisi. Pellentesque suspendisse scelerisque tellus
              habitant libero non amet eu. Tortor auctor dis amet aliquet et.
            </p>
          </div>

          {/* Guest Section */}
          <div className='mb-8 p-4 border-2 rounded-[8px] border-[#BBC7D7] border-solid'>
            <div className='flex items-start gap-4 mb-4'>
              <Avatar src='/api/placeholder/48/48' alt='Martin Manson' />
              <div>
                <div className='flex items-center gap-2 mb-1'>
                  <h2 className='font-semibold'>Martin Manson</h2>
                  <Badge>Guest</Badge>
                </div>
                <p className='text-sm text-gray-600'>
                  Position · Job title · Company ·
                  <a href='#' className='text-blue-600 hover:underline'>
                    Website link
                  </a>
                </p>
              </div>
            </div>
            <p className='text-gray-600 text-sm leading-relaxed'>
              Lorem ipsum dolor sit amet consectetur. In risus sollicitudin
              viverra purus in at. Pretium id tincidunt morbi egestas eros
              sagittis. Risus amet scelerisque et neque aliquet egestas velit.
              Nunc morbi lectus consectetur arcu. Habitant nunc imperdiet
              vestibulum ac mi facilisis aliquam nulla. Amet at venenatis
              bibendum aliquet nisi. Pellentesque suspendisse scelerisque tellus
              habitant libero non amet eu. Tortor auctor dis amet aliquet et.
            </p>
          </div>

          {/* Footer */}
          <div className='flex flex-wrap justify-between items-center pt-4 border-t border-gray-200'>
            <button className='flex items-center gap-2 text-[#001A3D] hover:underline'>
              <svg
                className='w-4 h-4'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
              >
                <path
                  d='M7 10L12 15L17 10'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              Download podcast transcript
            </button>
            <span className='text-sm text-[#37A2BA]'>
              Published on 25/05/2024
            </span>
          </div>
        </div>
      </div>
      <div className='max-w-8xl mt-44 mx-auto px-24'>
        <div className='flex items-center justify-between w-full'>
          <h1 className='text-2xl font-bold mb-6'>Podcast Content</h1>
          <button className='flex items-center gap-2 bg-gray-200 text-gray-800 px-4 py-2 rounded-md mb-6 text-sm appearance-none bg-transparent'>
            <svg
              className='w-4 h-4'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
            >
              <path
                d='M7 10L12 15L17 10'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Download podcast transcript
          </button>
        </div>
        <div className='space-y-6 text-gray-700 leading-relaxed'>
          <p>
            Lorem ipsum dolor sit amet consectetur. In risus sollicitudin
            viverra purus in arcu. Pretium id tincidunt morbi egestas eros
            sagittis. Risus amet scelerisque et neque aliquet egestas vitae. Et
            non quam dui nibh. Nunc tellus etiam sit consequat dui at in sit
            mauris. M enim nunc nunc pellentesque aliquet nibh. Orci ultricies
            tempus orci commodo commodo dolor adipiscing auctor. Ullamcorper
            tristique ligula erat in risus molestie consequat tortor. Urna orci
            vulputate hac placerat. Consequat et elit a nulla. Placeraat mauris
            quis sed habitant id. Suscipit amet augue fames lorem in. Dictum
            egestas placerat velit et viverra in risus dictum convallis.
            Pulvinar libero felis aliquet enim lorem feugiat ultricies vitae.
            Sed gravida posuere orci dignissim felis turpis placerat faucibus
            ullamcorper. Eget orci odio non turpis egestas pretium et. Luctus
            lorem quam diam diam sed ut odio. Neque urna at ultricies id leo
            faucibus tristique eget diam. Cursus habitasse lectus tellus elit.
            Rhoncus elit arcu vestibulum nibh habitasse et hendrerit. Accumsan
            vel sem pharetra sed. Ut sem neque gravida ante sed maecenas sociis
            arcu vitae. Sed id ultrices sit eget. Eu vel porta nisi eget
            feugiat. Lorem ipsum dolor sit amet consectetur. In risus
            sollicitudin viverra purus in arcu. Pretium id tincidunt morbi
            egestas eros sagittis. Risus amet scelerisque et neque aliquet
            egestas vitae. Et non quam dui nibh. Nunc tellus etiam sit consequat
            dui at in sit mauris. M enim nunc nunc pellentesque aliquet nibh.
            Orci ultricies tempus orci commodo commodo dolor adipiscing auctor.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Episode
