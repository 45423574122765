export const data = [
  {
    author: [
      {
        name: 'Martin Manson',
        published_at: '25/25/2024',
        avatar: '',
      },
    ],
    title: 'Title',
    description:
      'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.',
    url: '',
    cover: '',
    plays: 20,
    timestamp: '1hr, 24 min',
    speakers: [
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
    ],
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    author: [
      {
        name: 'Martin Manson',
        published_at: '25/25/2024',
        avatar: '',
      },
    ],
    title: 'Title',
    description:
      'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.',
    url: '',
    cover: '',
    plays: 20,
    timestamp: '1hr, 24 min',
    speakers: [
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
    ],
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    author: [
      {
        name: 'Martin Manson',
        published_at: '25/25/2024',
        avatar: '',
      },
    ],
    title: 'Title',
    description:
      'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.',
    url: '',
    cover: '',
    plays: 20,
    timestamp: '1hr, 24 min',
    speakers: [
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
    ],
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    author: [
      {
        name: 'Martin Manson',
        published_at: '25/25/2024',
        avatar: '',
      },
    ],
    title: 'Title',
    description:
      'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.',
    url: '',
    cover: '',
    plays: 20,
    timestamp: '1hr, 24 min',
    speakers: [
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
    ],
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    author: [
      {
        name: 'Martin Manson',
        published_at: '25/25/2024',
        avatar: '',
      },
    ],
    title: 'Title',
    description:
      'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.',
    url: '',
    cover: '',
    plays: 20,
    timestamp: '1hr, 24 min',
    speakers: [
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
    ],
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    author: [
      {
        name: 'Martin Manson',
        published_at: '25/25/2024',
        avatar: '',
      },
    ],
    title: 'Title',
    description:
      'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.',
    url: '',
    cover: '',
    plays: 20,
    timestamp: '1hr, 24 min',
    speakers: [
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
    ],
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    author: [
      {
        name: 'Martin Manson',
        published_at: '25/25/2024',
        avatar: '',
      },
    ],
    title: 'Title',
    description:
      'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.',
    url: '',
    cover: '',
    plays: 20,
    timestamp: '1hr, 24 min',
    speakers: [
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
    ],
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },

  {
    author: [
      {
        name: 'Martin Manson',
        published_at: '25/25/2024',
        avatar: '',
      },
    ],
    title: 'Title',
    description:
      'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.',
    url: '',
    cover: '',
    plays: 20,
    timestamp: '1hr, 24 min',
    speakers: [
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
      {
        name: 'Margareth White',
        role: 'Barrister',
        avatar: '',
      },
    ],
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
]

export const filters = ['title', 'duration']

export const speakers = [
  {
    id: 1,
    name: 'Teodoro Luck',
  },
  {
    id: 2,
    name: 'Teodoro Luck',
  },
  {
    id: 3,
    name: 'Teodoro Luck',
  },
  {
    id: 4,
    name: 'Teodoro Luck',
  },
  {
    id: 5,
    name: 'Teodoro Luck',
  },
  {
    id: 6,
    name: 'Teodoro Luck',
  },
  {
    id: 7,
    name: 'Teodoro Luck',
  },
]
