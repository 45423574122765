import { Amplify } from 'aws-amplify'
import ReactPaginate from 'react-paginate'
import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useOffices from 'common/providers/useOffices'
import { useLoadScript } from '@react-google-maps/api'

import Map from '../Map'
import Project from 'common/project'
import { LawyerProfileCard } from '../lawyer/LawyerProfileCard'
import useProfile from 'common/providers/useProfile'
import useFavourite from 'common/providers/useFavourite'
import {
  BarristerProfileCard,
  CardMode,
} from '../barrister/BarristerProfileCard'
import useBarristers from 'common/providers/useBarristers'
import { LawfirmProfileCard } from './LawfirmProfileCard'
import { OfficeSummary } from '../../../../../common/state-type'
import useLawyerProfiles from 'common/providers/useLawyerProfiles'
import { BookingPopup, CreateBooking, BookingProfileType } from '../booking'
import {
  AccountType,
  BarristerProfile,
  LawyerListObject,
} from 'common/state-type'

const mileToKm = 1.60934 // mile to km
export type position = {
  lat: number
  lng: number
}

type RegionType = {
  latitude: number
  longitude: number
  latitudeDelta: number
  longitudeDelta: number
}

type lawyerType = 'barristers' | 'solicitors' | 'offices'
Amplify.configure(Project.cognitoWeb)

const LawfrimResults = () => {
  const navigate = useNavigate()
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDomcLY-xoazL9KnRBNN9b_oROG_SMO9m8',
  })
  const { profile } = useProfile()
  const { getOffices, offices: _offices } = useOffices()
  const { createFavourite, deleteFavourite } = useFavourite()
  const { getBarristers, barristers: _barristers } = useBarristers()
  const { getLawyerProfiles, lawyerProfiles: _lawyers } = useLawyerProfiles()

  const kilometers = 20 * mileToKm
  const delta = kilometers / 100
  const initialRegionValue = {
    latitude: 51.5074,
    longitude: 0.1278,
    latitudeDelta: delta,
    longitudeDelta: delta,
  }
  const [page, setPage] = useState(1)
  const [region, setRegion] = useState<RegionType>(initialRegionValue)
  const [activeMarker, setActiveMarker] = useState<string | null>(null)
  const [bookingProfile, setBookingProfile] = useState<BookingProfileType>(null)

  // Initially setting the center of map to user's geolocation
  useEffect(() => {
    if (navigator.geolocation) {
      ;(
        navigator.permissions?.query?.({ name: 'geolocation' }) ||
        Promise.resolve()
      ).then(() => {
        navigator.geolocation.getCurrentPosition((position) => {
          setRegion({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            latitudeDelta: delta,
            longitudeDelta: delta,
          })
        })
      })
    }
  }, [])

  const fetchParams = {
    page: page,
    page_size: 50,
    favourites_only: true,
  }

  useEffect(() => {
    getLawyerProfiles(fetchParams)
    getBarristers(fetchParams)
    getOffices(fetchParams)
  }, [])

  const solicitor = _lawyers?.results?.map((lawyer) => ({
    ...lawyer,
    type: AccountType.LAWYER,
  }))

  const barristers = _barristers?.results?.map((barrister) => ({
    ...barrister,
    type: AccountType.BARRISTER,
  }))

  const lawfirms = _offices?.results?.map((office) => ({
    ...office,
    type: AccountType.BUSINESS,
  }))

  const allProfiles =
    solicitor && barristers && lawfirms
      ? [...solicitor, ...barristers, ...lawfirms]
      : []

  const handlePageSelect = (event) => {
    setPage(event.selected)
  }

  const handleFavPress = (
    item: LawyerListObject | BarristerProfile | OfficeSummary,
    accountType: AccountType,
  ) => {
    if (item.favourite_id) {
      const data = {
        id: item.favourite_id,
        type: accountType,
      }
      deleteFavourite(data)
    }
  }

  const handleBookPress = (
    item: LawyerListObject | BarristerProfile,
    accountType: AccountType,
  ) => {
    setBookingProfile({ profile: item, lawyerType: accountType })
  }

  const handlePageClick = (clickEvent) => {
    if (clickEvent.isNext) {
      setPage(page + 1)
      return page + 1
    }
    if (clickEvent.isPrevious && page > 1) {
      setPage(page - 1)
      return page - 1
    }
  }

  return (
    <>
      <div className='bg-gray-200 w-full p-2'>
        <button
          onClick={() => {
            navigate('/dashboard')
          }}
          className='flex flex-row items-center'
        >
          <FaAngleLeft size={26} />
          <span className='pl-2'>Back</span>
        </button>
      </div>
      <div className='flex flex-row w-auto'>
        <div className='flex flex-col px-1 relative overfloww-scroll w-[42.29vw] h-[100vh] bg-zinc-100 pb-8'>
          <div id='profile-list' className='py-4'>
            {allProfiles.map((profile) => {
              if (profile.type === AccountType.LAWYER) {
                return (
                  <LawyerProfileCard
                    key={profile.id}
                    onMouseEnter={() => setActiveMarker(profile.id)}
                    mode={CardMode.FAVOURITE}
                    data={profile as LawyerListObject}
                    onFavPress={() =>
                      handleFavPress(profile, AccountType.LAWYER)
                    }
                    onBookNow={() =>
                      handleBookPress(
                        profile as LawyerListObject,
                        AccountType.LAWYER,
                      )
                    }
                  />
                )
              }
              if (profile.type === AccountType.BARRISTER) {
                return (
                  <BarristerProfileCard
                    mode={CardMode.FAVOURITE}
                    onMouseEnter={() => setActiveMarker(profile.id)}
                    data={profile as BarristerProfile}
                    onFavPress={() =>
                      handleFavPress(profile, AccountType.BARRISTER)
                    }
                    onBookNow={() =>
                      handleBookPress(
                        profile as BarristerProfile,
                        AccountType.BARRISTER,
                      )
                    }
                  />
                )
              }
              if (profile.type === AccountType.BUSINESS) {
                return (
                  <LawfirmProfileCard
                    cardStyle={'mt-4'}
                    mode={CardMode.FAVOURITE}
                    key={profile.id}
                    onMouseEnter={() => setActiveMarker(profile.id)}
                    latitude={region.latitude}
                    longitude={region.longitude}
                    data={profile as OfficeSummary}
                    onFavPress={() =>
                      handleFavPress(profile, AccountType.BUSINESS)
                    }
                    onBookNow={() => navigate('/lawfirms/members')}
                  />
                )
              }
            })}
          </div>
          <div className='flex flex-col mt-auto mx-auto content-end text-gray-400 fixed bottom-0 z-1 w-[50%] align-middle items-center bg-white'>
            <ReactPaginate
              breakLabel='...'
              nextLabel='Next ->'
              onPageChange={handlePageSelect}
              pageRangeDisplayed={1}
              pageCount={0}
              previousLabel='<- Previous'
              containerClassName='inline-flex px-5 py-2'
              pageClassName='mx-1 text-center m-auto block align-middle'
              previousClassName='mx-1 text-center m-auto block align-middle leading-[25px]'
              nextClassName='mx-1 text-center m-auto block align-middle leading-[25px]'
              activeClassName='bg-[#65C2D7] text-white rounded-full w-[25px] h-[25px]'
              activeLinkClassName='leading-[25px]'
              onClick={handlePageClick}
            />
          </div>
        </div>
        {bookingProfile && (
          <BookingPopup>
            <CreateBooking
              lawyer={bookingProfile.profile}
              lawyerType={bookingProfile.lawyerType}
              handleClose={() => setBookingProfile(null)}
            />
          </BookingPopup>
        )}

        {isLoaded ? (
          <Map
            setActiveMarker={setActiveMarker}
            activeMarker={activeMarker}
            center={{
              lat: region.latitude,
              lng: region.longitude,
            }}
            lawyers={_lawyers}
            barristers={_barristers}
            offices={_offices}
          />
        ) : null}
      </div>
    </>
  )
}

export default LawfrimResults
