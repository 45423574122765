import React from 'react'
import { TagList, FaqData, highlights } from 'data/legalDocsData'
import Badge from 'components/utils/Badge'
import { BsCaretRightFill, BsStar, BsStarFill } from 'react-icons/bs'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import Feedback from './components/Feedback'

const LegalDocumentTemplate = () => {
  return (
    <div id='template' className='mt-20'>
      <div className='flex flex-column-sm justify-between items-center'>
        <div className='col-md-7'>
          <div id='preview' className='card'>
            <h1>Assignment of intellectual property rights</h1>
            <p className='mt-5'>
              An agreement for the assignment of intellectual property rights,
              drafted from the assignor's perspective.
            </p>
            <div className='flex flex-column-sm my-1 '>
              <div className='mr-lg-5'>
                <strong>ID:</strong> #17264672
              </div>
              <div className='mr-lg-5'>
                <strong>Type:</strong> Contract
              </div>
              <div>
                <strong>Version:</strong> V4.0
              </div>
            </div>
            <div className='flex gap-5'>
              <button className='btn btn-default'>Create</button>
              <button>Preview</button>
              <button className='text-muted'>Download</button>
            </div>

            <div className='summary mt-20'>
              <h4 className='fw-bold fs-lg'>Summary</h4>
              <p>
                Assignment of Intellectual Property Rights with a pro-assignor
                perspective in the UK is a legal document that transfers
                intellectual property ownership while safeguarding the
                assignor&#39;s interests. This agreement prioritizes the
                assignor&#39;s rights by defining the scope of the assignment,
                allowing for retained usage rights, outlining compensation terms
                like royalties, and imposing restrictions on the assignee&#39;s
                use. It is a highly customizable document tailored to meet the
                assignor&#39;s unique needs and goals, ensuring they benefit
                from the assignment while retaining control over their
                intellectual property. Legal expertise is often sought to craft
                agreements that protect the assignor&#39;s interests within the
                bounds of UK intellectual property laws.
              </p>
            </div>

            <div className='summary mt-10'>
              <h4 className='fw-bold fs-lg my-1'>Content</h4>
              <p>
                Assignment of Intellectual Property (IP) Rights is a legal
                document that transfers ownership of intellectual property from
                the assignor to the assignee. When drafting such an agreement
                with a pro-assignor perspective in the United Kingdom, the
                primary aim is to protect the interests, rights, and potential
                benefits of the assignor while facilitating the transfer of IP.
                This document is crucial for individuals or entities seeking to
                assign their intellectual property to others, such as creators,
                inventors, or businesses looking to monetize their IP assets.
              </p>

              <h6>1. Protecting Assignor Interests:</h6>
              <p>
                A pro-assignor Assignment of Intellectual Property Rights
                prioritizes the assignor&#39;s interests, ensuring that they
                retain certain rights, benefits, or safeguards throughout the
                assignment process. This approach can be particularly beneficial
                in negotiations involving valuable or unique IP.
              </p>
              <h6>2. Key Clauses in a Pro-Assignor Agreement:</h6>
              <ul>
                <li>
                  Scope and Limitations: The agreement defines the scope of the
                  assignment, specifying the exact IP assets being transferred.
                  It may also include limitations on the assignee&#39;s use,
                  such as geographic or industry restrictions.
                </li>
                <li>
                  Retained Rights: Provisions may allow the assignor to retain
                  specific rights, such as non-exclusive licenses to continue
                  using the IP for personal or limited commercial purposes.
                  Retained rights can be critical for creators or inventors who
                  want to retain some level of control.
                </li>
                <li>
                  Compensation or Royalties: In pro-assignor agreements,
                  compensation terms are outlined, such as one-time payments,
                  ongoing royalties, or revenue-sharing arrangements. These
                  provisions ensure that the assignor benefits financially from
                  the assignment.
                </li>
                <li>
                  Restrictions on Use: The agreement may impose restrictions on
                  how the assignee can use the IP. This can include limitations
                  on sublicensing, modification, or alteration of the IP.
                </li>
                <li>
                  Dispute Resolution: Provisions for dispute resolution,
                  including negotiation, mediation, or arbitration, are
                  typically included to address any conflicts or breaches of the
                  assignment terms.
                </li>
              </ul>
              <h6>3. Tailoring to Assignor Needs:</h6>
              <p>
                Pro-assignor agreements are highly customizable to accommodate
                the unique needs and goals of the assignor. They can be adjusted
                to favour the assignor&#39;s position while still meeting the
                assignee&#39;s requirements for IP use.
              </p>
              <h6>4. Legal and IP Expertise:</h6>
              <p>
                Given the complexity of IP laws and the intricacies of
                assignment agreements, legal counsel or IP specialists are often
                consulted during the drafting process. Their expertise ensures
                that the agreement aligns with the assignor&#39;s objectives and
                complies with relevant UK intellectual property laws.
              </p>
              <p className='mt-5'>
                Drafting a pro-assignor Assignment of Intellectual Property
                Rights in the UK is a strategic approach for individuals or
                entities looking to transfer IP while preserving their rights
                and interests. These agreements are versatile tools that can be
                tailored to meet the unique needs of assignors, ensuring that
                they benefit from the assignment financially and retain control
                over how their intellectual property is used. Legal consultation
                is invaluable to navigate the complexities of IP transfers and
                ensure that {' '}
              </p>
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div id='information'>
            <div className='card mx-auto'>
              <h4 className='mb-4 fs-lg fw-bold'>Purchase and create</h4>
              <hr />
              <p className='my-2'>
                Create your document with our digital drafter with step-by-step
                guidance and support.
              </p>
              <div className='flex items-center  my-1'>
                <h1 className='fs-lg fw-bold text-light'>£175.00</h1>
                <span className='text-light'>+VAT</span>
              </div>
              <div className='flex justify-end'>
                <button className='btn btn-primary'>Purchase</button>
              </div>
            </div>

            <div className='card'>
              <h4 className='fs-lg mb-10 fw-bold'>Document highlights</h4>
              {highlights.map((highlight) => (
                <div className='mb-3 fw-bold highlights'>
                  {highlight.name}:{' '}
                  <span className='fw-normal text-muted'>
                    {highlight.value}
                  </span>
                </div>
              ))}
              <hr className='my-10' />
              <div className='flex flex-column'>
                {TagList.map((list) => (
                  <>
                    <h3>{list.name}</h3>
                    <div className='flex flex-wrap gap-3 mb-5'>
                      {list.tags.map((tag) => (
                        <Badge
                          className='fw-bold text-dark'
                          title={tag}
                          color={list.color}
                        />
                      ))}
                    </div>
                  </>
                ))}
              </div>
              <hr className='my-3' />
              <div className=''>
                <h6>24/365 Access</h6>
                <p>Automated drafter</p>
              </div>
              <hr className='my-3' />
              <div className='flex justify-center'>
                <h6>Tier Level</h6>
              </div>
            </div>
          </div>
          <Feedback />
        </div>
        <div></div>
      </div>
      <div id='faq'>
        <h1 className='mb-5'>
          <strong>FAQ</strong>
        </h1>
        {FaqData &&
          FaqData.map((faq, idx) => (
            <Accordion className='mb-5 py-2 accordion'>
              <AccordionSummary
                expandIcon={<BsCaretRightFill size={30} color='#000' />}
                aria-controls={`panel${idx}-content`}
                id={`panel${idx}-header`}
                sx={{ fontWeight: 600 }}
              >
                {faq.title}
              </AccordionSummary>
              <AccordionDetails>{faq.message}</AccordionDetails>
            </Accordion>
          ))}
      </div>
    </div>
  )
}

export default LegalDocumentTemplate
