import { Popover } from '@mui/material'
import React from 'react'
import { BiSolidCheckCircle } from 'react-icons/bi'
import { BsQuestionCircleFill } from 'react-icons/bs'

type CardProps = {
  children?: any
  handleOnSubmit?: any
  stripe: any
}

const PricingCard = ({ children, handleOnSubmit, stripe }: CardProps) => {
  return (
    <>
      <div className=' card md:mx-5'>
        <div className='card-body'>{children}</div>
        <div className='card-footer'>
          <button
            className='pointer'
            onClick={handleOnSubmit}
            disabled={!stripe}
          >
            Get Started
          </button>
        </div>
      </div>
    </>
  )
}

const PricingHeader = ({ children }) => {
  return (
    <div className='heading'>
      <h1>{children}</h1>
    </div>
  )
}

type ItemProps = {
  title: any
  bold?: boolean
  caption?: string
}

const PricingList = ({ title, bold, caption }: ItemProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  return (
    <div className='items'>
      <BiSolidCheckCircle className='icon' color='#58C1b3' />
      <span className={bold ? 'fw-bold' : ''}>{title}</span>
      {bold && (
        <>
          <BsQuestionCircleFill
            color='#58C1b3'
            size={10}
            className='popup'
            onClick={(event) => {
              setAnchorEl(event.currentTarget)
            }}
            fontSize={10}
          />

          <Popover
            anchorEl={anchorEl}
            classes={{ paper: 'caption' }}
            open={open}
            id={open ? 'simple-popover' : undefined}
            onClose={() => {
              setAnchorEl(null)
            }}
            transformOrigin={{
              horizontal: 'left',
              vertical: 'top',
            }}
          >
            {caption}
          </Popover>
        </>
      )}
    </div>
  )
}

const PricingDetails = ({ price }) => {
  return (
    <div className='details'>
      <div className='flex justify-center items-end'>
        <h1>{price}</h1>
        <span>+</span>
        <span>VAT</span>
      </div>
      <span>Per Document</span>
    </div>
  )
}

export { PricingCard, PricingHeader, PricingList, PricingDetails }
