import React, { MouseEventHandler, useState } from 'react'
import { icons } from 'assets/icons'
import { socials } from 'assets/socials'
import { Button } from 'components/forms/Buttons'
import './index.scss'
import { BarristerProfile, AccountType } from 'common/state-type'
import useAreasOfPractice from 'common/providers/useAreasOfPractice'
import useFavourite from 'common/providers/useFavourite'

type Props = {
  onMouseEnter?: MouseEventHandler<HTMLDivElement>
  data: BarristerProfile
  onFavPress?: () => void
  onBookNow?: () => void
  onReviewBooking?: () => void
  bookingStatus?: React.ReactNode
  className?: string
  mode: CardMode
}

enum CardMode {
  FAVOURITE,
  APPOINTMENT,
}

const ProfileCard = ({
  data,
  onMouseEnter,
  onFavPress,
  onBookNow,
  onReviewBooking,
  bookingStatus,
  className,
  mode,
}: Props) => {
  const { areasOfPractice } = useAreasOfPractice()

  const [showPhoneNumber, setShowPhoneNumber] = useState(false)
  const [isFavorited, setIsFavorited] = useState(!!data.favourite_id)
  const { createFavourite, deleteFavourite } = useFavourite()

  const aop =
    data.areas_of_practice &&
    areasOfPractice &&
    areasOfPractice.filter((e) => data.areas_of_practice.includes(e.id))

  const operatingAreas = aop?.map((item) => item.name)

  const handleFavPress = () => {
    if (data?.id) {
      onFavPress()
      if (isFavorited) {
        const del = {
          id: data.id,
          type: AccountType.BARRISTER,
        }
        deleteFavourite(del)
        data.favourite_id = null
      } else {
        const type = Utils.getUserType(AccountType.BARRISTER)

        createFavourite({ [type]: data.id })
        // .then((response) => {
        //   data.favourite_id = response.id
        // })
        // .catch((error) => {
        //   console.error('Error creating favourite:', error)
        // })
      }
      onFavPress?.()
    }
    setIsFavorited(!isFavorited)
  }

  const handlePhoneClick = () => {
    setShowPhoneNumber(!showPhoneNumber)
  }

  const handleEmailClick = () => {
    window.location.href = `mailto:customerservice@legalutopia.co.uk`
  }

  const handleWebsiteClick = () => {
    if (data.website_url) {
      window.open(data.website_url, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <>
      {data.type && (
        <div
          id={`profile-${data.id}`}
          onMouseEnter={onMouseEnter}
          className={`card relative h-60 bg-${data.type}-gradient p-3`}
        >
          <div className='flex justify-between items-start'>
            <div className='flex flex-col gap-5'>
              <img
                src={socials.light.linkedin}
                className='h-[24px] w-[24px]'
                alt=''
              />
              <img
                src={socials.light.mail}
                className='h-[24px] w-[24px]'
                alt=''
              />
            </div>

            <div className='mt-3 text-center'>
              <h1 className='md:text-[18px] font-bold'>Name + Surname</h1>
              <p className='md:text-[14px] mb-6'>10y of experience</p>
              <div
                className={`relative flex mx-auto h-[40px] w-[40px] bg-gray-100 rounded-full border-${data.type}`}
              >
                <h1 className='m-auto transform rotate-[52deg]'>SG</h1>
              </div>
            </div>
            <div className='flex gap-5 flex-col justify-between items-center h-full'>
              <div className='flex flex-col items-center gap-5'>
                <img
                  src={icons.Heart}
                  // isFavorited={isFavorited}
                  onClick={onFavPress}
                  className='h-[24px] w-[24px] cursor-pointer'
                  alt=''
                />
                <img src={icons.Info} className='h-[14px] w-[14px]' alt='' />
              </div>
              <img
                className='mt-5 h-[24px] w-[24px]'
                src={icons.Upload}
                alt=''
              />
            </div>
          </div>
          <div
            className={`absolute bottom-0 left-0 right-0 p-4 bg-${data.type}`}
          >
            <div className='flex justify-between items-center'>
              <p className='text-[14px] text-white'>16th September 2024</p>
              <Button
                type='submit'
                onClick={onReviewBooking}
                className='btn-default text-light text-[12px]'
              >
                View
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProfileCard
