import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/forms/Buttons'
import { MultiLineField } from '../../../components/forms/InputFields'

const Hero = () => {
  const navigate = useNavigate()
  return (
    <section className='hero'>
      <h1 className='text-center'>
        We’re here to make a difference:
        <br /> by making law affordable and accessible to everyone.
      </h1>
      <p className='mt-3 text-center'>
        Build your confidence. Discover your options. Choose what works for you.
      </p>

      <div className='flex justify-center'>
        <div className=' col-md-6'>
          <MultiLineField
            className='search mt-5'
            placeholder='Type something you are search for'
          />
          <Button className={'btn-primary mt-4 pull-right'}>Search</Button>
        </div>
      </div>
      <div className='flex justify-end mt-5'>
        <a
          href=''
          onClick={() => navigate('learn-more')}
          className='text-underline text-primary'
        >
          Learn about this service
        </a>
      </div>
    </section>
  )
}

export default Hero
