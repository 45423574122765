import Avatar from 'assets/avatar02.jpeg'

export const data = [
  {
    name: 'Legal requirement.pdf',
    description:
      'Description, lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum',
    size: '200MB',
    type: 'Premium plus',
    owner: [
      {
        name: 'Olivia Rhye',
        email: 'olivia@untitledui.com',
        avatar_url: Avatar,
      },
    ],
    invoice: '',
    status: 'Active',
    date_purchased: 'Jan 4, 2022',
  },
  {
    name: 'Legal requirement.pdf',
    description:
      'Description, lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum',
    size: '200MB',
    type: 'Premium plus',
    owner: [
      {
        name: 'Olivia Rhye',
        email: 'olivia@untitledui.com',
        avatar_url: Avatar,
      },
    ],
    invoice: '',
    status: 'Downloaded',
    date_purchased: 'Jan 4, 2022',
  },
  {
    name: 'Legal requirement.pdf',
    description:
      'Description, lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum',
    size: '200MB',
    type: 'Premium plus',
    owner: [
      {
        name: 'Olivia Rhye',
        email: 'olivia@untitledui.com',
        avatar_url: Avatar,
      },
    ],
    invoice: '',
    status: 'Expired',
    date_purchased: 'Jan 4, 2022',
  },
  {
    name: 'Legal requirement.pdf',
    description:
      'Description, lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum',
    size: '200MB',
    type: 'Premium plus',
    owner: [
      {
        name: 'Olivia Rhye',
        email: 'olivia@untitledui.com',
        avatar_url: Avatar,
      },
    ],
    invoice: '',
    status: 'Active',
    date_purchased: 'Jan 4, 2022',
  },
  {
    name: 'Legal requirement.pdf',
    description:
      'Description, lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum',
    size: '200MB',
    type: 'Premium plus',
    owner: [
      {
        name: 'Olivia Rhye',
        email: 'olivia@untitledui.com',
        avatar_url: Avatar,
      },
    ],
    invoice: '',
    status: 'Downloaded',
    date_purchased: 'Jan 4, 2022',
  },
  {
    name: 'Legal requirement.pdf',
    description:
      'Description, lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum',
    size: '200MB',
    type: 'Premium plus',
    owner: [
      {
        name: 'Olivia Rhye',
        email: 'olivia@untitledui.com',
        avatar_url: Avatar,
      },
    ],
    invoice: '',
    status: 'Downloaded',
    date_purchased: 'Jan 4, 2022',
  },
  {
    name: 'Legal requirement.pdf',
    description:
      'Description, lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum',
    size: '200MB',
    type: 'Premium plus',
    owner: [
      {
        name: 'Olivia Rhye',
        email: 'olivia@untitledui.com',
        avatar_url: Avatar,
      },
    ],
    invoice: '',
    status: 'Expired',
    date_purchased: 'Jan 4, 2022',
  },
]

export const documents = [
  'Agreement',
  'Deed',
  'Form',
  'Guide',
  'Letter',
  'Memorandum',
  'Notes',
  'Notice',
  'Policy',
  'Register',
  'Spreedsheet',
  'Template',
]
