import { background } from 'assets/backgrounds'
import React from 'react'

const ContentLayout = ({ title, children }) => {
  /*** */
  return (
    <section>
      <div
        className='md:flex justify-between w-full md:pt-[81px] md:px-[143px] h-[700px] pt-[40px] px-[16px] bg-[length:100%_700px] bg-no-repeat'
        style={{
          backgroundImage: `url(${background.Content._01})`,
        }}
      >
        <div className='col-md-6'>
          <h1 className='text-[18px] md:text-[64px] font-bold text-[#001A3D]'>
            {title}
          </h1>
          <p className='text-[14px] md:text-[18px] mt-[35px]  font-bold text-[#001A3D]'>
            All our formal contracting, privacy, and policy terms keeping you
            informed on the way we work with you and others.
          </p>
        </div>
        <div className='col-md-4'>
          <div className='card rounded-lg p-5 md:p-[40px]  md:w-[440px] md:mt-0 mt-[100px] text-white bg-[#001A3D]'>
            <p className='font-medium text-[12px] md:text-[16px] tracking-[0.4px] mb-3'>
              Company Name:
              <span className='text-[10px] md:text-[14px] font-normal'>
                {' '}
                Legal Utopia Limited
              </span>
            </p>
            <p className='font-medium text-[12px] md:text-[16px] tracking-[0.4px] mb-3'>
              Company No:
              <span className='text-[10px] md:text-[14px] font-normal'>
                {' '}
                Legal Utopia Limited
              </span>
            </p>
            <p className='font-medium text-[12px] md:text-[16px] tracking-[0.4px] '>
              Registered Address:
              <span className='text-[10px] md:text-[14px] block mt-3 font-normal'>
                3rd Floor, 86-90 Paul Street, London, EC2A 4NE
              </span>
            </p>

            <hr className=' my-2 md:my-3' />

            <div className='text-center'>
              <h1 className='font-bold text-[12px] md:text-[18px] tracking-[0.4px]'>
                Got a question?
              </h1>
              <div className='my-1 font-thin text-[10px]'>
                <p className='font-extralight'>Director of Operations</p>
                <p>Fraser J Matcham </p>
                <p>fraser@legalutopia.co.uk</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='md:p-[180px] pt-[40px] px-[16px]'>{children}</div>
    </section>
  )
}

export default ContentLayout
