import React, { useState } from 'react'
import contactBG from '../../assets/contact-bg.svg'
import Project from '../../common/project'
import { useForm } from 'react-hook-form'
import { CheckboxGroup } from '../../common/components/Inputs/CheckboxGroup'
import { Error } from '../../icons'
import Constants from '../../common/utils/constants'

const ContactPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })
  const [isChecked, setIsChecked] = useState<boolean>(false)

  const handleChange = () => {
    setIsChecked(!isChecked)
  }

  const onSubmit = (data) => {
    console.log(data)
    const mailtoLink = `mailto:${
      Constants.supportEmail
    }?subject=Contact%20Request&body=First%20Name:%20${encodeURIComponent(
      data.firstName,
    )}%0D%0ALast%20Name:%20${encodeURIComponent(
      data.lastName,
    )}%0D%0AEmail:%20${encodeURIComponent(
      data.email,
    )}%0D%0APhone:%20${encodeURIComponent(
      data.phone,
    )}%0D%0AMessage:%20${encodeURIComponent(data.message)}`
    window.location.href = mailtoLink
  }
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  console.log(errors)
  return (
    <div className='flex flex-col h-[calc(100%-64px)] justify-center overflow-x-hidden items-center relative'>
      <img
        src={contactBG}
        alt='Contact background'
        className='absolute inset-0 w-full h-full object-cover'
      />
      <div className='flex flex-col py-0 sm:py-10 justify-center bg-[#D1D3D7] bg-opacity-10 backdrop-filter backdrop-blur-lg border border-white shadow-[0 1px 26px 0 rgba(187, 199, 215, 0.5] border-opacity-40 rounded-xl py-[50px] md:py-[100px] px-[74px] sm:px-[94px] md:px-[64px] lg:px-[164px] xl:px-[201px] xs:p-[40px]'>
        <h1 className='mb-4 text-[25px] md:text-[32px] font-bold text-[#001A3D] leading-normal'>
          Contact us
        </h1>
        <form onSubmit={handleSubmit(onSubmit)} className='mt-8'>
          <div className='grid md:grid-cols-2 gap-4'>
            <div className='flex flex-col justify-self-start item-center '>
              <div className='relative w-80'>
                <input
                  {...register('firstName', {
                    required: true,
                    pattern: /^[A-Za-z]+$/,
                  })}
                  type='text'
                  id='firstName'
                  placeholder='firstName'
                  className={`peer block w-full appearance-none border-2 rounded-lg bg-white px-3.5 py-2 pt-6 text-gray-900 placeholder-transparent focus:outline-none ${
                    errors.firstName
                      ? 'border-red-500'
                      : 'border-gray-300 focus:border-teal-500'
                  }`}
                  aria-labelledby='firstName-label'
                  aria-invalid={errors.firstName ? 'true' : 'false'}
                />
                <label
                  htmlFor='firstName'
                  id='firstName-label'
                  className={`text-xs md:text-[12px] absolute left-4 top-5 transform -translate-y-1/2 bg-white transition-all duration-200 ease-in-out
    peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400
    peer-focus:top-5 peer-focus:text-[12px] ${
      errors.firstName
        ? 'peer-focus:text-red-500 peer-placeholder-shown:text-red-500 text-red-500'
        : 'peer-focus:text-teal-600'
    }`}
                >
                  First name
                </label>
              </div>
              {errors.firstName?.type && (
                <div className='flex justify-self-start items-center pt-1 gap-y-4'>
                  <Error width='18px' height='18px' />
                  <span className='text-sm text-red-500 font-normal'>
                    Please enter your first name
                  </span>
                </div>
              )}
            </div>
            <div className='flex flex-col justify-self-start item-center '>
              <div className='relative w-80'>
                <input
                  {...register('lastName', {
                    required: true,
                    pattern: /^[A-Za-z]+$/,
                  })}
                  type='text'
                  id='lastName'
                  placeholder='lastName'
                  className={`peer block w-full appearance-none border-2 rounded-lg bg-white px-3.5 py-2 pt-6 text-gray-900 placeholder-transparent focus:outline-none ${
                    errors.lastName
                      ? 'border-red-500'
                      : 'border-gray-300 focus:border-teal-500'
                  }`}
                  aria-labelledby='lastName-label'
                  aria-invalid={errors.lastName ? 'true' : 'false'}
                />
                <label
                  htmlFor='lastName'
                  id='lastName-label'
                  className={`text-xs md:text-[12px] absolute left-4 top-5 transform -translate-y-1/2 bg-white transition-all duration-200 ease-in-out
    peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400
    peer-focus:top-5 peer-focus:text-[12px] ${
      errors.lastName
        ? 'peer-focus:text-red-500 peer-placeholder-shown:text-red-500 text-red-500'
        : 'peer-focus:text-teal-600'
    }`}
                >
                  Last name
                </label>
              </div>
              {errors.lastName?.type && (
                <div className='flex justify-self-start items-center pt-1 gap-y-4'>
                  <Error width='18px' height='18px' />
                  <span className='text-sm text-red-500 font-normal'>
                    Please enter your last name
                  </span>
                </div>
              )}
            </div>

            <div className='flex flex-col justify-self-start item-center '>
              <div className='relative w-80'>
                <input
                  {...register('emailAddress', {
                    required: true,
                    pattern: emailPattern,
                  })}
                  type='text'
                  id='emailAddress'
                  placeholder='emailAddress'
                  className={`peer block w-full appearance-none border-2 rounded-lg bg-white px-3.5 py-2 pt-6 text-gray-900 placeholder-transparent focus:outline-none ${
                    errors.emailAddress
                      ? 'border-red-500'
                      : 'border-gray-300 focus:border-teal-500'
                  }`}
                  aria-labelledby='emailAddress-label'
                  aria-invalid={errors.emailAddress ? 'true' : 'false'}
                />
                <label
                  htmlFor='emailAddress'
                  id='emailAddress-label'
                  className={`text-xs md:text-[12px] absolute left-4 top-5 transform -translate-y-1/2 bg-white transition-all duration-200 ease-in-out
    peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400
    peer-focus:top-5 peer-focus:text-[12px] ${
      errors.emailAddress
        ? 'peer-focus:text-red-500 peer-placeholder-shown:text-red-500 text-red-500'
        : 'peer-focus:text-teal-600'
    }`}
                >
                  Email address
                </label>
              </div>
              {errors.emailAddress?.type && (
                <div className='flex justify-self-start items-center pt-1 gap-y-4'>
                  <Error width='18px' height='18px' />
                  <span className='text-sm text-red-500 font-normal'>
                    Please enter an email connected to an account
                  </span>
                </div>
              )}
            </div>

            <div className='flex flex-col justify-self-start item-center '>
              <div className='relative w-80'>
                <input
                  {...register('phoneNumber', {
                    required: true,
                    pattern: /^[0-9]+$/,
                  })}
                  type='text'
                  id='phoneNumber'
                  placeholder='phoneNumber'
                  className={`peer block w-full appearance-none border-2 rounded-lg bg-white px-3.5 py-2 pt-6 text-gray-900 placeholder-transparent focus:outline-none ${
                    errors.phoneNumber
                      ? 'border-red-500'
                      : 'border-gray-300 focus:border-teal-500'
                  }`}
                  aria-labelledby='phoneNumber-label'
                  aria-invalid={errors.phoneNumber ? 'true' : 'false'}
                />
                <label
                  htmlFor='phoneNumber'
                  id='phoneNumber-label'
                  className={`text-xs md:text-[12px] absolute left-4 top-5 transform -translate-y-1/2 bg-white transition-all duration-200 ease-in-out
    peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400
    peer-focus:top-5 peer-focus:text-[12px] ${
      errors.phoneNumber
        ? 'peer-focus:text-red-500 peer-placeholder-shown:text-red-500 text-red-500'
        : 'peer-focus:text-teal-600'
    }`}
                >
                  Phone number
                </label>
              </div>
              {errors.phoneNumber?.type && (
                <div className='flex justify-self-start items-center pt-1 gap-y-4'>
                  <Error width='18px' height='18px' />
                  <span className='text-sm text-red-500 font-normal'>
                    Please enter your phone number
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='flex flex-col justify-self-start item-center'>
            <div className='relative md:w-[660px] w-[320px] mt-4'>
              <input
                {...register('message', {
                  required: true,
                  pattern: /^[A-Za-z]+$/,
                })}
                type='text'
                id='message'
                placeholder='message'
                className={`h-full peer block w-full appearance-none border-2 rounded-lg bg-white pt-10 px-3.5 py-32 pt-6 text-gray-900 placeholder-transparent focus:outline-none ${
                  errors.message
                    ? 'border-red-500'
                    : 'border-gray-300 focus:border-teal-500'
                }`}
                aria-labelledby='message-label'
                aria-invalid={errors.message ? 'true' : 'false'}
              />
              <label
                htmlFor='message'
                id='message-label'
                className={`text-xs md:text-[12px] absolute left-4 top-4 bg-white transition-all duration-200 ease-in-out
    peer-placeholder-shown:top-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400
    peer-focus:top-2 peer-focus:text-[12px] ${
      errors.message
        ? 'peer-focus:text-red-500 peer-placeholder-shown:text-red-500 text-red-500 peer-placeholder-shown:top-0'
        : 'peer-focus:text-teal-600'
    }`}
              >
                Message
              </label>
            </div>
            {errors.message?.type && (
              <div className='flex justify-self-start items-center pt-1 gap-y-4'>
                <Error width='18px' height='18px' />
                <span className='text-sm text-red-500 font-normal'>
                  Please enter your message
                </span>
              </div>
            )}
          </div>
          <div className='flex justify-start my-4 pl-4'>
            <CheckboxGroup
              id='terms'
              onChange={handleChange}
              checked={isChecked}
              label={
                <span className='text-base text-[#6E7785]'>
                  I agree with the
                  <a
                    href={new URL('/terms-of-use-consumer', Project.cwp).href}
                    className='!text-blue inline-block px-1'
                  >
                    Terms
                  </a>
                  and
                  <a
                    href={new URL('/privacy-policy-web', Project.cwp).href}
                    className='!text-blue inline-block px-1'
                  >
                    Privacy policy
                  </a>
                </span>
              }
              name='terms'
            />
          </div>

          <div className='flex justify-end'>
            <button
              className={`py-2 px-3 rounded font-base font-medium tracking-wider ${
                isChecked
                  ? 'bg-[#020251] shadow-[0_1px_4px_0_rgba(0,0,0,0.25)] text-white cursor-pointer'
                  : 'bg-[#F4F4F4] shadow-[0_1px_4px_0_rgba(0,0,0,0.25)] text-gray-500 cursor-not-allowed'
              }`}
              disabled={!isChecked}
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactPage
