export const navigations = [
  {
    name: 'Our App',
    url: '/',
  },

  {
    name: 'Discover Law',
    url: '/discover-law',
    subs: [
      [
        {
          name: 'Canine',
          url: '',
          subs: [
            [
              {
                name: 'Dog walking',
                url: '',
              },
              {
                name: 'Trespass',
                url: '',
              },
              {
                name: 'Microchipping',
                url: '',
              },
              {
                name: 'Injury to others',
                url: '',
              },
            ],
          ],
        },
        {
          name: 'Charity',
          url: '',
        },
        {
          name: 'Civil',
          url: '',
        },
        {
          name: 'Commercial',
          url: '',
          subs: [
            [
              {
                name: 'Dog walking',
                url: '',
              },
              {
                name: 'Trespass',
                url: '',
              },
              {
                name: 'Microchipping',
                url: '',
              },
              {
                name: 'Injury to others',
                url: '',
              },
            ],
          ],
        },
      ],
      [
        {
          name: 'Competition',
          url: '',
        },
        {
          name: 'Contract',
          url: '',
        },
        {
          name: 'Corporate',
          url: '',
        },
      ],
      [
        {
          name: 'Criminal',
          url: '',
        },
        {
          name: 'Data Protection',
          url: '',
        },
        {
          name: 'Employment',
          url: '',
        },
      ],
      [
        {
          name: 'Environmental',
          url: '',
        },
        {
          name: 'Family',
          url: '',
        },
        {
          name: 'General',
          url: '',
        },
      ],
      [
        {
          name: 'Health',
          url: '',
        },
        {
          name: 'Intellectual Property',
          url: '',
        },
        {
          name: 'Planning, Property and Land Law',
          url: '',
        },
      ],
      [
        {
          name: 'Shipping',
          url: '',
        },
        {
          name: 'Tax',
          url: '',
        },
        {
          name: 'Wills and Probate',
          url: '',
        },
      ],
    ],
  },
  {
    name: 'Legal help',
    url: '',
    subs: [
      [
        {
          name: 'Find a Lawyer',
          url: './find-a-lawyer',
        },
        {
          name: 'Charity',
          url: '',
        },
        {
          name: 'Civil',
          url: '',
        },
        {
          name: 'Commercial',
          url: '',
        },
      ],
      [
        {
          name: 'Create a Legal Doc',
          url: '',
        },
        {
          name: 'Contract',
          url: '',
        },
        {
          name: 'Corporate',
          url: '',
        },
      ],
      [
        {
          name: 'Resources',
          url: '',
        },
        {
          name: 'Data Protection',
          url: '',
        },
        {
          name: 'Employment',
          url: '',
        },
      ],
      [
        {
          name: 'Diagnose my issue',
          url: '',
        },
        {
          name: 'Family',
          url: '',
        },
        {
          name: 'General',
          url: '',
        },
      ],
      [
        {
          name: 'Answer my question',
          url: '',
        },
        {
          name: 'Intellectual Property',
          url: '',
        },
        {
          name: 'Planning, Property and Land Law',
          url: '',
        },
      ],
    ],
  },
  {
    name: 'Pricing',
    url: '/pricing',
  },
  {
    name: 'For Lawyers',
    url: '',
  },
  {
    name: 'Who We Are',
    url: '/whoweare',
  },
  {
    name: 'Contact us',
    url: '/contact-us',
    subs: [
      [
        {
          name: 'Contact methods',
          url: '',
        },
        {
          name: 'Message us',
          url: '',
        },
        {
          name: 'Email: email@gmail',
          url: '',
        },
        {
          name: 'Call us 020 3918 5630',
          url: '',
        },
      ],
      [
        {
          name: 'Newsletter',
          url: '',
        },
        {
          name: 'Receive weekly email',
          url: '',
        },
      ],
    ],
  },
]
