import React from 'react'
import Avatar03 from 'assets/avatar-3.jpeg'
import Avatar02 from 'assets/avatar02.jpeg'
import Background from 'assets/podcast-background.jpeg'
import { Chip } from '@mui/material'
import AudioPlayer from 'components/Multimedia/AudioPlayerV2'
import './index.scss'

type Props = {
  episode: any
}

const PodcastsCard = ({ episode }: Props) => {
  return (
    <div className='episodesX'>
      <div className='card'>
        <div className='author mb-3'>
          <div className='info'>
            <div
              className='avatar'
              style={{
                background: `url(${Avatar03}) lightgray 50% / cover no-repeat`,
              }}
            ></div>
            <div>
              <p>Martin Markson</p>
              <p>Published on 25/05/2024</p>
            </div>
          </div>
          <div className='download'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                d='M12 3.5L15 6.5M12 14.5V3.5V14.5ZM12 3.5L9 6.5L12 3.5Z'
                stroke='black'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M16 19H8C7.01367 19 6.69515 18.9953 6.45248 18.9569C4.95485 18.7197 3.78029 17.5451 3.54309 16.0475C3.50466 15.8048 3.5 15.4863 3.5 14.5C3.5 13.5137 3.50466 13.1952 3.54309 12.9525C3.78029 11.4549 4.95485 10.2803 6.45248 10.0431C6.69515 10.0047 7.01367 10 8 10H8.25C8.66421 10 9 9.66421 9 9.25C9 8.83579 8.66421 8.5 8.25 8.5H8C7.07099 8.5 6.60649 8.5 6.21783 8.56156C4.07837 8.90042 2.40042 10.5784 2.06156 12.7178C2 13.1065 2 13.571 2 14.5C2 15.429 2 15.8935 2.06156 16.2822C2.40042 18.4216 4.07836 20.0996 6.21783 20.4384C6.60649 20.5 7.07099 20.5 8 20.5H16C16.929 20.5 17.3935 20.5 17.7822 20.4384C19.9216 20.0996 21.5996 18.4216 21.9384 16.2822C22 15.8935 22 15.429 22 14.5C22 13.571 22 13.1065 21.9384 12.7178C21.5996 10.5784 19.9216 8.90042 17.7822 8.56156C17.3935 8.5 16.929 8.5 16 8.5H15.75C15.3358 8.5 15 8.83579 15 9.25C15 9.66421 15.3358 10 15.75 10H16C16.9863 10 17.3048 10.0047 17.5475 10.0431C19.0451 10.2803 20.2197 11.4549 20.4569 12.9525C20.4953 13.1952 20.5 13.5137 20.5 14.5C20.5 15.4863 20.4953 15.8048 20.4569 16.0475C20.2197 17.5451 19.0451 18.7197 17.5475 18.9569C17.3048 18.9953 16.9863 19 16 19Z'
                fill='black'
              />
            </svg>
          </div>
        </div>
        <div
          className='cover'
          style={{
            background: `url(${Background}) lightgray 50% / cover no-repeat`,
          }}
        ></div>
        <AudioPlayer />
        <div className='body my-5'>
          <h3 className='mb-3'>{episode.title}</h3>
          <p>{episode.description}</p>
        </div>

        <div className='flex mt-5 gap-5'>
          {episode &&
            episode.speakers.map((speaker, idx) => (
              <div className='author mb-3' key={idx}>
                <div className='info'>
                  <div
                    className='avatar'
                    style={{
                      background: `url(${Avatar02}) lightgray 50% / cover no-repeat`,
                    }}
                  ></div>
                  <div className='ml-1'>
                    <p className='text-[10px]'>{speaker.name}</p>
                    <p className='text-[8px]'>{speaker.role}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className='flex gap-2'>
          <div className='badge bg-[#EBF3FF] text-[12px] font-bold'>
            Conveyancing
          </div>
          <div className='badge bg-[#D2F6FF] text-[12px] font-bold'>
            Legal long title
          </div>
          <div className='badge bg-[#EBF3FF] text-[12px] font-bold'>
            Conveyancing
          </div>
        </div>
      </div>
    </div>
  )
}

export default PodcastsCard
