import React from 'react'
import { useFirebaseCloudStorage } from '../../../common/firebase/useFirebaseCloudStorage'
import Image from 'components/placeholders/Image'

const FeaturedArticle = () => {
  const { getImages, getAudios, loading, error } =
    useFirebaseCloudStorage('images')
  const images = getImages()

  return (
    <section className='banner'>
      <div className='py-5 md:py-0'>
        <div className='flex flex-column-sm justify-between'>
          <div className='col-md-5 mb-10'>
            <h3 className='mb-5 text-[16px] md:text-[24px] text-white'>
              Featured Article
            </h3>
            <p className='mb-5 md:text-[32px] text-[18px] font-bold '>
              Economic Crime and Corporate Transparency law comes into effect,
              what are the new obligations on company directors?
            </p>
            <a href='' className='underline'>
              Read more
            </a>
          </div>
          <div className='col-md-6'>
            <Image
              height='300px'
              display='flex'
              color='#fff'
              background='#101828'
              width='100%'
            >
              Image
            </Image>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedArticle
