import { icons } from 'assets/icons'

export const steps = [
  {
    title: 'Search for a regulated lawyer',
    caption:
      'Describe what you are looking for and choose your preferred lawyer.',
  },
  {
    title: 'Schedule a consultation',
    caption:
      'Join a remote call with your chosen lawyer for initial direction on your legal issue.',
  },
  {
    title: 'Consider your options',
    caption:
      'Pause and think about whether your chosen lawyer is the right fit for your needs. ',
  },
  {
    title: 'Engagement',
    caption:
      'Contact your chosen lawyer to formally engage them as your lawyer, or continue your search.',
  },
]

export const works = [
  {
    title: 'Solicitor',
    caption: 'Complaint Cover',
    icon: icons.group,
    bgColor: '#D2F6FF',
  },
  {
    title: 'Legal Ombudsman',
    caption: 'Complaint Cover',
    icon: icons.userInfo,
    bgColor: '#EBEBFF',
  },
  {
    title: 'Barrister',
    caption: 'Complaint Cover',
    icon: icons.group,
    bgColor: '#E3FFFB',
  },
]

export const glasses = [
  {
    heading: 'Is my legal need complex?',
    title: 'Explore if your legal need is basic or complex',
    description:
      'Understanding how complex your legal need really is in the context of a legal professional can help to reduce the potential legal costs of getting advice or support from a regulated solicitor or barrister.',
  },
  {
    heading: 'How much is it going to cost?',
    title: 'The different ways legal costs are estimated.',
    description:
      'Legal costs have a reputation for being high and uncertain global, there is no exception for England and Wales. We take a look at how traditional legal costs are estimated, how to ensure you can manage these costs, and the things to look for when seeking a cost quotation. ',
  },
]
