import SignUpPage from 'common/components/Authentication/SignUpPage'
import LogInPage from 'common/components/Authentication/LogInPage'
import SignoutPage from 'common/components/Authentication/SignoutPage'
import ForgottenPasswordPage from 'common/components/Authentication/ForgottenPasswordPage'
import ResetPasswordPage from 'common/components/Authentication/ResetPasswordPage'
import ResetPasswordSuccessPage from 'common/components/Authentication/ResetPasswordSuccessPage'
import VerifyEmailPage from 'common/components/Authentication/VerifyEmailPage'
import EnableMFAPage from './components/MFA/SetUpMFA'
import { DataSourcePage } from './components/CWP Sections/DataSource/DataSource'

import { useAuth } from 'common/providers/useAuth'
import useProfile from 'common/providers/useProfile'
import Project from 'common/project'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import HomePage from './pages/home'
import ContactPage from '../src/components/Contact'

import GetTheAppPage from '../src/components/GetTheAppPage'
// import AppHeader from './components/AppHeader'
import BlogPage from 'components/BlogPage'
import LawPage from './components/LawPage'
import PublicContent from './components/Public Content/PublicContent'
import DashBoard from './components/CWP Sections/DashBoard'
import LawFirmResults from './components/CWP Sections/lawfirm/LawFirmResults'
import BookALayer from 'components/BookALayer'
import JoinOurNetwork from './components/JoinOurNetwork'
import { useEffect } from 'react'
import { Amplify, Auth, Hub } from 'aws-amplify'
import EditProfile from './components/CWP Sections/EditProfile'
import LawyerProfile from 'components/CWP Sections/lawyer/LawyerProfile'
import LawfirmProfile from 'components/CWP Sections/lawfirm/LawfirmProfile'
import BarristerProfile from 'components/CWP Sections/barrister/BarristerProfile'
import LawfirmMembersList from 'components/CWP Sections/lawfirm/LawfirmMembersList'
import BlogPostView from '../src/components/CWP Sections/LegalDocuments/BlogPostView'
import Interview from '../src/components/CWP Sections/Interviews/Interview'
import {
  getAuthRedirectCookie,
  removeAuthRedirectCookie,
} from 'common/utils/redirects'
import LegalDocumentDownload from './components/LegalDocumentDownload'
import AppLayout from 'components/layouts/AppLayout'
import {
  Articles,
  DiscoverLaw,
  Episode,
  LearnMore,
  DocumentLibrary,
  LegalField,
  Appointments,
  LegalDocuments,
  LegalDocumentTemplate,
  Podcasts,
  Pricing,
  LegalPages,
  LegalPagesDocs,
  Favourites,
  WhoWeAre,
} from './pages'

Amplify.configure(Project.cognitoWeb)
Auth.configure(Project.cognitoWeb)

if (typeof window !== 'undefined') {
  const wfa_pstMesgFrmFom = (evt) => {
    if (
      evt.origin === 'https://crm.zoho.eu' ||
      evt.origin === 'https://crm.zohopublic.eu'
    ) {
      const loc_obj = JSON.stringify({
        origin: window.location.origin,
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash,
      })
      evt.source.postMessage(
        'prnt_wnd_pg_lc_rc_frm_prwindow_' + loc_obj,
        evt.origin,
      )
    }
  }
  window.addEventListener('message', wfa_pstMesgFrmFom, false)
}

function App() {
  const user = useAuth()
  const { getProfile } = useProfile()

  useEffect(() => {
    getProfile({})
  }, [])

  useEffect(() => {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') {
        getProfile({
          onSuccess: (data) => {
            const authRedirectCookie = getAuthRedirectCookie()

            if (authRedirectCookie) {
              removeAuthRedirectCookie()
              document.location = authRedirectCookie

              return
            }

            document.location =
              data?.fullName || data.first_name ? '/dashboard' : '/profile'
          },
        })
      }
    })
  }, [])

  return (
    <div className='App h-full'>
      <Router>
        <AppLayout>
          <Routes>
            <Route path='/pages/:slug' element={<BlogPage />} />
            <Route path={'legal'} element={<LegalPages />} />
            <Route path={'legal/:id'} element={<LegalPagesDocs />} />
            <Route path='/learn-more' element={<LearnMore />} />
            <Route path='/articles' element={<Articles />} />
            <Route path='/contact-us' element={<ContactPage />} />
            <Route path='/discover-law' element={<DiscoverLaw />} />
            <Route path='/podcasts' element={<Podcasts />} />
            <Route path='/legal-fields' element={<LegalField />} />
            <Route path='/episode' element={<Episode />} />
            <Route path='/mfa' element={<EnableMFAPage />} />
            <Route path='/verify-email' element={<VerifyEmailPage />} />
            <Route
              path='/forgot-password'
              element={<ForgottenPasswordPage />}
            />
            <Route path='/login' element={<LogInPage />} />
            <Route path='/reset-password' element={<ResetPasswordPage />} />
            <Route
              path='/reset-password/success'
              element={<ResetPasswordSuccessPage />}
            />
            <Route path='/interview' element={<Interview />} />
            <Route path='/join-our-network' element={<JoinOurNetwork />} />
            <Route path='/document/:id' element={<BlogPostView />} />
            <Route path='/signup' element={<SignUpPage />} />
            <Route path='/whoweare' element={<WhoWeAre />} />
            <Route path='/publiccontent' element={<PublicContent />} />
            <Route path='/publiccontent' element={<PublicContent />} />
            <Route path='/legalpages' element={<LawPage />} />
            <Route path='/datasource' element={<DataSourcePage />} />
            <Route path='/Signout' element={<SignoutPage />} />
            <Route path='/' element={<HomePage />} />
            <Route path='/legal-documents' element={<LegalDocuments />} />
            <Route
              path='/legal-documents/:id'
              element={<LegalDocumentTemplate />}
            />
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/document-library' element={<DocumentLibrary />} />
            <Route path='/bookalawyer' element={<BookALayer />} />
            <Route path='/dashboard' element={<DashBoard />} />
            <Route path='/profile' element={<EditProfile />} />
            <Route path='/appointments' element={<Appointments />} />
            <Route path='/offices/:id' element={<LawFirmResults />} />
            <Route path='/lawyers/:id' element={<LawyerProfile />} />
            <Route path='/lawfirms/:id' element={<LawfirmProfile />} />
            <Route path='/barristers/:id' element={<BarristerProfile />} />
            <Route
              path='/lawfirms/:id/members'
              element={<LawfirmMembersList />}
            />
            <Route
              path='/legal-documents/:id/download'
              element={<LegalDocumentDownload />}
            />

            <Route path='/dashboard' element={<DashBoard />} />
            <Route path='/profile' element={<EditProfile />} />
            <Route path='/appointments' element={<Appointments />} />
            <Route path='/offices/:id' element={<LawFirmResults />} />
            <Route path='/lawyers/:id' element={<LawyerProfile />} />
            <Route path='/lawfirms/:id' element={<LawfirmProfile />} />
            <Route
              path='/lawfirms/:id/members'
              element={<LawfirmMembersList />}
            />
            <Route path='/get-the-app' element={<GetTheAppPage />} />
            <Route path='/dashboard' element={<DashBoard />} />
            <Route path='/profile' element={<EditProfile />} />
            <Route path='/appointments' element={<Appointments />} />
            <Route path='/offices/:id' element={<LawFirmResults />} />
            <Route path='/lawyers/:id' element={<LawyerProfile />} />
            <Route path='/lawfirms/:id' element={<LawfirmProfile />} />

            <Route
              path='/lawfirms/:id/members'
              element={<LawfirmMembersList />}
            />
            <Route
              path='/legal-documents/:id/download'
              element={<LegalDocumentDownload />}
            />
            {user ? (
              <>
                <Route path='/dashboard' element={<DashBoard />} />
                <Route path='/favourites' element={<Favourites />} />
              </>
            ) : (
              <Route path='/login' element={<LogInPage />} />
            )}
          </Routes>
        </AppLayout>
      </Router>
    </div>
  )
}

export default App
