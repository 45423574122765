import moment from 'moment'
import React from 'react'
import { AccountType } from 'common/state-type'
import HeaderBar from './HeaderBar'

interface BookingInfoProps {
  officeName: string
  lawyerType: string
  fullName: string
  date: Date
  time: string
  onConfirm: () => void
  avatar: string
  onClose: () => void
}

const BookingInfo: React.FC<BookingInfoProps> = ({
  officeName,
  lawyerType,
  fullName,
  date,
  time,
  onConfirm,
  avatar,
  onClose,
}) => {
  return (
    <div className='flex flex-1 flex-col'>
      <HeaderBar
        avatar={avatar}
        fullName={fullName}
        officeName={officeName}
        onClose={onClose}
      />
      <div className='flex flex-1 flex-col pt-8 px-8 text-black'>
        <div className='text-sm px-2.5 items-center w-20 flex h-7 text-white rounded-lg font-bold mb-4 bg-[#F3EB1B]'>
          <span>Pending</span>
        </div>
        <span className='text-lg font-bold pb-4'>
          {Utils.capitalizeFirstLetter(officeName)}
        </span>
        <span className='text-lg font-bold pb-2'>
          {Utils.capitalizeFirstLetter(fullName)}{' '}
          <span className='font-medium'>
            {lawyerType === AccountType.LAWYER ? 'Solicitor' : 'Barrister'}
          </span>
        </span>
        <span className='pb-8 capitalize text-base'>Solicitor Advocate</span>
        <div className='flex flex-row'>
          <div className=' flex flex-col text-base'>
            <span className='pb-2'>Duration:</span>
            <span className='pb-2'>Date:</span>
            <span>Time:</span>
          </div>
          <div className='flex flex-col pl-4 text-base'>
            <span className='pb-2'>30 mins</span>
            <span className='pb-2'>
              {moment(date).format('dddd DD MMMM YYYY')}
            </span>
            <span>{time} </span>
          </div>
        </div>
        <button
          onClick={onConfirm}
          className={`font-medium self-center rounded-lg text-white text-lg py-0.5 px-12 bg-lue-purple-400 mt-16`}
        >
          Confirm
        </button>
      </div>
    </div>
  )
}

export default BookingInfo
